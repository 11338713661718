import dynamic from 'next/dynamic'

const TikTok = dynamic(() => import('./TikTok'))
const TikTokNew = dynamic(() => import('./TikTokNew'))
const Ins = dynamic(() => import('./Ins'))
const Twitter = dynamic(() => import('./Twitter'))
const Facebook = dynamic(() => import('./Facebook'))
const Youtube = dynamic(() => import('./Youtube'))
const InsNew = dynamic(() => import('./InsNew'))
const TwitterNew = dynamic(() => import('./TwitterNew'))
const FacebookNew = dynamic(() => import('./FacebookNew'))
const YoutubeNew = dynamic(() => import('./YoutubeNew'))
const TwitterBlog = dynamic(() => import('./TwitterBlog'))
const PinterestBlog = dynamic(() => import('./PinterestBlog'))
const FacebookBlog = dynamic(() => import('./FacebookBlog'))
const Email = dynamic(() => import('./Email'))
const icons = (props: any) =>
  [
    {
      key: 'tikTok',
      icon: <TikTok {...props}></TikTok>,
    },
    {
      key: 'tikTokNew',
      icon: <TikTokNew {...props}></TikTokNew>,
    },
    {
      key: 'ins',
      icon: <Ins {...props}></Ins>,
    },
    {
      key: 'insNew',
      icon: <InsNew {...props}></InsNew>,
    },
    {
      key: 'twitter',
      icon: <Twitter {...props}></Twitter>,
    },
    {
      key: 'twitterNew',
      icon: <TwitterNew {...props}></TwitterNew>,
    },
    {
      key: 'facebook',
      icon: <Facebook {...props}></Facebook>,
    },
    {
      key: 'facebookNew',
      icon: <FacebookNew {...props}></FacebookNew>,
    },
    {
      key: 'youtube',
      icon: <Youtube {...props}></Youtube>,
    },
    {
      key: 'youtubeNew',
      icon: <YoutubeNew {...props}></YoutubeNew>,
    },
    {
      key: 'twitterBlog',
      icon: <TwitterBlog {...props}></TwitterBlog>,
    },
    {
      key: 'facebookBlog',
      icon: <FacebookBlog {...props}></FacebookBlog>,
    },
    {
      key: 'pinterest',
      icon: <PinterestBlog {...props}></PinterestBlog>,
    },
    {
      key: 'email',
      icon: <Email {...props}></Email>,
    },
  ] as const

export default icons
