import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronLeft } from '@components/icons/next'
import { Text } from '@components/ui'
import cn from 'classnames'
import s from './SidebarLayout.module.css'

const SidebarLayout = ({
  children,
  className,
  handleClose,
  handleBack,
  headerTitle,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <header className={s.header}>
        {headerTitle && (
          <div className="flex-1">
            <Text html={headerTitle}></Text>
          </div>
        )}
        {handleClose && (
          <button
            onClick={handleClose}
            aria-label="Close"
            tabIndex="0"
            className="flex items-center transition duration-150 ease-in-out hover:text-accent-5"
          >
            <XMarkIcon className="h-7 w-7" />
          </button>
        )}
        {handleBack && (
          <button onClick={handleBack} aria-label="Go back" className=" ">
            <ChevronLeft className="h-6 w-6 hover:text-accent-3" />
            <span className="ml-2 text-xs text-accent-7">Back</span>
          </button>
        )}
      </header>
      <div className={s.container}>{children}</div>
    </div>
  )
}

export default SidebarLayout
