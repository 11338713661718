interface Props {
  locale: string
}

export const getOrderCustomAttribute = ({ locale }: Props) => {
  let lang = 'en'
  let country = ''
  if (['ca', 'uk', 'us', 'au'].includes(locale)) {
    lang = 'en'
    country = locale
  } else if (locale.includes('-')) {
    const [area, language] = locale.split('-')
    country = area
    lang = language
  } else {
    lang = locale
    country = locale
  }

  return [
    {
      key: '_lang',
      value: lang,
    },
    {
      key: '_country',
      value: country,
    },
  ]
}
