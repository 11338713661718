import cn from 'classnames'
import React, { useCallback, useEffect } from 'react'
import Icon from '@components/icons'
import dynamic from 'next/dynamic'
import { CommerceProvider } from '@shopify'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { useUI } from '@components/ui/context'
import { Sidebar, LoadingDots, Picture, Text } from '@components/ui'
import PaymentMethodView from '@components/checkout/PaymentMethodView'
import CheckoutSidebarView from '@components/checkout/CheckoutSidebarView'
import { CheckoutProvider } from '@components/checkout/context'
import LoginView from '@components/auth/LoginView'
import ShippingView from '@components/checkout/ShippingView'
import CartSidebarView from '@components/cart/CartSidebarView'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { refreshStarRating } from '@lib/utils/tools'
import { BRAND, SHORT_LOCALE_MAP } from '@shopify/const'
import Modal from 'react-modal'
import s from './Layout.module.css'
import BannerLink from '../BannerLink'
import { useRouter } from 'next/router'
import Button from '@components/control/Button'
import { Close } from '@components/icons/next'
import CookieConsent from '../CookieConsent'
import Registrations from '../Registrations'
import { BlogBanner } from '../Modal'
import SiteModal from '../SiteModal'

import EufyAnnouncement from '../EufyAnnouncement'
import Scroll from '@components/ui/Scroll/Scroll'
import CustomerServiceStrategy from '../ThirdParty/CustomerServiceStrategy'
import LocaleSwitcher from '../LocaleSwitcher'
import useTagInCookies from 'lib/utils/useTagInCookies'
import useShowGiftCardModal from './useShowGiftCardModal'

const NewcomerSubscribePopup = dynamic(() =>
  import('@components/NewcomerSubscribePopup/NewcomerSubscribePopup')
)

const ReferralInlet = dynamic(() =>
  import('@components/ReferralInlet/ReferralInlet')
)

const Loading = () => (
  <div className="flex h-80 w-full items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const EufyNavbar = dynamic(() => import('@components/common/EufyNavbarV3'), {
  ...dynamicProps,
})

const EufyFooter = dynamic(() => import('@components/common/EufyFooter'))

const SignUpView = dynamic(() => import('@components/auth/SignUpView'), {
  ...dynamicProps,
})

const ForgotPassword = dynamic(
  () => import('@components/auth/ForgotPassword'),
  {
    ...dynamicProps,
  }
)

const FeatureBar = dynamic(() => import('@components/common/FeatureBar'), {
  ...dynamicProps,
})

const ComponentModal = dynamic(() => import('@components/ui/Modal'), {
  ...dynamicProps,
  ssr: false,
})

const ModalView = ({
  modalView,
  closeModal,
  modalClassName,
  locale,
  ...restProps
}) => {
  const expiresDay = 14
  const { setItemWithExpiry, getItemWithExpiry } = useTagInCookies({
    expiresDay,
  })

  const onBlogBannerModalClose = useCallback(() => {
    const shortLocale = SHORT_LOCALE_MAP[locale || ''] || locale
    const key = `${shortLocale}_blog_banner`
    const cookiesTag = getItemWithExpiry(key)
    if (!cookiesTag) {
      setItemWithExpiry({
        key,
        value: true,
        timestamp: expiresDay * 24 * 60 * 60 * 1000,
      })
    }
    closeModal()
  }, [closeModal, locale, getItemWithExpiry, setItemWithExpiry])

  return (
    <ComponentModal
      onClose={
        modalView === 'BLOG_BANNER_VIEW' ? onBlogBannerModalClose : closeModal
      }
      className={modalClassName}
      {...(modalView === 'BLOG_BANNER_VIEW' && { closeStrokeColor: '#FFF' })}
    >
      {modalView === 'LOGIN_VIEW' && <LoginView />}
      {modalView === 'SIGNUP_VIEW' && <SignUpView />}
      {modalView === 'FORGOT_VIEW' && <ForgotPassword />}
      {modalView === 'BLOG_BANNER_VIEW' && (
        <BlogBanner closeStrokeColor="#FFF" {...restProps} />
      )}
    </ComponentModal>
  )
}

const ModalUI = ({ locale }) => {
  const { displayModal, closeModal, modalView, modalProps, modalClassName } =
    useUI()
  return displayModal ? (
    <ModalView
      modalView={modalView}
      closeModal={closeModal}
      {...modalProps}
      locale={locale}
      modalClassName={modalClassName}
    />
  ) : null
}

const SidebarView = ({ sidebarView, closeSidebar, metafields, show }) => {
  return (
    <Sidebar onClose={closeSidebar} show={show}>
      {sidebarView === 'CART_VIEW' && (
        <CartSidebarView metafields={metafields} />
      )}
      {sidebarView === 'CHECKOUT_VIEW' && <CheckoutSidebarView />}
      {sidebarView === 'PAYMENT_VIEW' && <PaymentMethodView />}
      {sidebarView === 'SHIPPING_VIEW' && <ShippingView />}
    </Sidebar>
  )
}

const SidebarUI = ({ links, metafields }) => {
  const { displaySidebar, closeSidebar, sidebarView } = useUI()
  return (
    <SidebarView
      metafields={metafields}
      sidebarView={sidebarView}
      closeSidebar={closeSidebar}
      links={links}
      show={displaySidebar}
    />
  )
}

const Layout = ({ children, pageProps: { categories = [], ...pageProps } }) => {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()

  const {
    metafields = {},
    pages,
    page,
    shop = {},
    locale,
    slug,
    handle,
    previewData,
    recommendProducts,
  } = pageProps
  const {
    copywriting,
    preRender,
    pageCommon,
    shopTopBanner = {},
    shopGlobalCommon = {},
    navSetting = {},
    shop_liveChat = {},
    orderLimits = {},
    cookieConsentSettings,
    disableBeforeConsent,
    registrationsSettings,
    registrationsModal,
    newcomerSubscribePopup,
    showNewPop,
    referralInlet,
    liveHelper,
    thirdparty,
    fwcdn3,
    globalCountry,
    dealsBanner,
    localeswitcher,
    giftCardPop,
    giftCard,
    memberSetting,
  } = metafields
  shop.orderLimits = orderLimits
  const {
    tipModal,
    setTipModal,
    setPreviewData,
    showRegistrationsPopup,
    openModal,
  } = useUI()

  const { asPath, pathname } = useRouter() //产品页 slug 为 handle
  useShowGiftCardModal({
    giftCardPop,
    giftCard,
  })
  const showTopBanner =
    shopTopBanner?.imgUrl &&
    !shopTopBanner?.excludePages?.includes(asPath?.split('?')?.[0])

  const navStyle = () => {
    if (navSetting?.navMode === 'halfHeight') return ''
    if (navSetting?.navMode === 'transparent') return s.transparent
    if (navSetting?.navMode === 'fixed') {
      if (BRAND === 'ankermake') {
        return !navSetting?.noneHeader && s.NavEmptyAnkerMake
      }

      return !navSetting?.noneHeader && s.NavEmpty
    }
    if (navSetting?.navMode === 'none-fixed') {
      if (BRAND === 'ankermake') {
        return ''
      }
      return !navSetting?.noneHeader && s.NavEmpty
    }
  }

  const handleLiveChat = () => {
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'bottom_function',
      eventAction: 'customer_service',
      eventLabel: '',
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'bottom_function',
      event_parameters: {
        action: 'customer_service',
      },
    })
  }

  useEffect(() => {
    window.onload = () => {
      setTimeout(() => {
        refreshStarRating()
      }, 300)
    }
  })

  useEffect(() => {
    setPreviewData(pageProps?.previewData || {})
  }, [pageProps?.previewData, setPreviewData])

  const expiresDay = 14
  const { getItemWithExpiry } = useTagInCookies({
    expiresDay,
  })

  useEffect(() => {
    // blog页
    if (!dealsBanner?.dealsConfigs) return
    const dealsConfig =
      dealsBanner.dealsConfigs?.find((dealsItem) => {
        let result = false
        for (const handle of dealsItem.handles) {
          const paths = asPath.toLowerCase().split('/')
          for (const path of paths) {
            result = path.toLocaleLowerCase() === handle.toLocaleLowerCase()
            if (result) {
              break
            }
          }
          if (result) break
        }
        return result
      }) || {}
    const shortLocale = SHORT_LOCALE_MAP[locale || ''] || locale
    const key = `${shortLocale}_blog_banner`
    const cookiesTag = getItemWithExpiry(key)
    if (!!Reflect.ownKeys(dealsConfig).length && !cookiesTag) {
      openModal(
        'BLOG_BANNER_VIEW',
        {
          metafields: dealsConfig,
        },
        '!p-0 w-[90%] min-md:w-auto !border-none  !focus:outline-none'
      )
    }
  }, [dealsBanner?.dealsConfigs, asPath, openModal, getItemWithExpiry, locale])

  return (
    <CommerceProvider
      locale={locale}
      shop={shop}
      moneyFormat={shop?.moneyFormat}
    >
      {previewData && previewData?.__preview_type && (
        <div className={s.previewDataTip}>
          <span>preview</span>
          <span className={s.previewDataTipDetail}>
            resource: {previewData?.__preview_type}
          </span>
          <span className={s.previewDataTipDetail}>
            namespace: {previewData?.__namespace}
          </span>
          <span className={s.previewDataTipDetail}>
            key: {previewData?.__key}
          </span>
        </div>
      )}
      <a name="top" id="top" />
      <div className={cn(s.root, navStyle())}>
        {localeswitcher?.show && (
          <LocaleSwitcher
            localeswitcher={localeswitcher}
            page={page}
            slug={slug}
          />
        )}
        <div className="relative">
          {showTopBanner && (
            <>
              <Picture
                source={shopTopBanner.imgUrl}
                loading="eager"
                className={cn('w-full md:aspect-h-[48] md:aspect-w-[375]', {
                  ['only-in-mobile']: shopTopBanner.mobileOnly,
                })}
              />
              <BannerLink to={`/${locale}${shopTopBanner.to}`} />
            </>
          )}
        </div>

        {/* {slug === '/home' && !navSetting?.noneHeader && (
          <Announcement metafields={metafields} locale={locale} />
        )} */}

        {!navSetting?.noneHeader && (
          <EufyAnnouncement metafields={metafields} locale={locale} />
        )}

        <div>
          {(shop_liveChat?.url || pageCommon?.whatupUrl) && (
            <div className={s.liveChat}>
              <a
                href={shop_liveChat?.url || pageCommon?.whatupUrl}
                target={shop_liveChat?.target || '_blank'}
                onClick={handleLiveChat}
              >
                <Icon
                  iconKey={shop_liveChat?.iconKey || 'liveChat'}
                  width={28}
                />
              </a>
            </div>
          )}
        </div>
        {!navSetting?.noneHeader && (
          <>
            {BRAND === 'eufy' && (
              <EufyNavbar
                metafields={metafields}
                categories={categories}
                locale={locale}
                recommendProducts={recommendProducts}
                slug={slug}
                handle={handle || page?.handle}
              />
            )}
          </>
        )}
        {/* whatup app 悬浮链接，eu ca 站点全站有，如不想全局出现，可在页面的 page common 中配置 */}
        <main className="fit">
          <Scroll show={true}>{children}</Scroll>
        </main>
        {!navSetting?.noneFooter && (
          <div>
            {BRAND === 'eufy' && (
              <>
                <EufyFooter
                  className={cn({ ['footer']: !preRender?.hideFootBar })}
                  pages={pages}
                  metafields={metafields}
                  locale={locale}
                  handle={handle || page?.handle}
                />
                <ModalUI locale={locale} />
                <CheckoutProvider>
                  <SidebarUI metafields={metafields} />
                </CheckoutProvider>
              </>
            )}
          </div>
        )}

        {cookieConsentSettings ? (
          <CookieConsent
            cookieConsent={cookieConsentSettings}
            disableBeforeConsent={disableBeforeConsent}
          />
        ) : (
          <FeatureBar
            title={
              copywriting?.accept_cookies ||
              'We use cookies on our website to give you the very best shopping experience we can. By using this site, you agree to its use of cookies.'
            }
            hide={acceptedCookies}
            close={onAcceptCookies}
            action={
              <Button
                border
                small
                theme="dark"
                onClick={() => onAcceptCookies()}
                className="!h-[36px] !max-w-[150px] !text-[14px] !leading-[36px] l:mt-[20px]"
              >
                {copywriting?.accept_cookies_btn || 'Accept cookies'}
              </Button>
            }
          />
        )}
      </div>

      {orderLimits?.show && (
        <Modal
          isOpen={!!tipModal}
          onRequestClose={() => {
            setTipModal('')
          }}
          className={'Modal TipModal'}
          overlayClassName="Overlay"
          contentLabel="coupon-faq"
          ariaHideApp={false}
        >
          <Close
            onClick={() => {
              setTipModal('')
            }}
          />
          <Text html={tipModal} />
        </Modal>
      )}
      <CustomerServiceStrategy
        path={asPath.replace(/\?.*$/, '')} // 移除path参数
        locale={locale}
        pathname={pathname}
        liveHelper={liveHelper}
        // 部分页面要关掉 live chat (uk 全局展示 live chat)
        liveChat={thirdparty?.show_livechat && !pageCommon?.hideLiveChat}
        fwcdn3Metafields={fwcdn3} // fw 直播/导购 配置
        metafields={metafields}
      />
      {/* start_ai_generated */}
      {referralInlet && (
        <ReferralInlet
          referralInletMetafields={referralInlet}
          path={asPath.replace(/\?.*$/, '')} // 移除path参数
          pathname={pathname}
        />
      )}
      {/* end_ai_generated */}
      {/* <WhatsApp /> */}
      {registrationsSettings && (
        <Registrations
          pathname={pathname}
          registrations={registrationsSettings}
          showRegistrationsPopup={showRegistrationsPopup}
          registrationsModalMetafields={registrationsModal}
          path={asPath.replace(/\?.*$/, '')} // 移除path参数
          globalCountry={globalCountry}
          showGoogleIdentity={thirdparty?.show_googleIdentity}
          memberSetting={memberSetting}
        />
      )}
      <SiteModal metafields={metafields} />
      {newcomerSubscribePopup && (
        <NewcomerSubscribePopup
          newcomerSubscribePopupMetafields={newcomerSubscribePopup}
          showNewPop={showNewPop}
          shopCommon={shopGlobalCommon}
        />
      )}
    </CommerceProvider>
  )
}

export default Layout
