import _template from 'lodash/template'
import { BRAND, SHORT_LOCALE_MAP } from '@shopify/const'
import { getThemeUrl } from '@lib/utils/tools'
import type { ParsedUrlQuery } from 'querystring'
import Cookies from 'js-cookie'
/**
 * Filters an array of objects based on a unique key.
 *
 * @param array - The array of objects to filter.
 * @param key - The key to use for comparison.
 * @returns An array of objects with unique values for the specified key.
 */

export function filterSameItemByKey(
  array: Array<{ [index: string]: any }>,
  key: string
) {
  return array.filter(
    (value: { [index: string]: any }, index: number, self) =>
      index === self.findIndex((t) => t[key] === value[key])
  )
}

// start_ai_generated
export function numberFormat(num = 0) {
  if (typeof Intl === 'undefined') {
    return num
  }

  return new Intl.NumberFormat().format(num)
}
// end_ai_generated

// start_ai_generated
export const formatLink = (
  url: string | undefined,
  label: string,
  val: string,
  idx?: number
) => {
  if (!url) return ''
  if (!url.includes(label)) {
    let symbol = url.includes('?') ? '&' : '?'
    return `${url}${symbol}ref=${val}${idx !== undefined ? idx + 1 : ''}`
  }
  return url
  // end_ai_generated
}

// start_ai_generated
export const formatCurrency = (
  moneyFormat: string,
  amount: string | number,
  isDigits: boolean = true,
  withOutSymbol: boolean = false
) => {
  const locale =
    typeof location !== 'undefined' ? location.pathname.split('/')[1] : 'us'
  const amountStr = amount?.toString() || ''

  const currencySymbol = moneyFormat
    .replace('{{amount}}', '')
    .replace('{{amount_with_comma_separator}}', '')

  // 检查是否已经是格式化的金额
  if (
    moneyFormat.includes('{{amount_with_comma_separator}}') &&
    typeof amount === 'string'
  )
    return amountStr

  const getAmount = (amount: string) =>
    parseFloat(
      amount?.replace(currencySymbol, '').replace(/,/g, '').replace('A', '')
    )

  // 移除货币符号和逗号
  const numericAmount = getAmount(amountStr)

  // 根据货币符号决定格式
  let formattedAmount: string
  if (currencySymbol === '$') {
    formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(numericAmount)
  } else if (currencySymbol.includes('€')) {
    formattedAmount = new Intl.NumberFormat(
      locale === 'eu-fr' ? 'fr-FR' : 'de-DE',
      {
        style: 'currency',
        currency: 'EUR',
      }
    ).format(numericAmount)
  } else if (currencySymbol === '£') {
    formattedAmount = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(numericAmount)
  } else if (currencySymbol === 'CA') {
    formattedAmount = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(numericAmount)
  } else if (currencySymbol === 'NZ$') {
    formattedAmount = new Intl.NumberFormat('en-NZ', {
      style: 'currency',
      currency: 'NZD',
    }).format(numericAmount)
  } else {
    // 如果货币符号不是$、€或£，返回原始金额
    formattedAmount = amountStr
  }

  // 不需要小数位isDigits为false ，默认为true，需要小数位，截取整数部分
  const formatDigitsFunc = (val: string) => {
    if (val.includes(',') && currencySymbol.includes('€')) {
      let number = val.toString().split(',')[0]
      return number
    } else if (val.includes('.')) {
      let number = val.toString().split('.')[0]
      return number
    } else {
      return val
    }
  }

  formattedAmount = isDigits
    ? formattedAmount
    : formatDigitsFunc(formattedAmount)

  // 替换货币符号位置
  const releaseAmount = formattedAmount.replace(/[€$£CA]/g, '').trim()
  if (currencySymbol.includes('$')) {
    return `${withOutSymbol ? '' : currencySymbol}${releaseAmount}`
  } else if (currencySymbol.includes('€')) {
    return `${releaseAmount}${withOutSymbol ? '' : currencySymbol}`
  } else if (currencySymbol.includes('£')) {
    return `${withOutSymbol ? '' : currencySymbol}${releaseAmount}`
  } else if (currencySymbol.includes('CA')) {
    return `${withOutSymbol ? '' : currencySymbol}${releaseAmount}`
  }

  return formattedAmount
}
// end_ai_generated

export const perLinkLocale = ({
  url,
  locale,
}: {
  url: string
  locale?: string
}) => {
  if (!url) return url
  const isFullLink =
    url.includes('https://') ||
    url.includes('http://') ||
    url.includes('.com') ||
    url.includes('//')
  if (isFullLink || !locale) {
    return url
  } else {
    return `${locale === 'us' ? '' : `/${locale}`}${url}`
  }
}

export const tsThemeLink = (link: string, locale: string | undefined) => {
  const themeDomin = `${SHORT_LOCALE_MAP[locale || ''] || locale}.${BRAND}.com`
  const isThemeLink = link.includes(themeDomin)
  const isUs = locale === 'us'
  let res = ''

  if (link.includes('/products') || link.includes('/collections')) {
    const relLink = isThemeLink
      ? link.replace(themeDomin, '').replace('https://', '').replace('//', '')
      : link
    res = isUs ? relLink : `/${locale}${relLink}`
  } else if (link.includes('/pages')) {
    res = isThemeLink ? link : getThemeUrl(link, locale)
  } else {
    res = link
  }

  return res
}

export const tsHtmlLink2Relative = ({
  html,
  locale,
  extend = '',
}: {
  html: string | undefined
  locale: string | undefined
  extend?: string
}) => {
  const regex =
    /href=['"]((?!http:\/\/|https:\/\/|\/\/|mailto:|tel:)[^'"]+)['"]/g
  if (!html) return html

  return html.replace(regex, (match, p1) => {
    const l = locale === 'us' ? '' : `/${locale}`
    const prefix = p1.startsWith('/') ? l : `${l}/`
    return `href="${prefix}${p1}${extend}"`
  })
}

export const tsHtmlThemeLink = ({
  html,
  locale,
}: {
  html: string | undefined
  locale: string | undefined
}) => {
  const regex = /href=['"]((?!mailto:|tel:)[^'"]+)['"]/g
  if (!html) return html
  return html.replace(regex, (match, p1) => {
    const path = tsThemeLink(p1, locale)
    const prefix = path.startsWith('/') ? '' : '/'
    return `href="${prefix}${path}"`
  })
}

// 优化代码，减少条件判断，简化逻辑
export const getDiscountChannel = ({
  query,
  sku,
}: {
  query: ParsedUrlQuery
  sku?: string
}) => {
  const stName = `discount_code_${sku}`
  const cookiesDiscount =
    Cookies.get(stName) ||
    Cookies.get('discount_code') ||
    Cookies.get('smc_discount_code') ||
    ''
  const queryDiscounts = query?.discount_code || query?.smc_discount_code || ''
  const queryDiscount = Array.isArray(queryDiscounts)
    ? queryDiscounts.join(',')
    : queryDiscounts
  const discountCode = queryDiscount.toUpperCase().includes('WSCH')
    ? queryDiscount
    : cookiesDiscount.toUpperCase().includes('WSCH')
    ? cookiesDiscount
    : ''

  if (discountCode) {
    Cookies.set(stName, queryDiscounts, { expires: 7, path: '/' })
    return 'wsch'
  }

  return ''
}

export const parseCurrency = (currencyString: string) => {
  // 移除所有非数字字符（包括货币符号和千位分隔符）
  const cleanedString = currencyString.replace(/[^\d,.-]/g, '')

  // 将逗号替换为点（处理欧洲格式的货币字符串，如 "189,99€"）
  const normalizedString = cleanedString.replace(',', '.')

  // 将字符串转换为浮点数
  const value = parseFloat(normalizedString)

  // 检查转换结果是否为有效数字
  if (isNaN(value)) {
    throw new Error(`Invalid currency string: ${currencyString}`)
  }

  return value
}

/**
 * 模板字符串替换
 * template("Hello, {name}!", { name: "world" }) => "Hello, world!"
 * @param str
 * @param args
 */
export const template = (
  str: string = '',
  args: Record<string, string | number | boolean | undefined> = {}
) => {
  return _template(str, {
    interpolate: /{([\s\S]+?)}/g,
  })(args)
}
