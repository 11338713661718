import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useContext,
  useState,
} from 'react'
import fetcher from '@lib/fetcher'
import { useRouter } from 'next/router'
import { APP_NAME } from '@shopify/const'
import { getMultipassToken } from '@shopify/utils'
import Cookies from 'js-cookie'
import { MEMBER_TYPE } from '@shopify/const'
import { useCartAttributesUpdate } from 'lib/shopify/api/cart'

import {
  MemberList,
  Profile,
  ProfileContextValue,
} from '@commerce/types/profile'

const contextInitialValue: ProfileContextValue = {
  isLoading: false,
  isFinish: false,
}

const ProfileContext = createContext<ProfileContextValue>(contextInitialValue)

export function ProfileProvider({ children }: PropsWithChildren<{}>) {
  const { locale } = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [profile, setProfile] = useState<Profile>()
  const cartAttributesUpdate = useCartAttributesUpdate()

  const updataMemberType = (memberType: MEMBER_TYPE) =>
    cartAttributesUpdate({
      customAttributes: [{ key: '_member_type', value: memberType }],
      updateRestAttrs: true,
      // id: data?.id,
    })

  const removeProfile = useCallback(() => {
    setIsLoading(true)
    setProfile(undefined)

    const app = APP_NAME[locale || '']

    Cookies.set(`${app}-info`, '', {
      Domain: '.eufy.com',
      expires: new Date('1970-01-01'),
    })
    Cookies.set('multipassinfo', '', {
      Domain: '.eufy.com',
      expires: new Date('1970-01-01'),
    })
    setTimeout(() => {
      setIsLoading(false)
    })
  }, [locale])

  const getMemberInfo = useCallback(
    async (profileData: Profile) => {
      const response = await fetcher({
        url: '/api/multipass/subsrv/v1/prime/member/info',
        body: '',
        locale,
        headers: {},
        repeatKey: '',
        type: '',
      })
      if (response?.status === 200) {
        const memberList: MemberList[] = response?.data?.data?.list
        const isPlus = memberList?.some((item: MemberList) => item.status)
        const isMonthlyPlus = memberList?.some(
          (item: MemberList) => item.prime_type === 1 && item.status
        )
        const isAnnualPlus = memberList?.some(
          (item: MemberList) => item.prime_type === 2 && item.status
        )
        const isExpiredPlus = !!(
          memberList?.length &&
          memberList?.every(
            (item: MemberList) => item.prime_type && !item.status
          )
        )

        const memberType = isPlus ? MEMBER_TYPE.PLUS : MEMBER_TYPE.FREE

        // updataMemberType(memberType)

        setProfile({
          ...profileData,
          isPlus,
          isMonthlyPlus,
          isAnnualPlus,
          isExpiredPlus,
          memberList,
          memberType,
        })
      } else {
        setProfile({
          ...profileData,
          memberType: MEMBER_TYPE.FREE,
        })
      }
      setIsFinish(true)
    },
    [locale]
  )

  const fetchProfile = useCallback(async () => {
    setIsLoading(true)

    const multipassinfo = getMultipassToken()

    if (!multipassinfo) {
      removeProfile()
      setIsFinish(true)
      return
    }

    const response = await fetcher({
      method: 'GET',
      url: `/api/multipass/account/users/profile?t=${Date.now()}`,
      body: {},
      locale,
      headers: {},
      repeatKey: '',
      type: '',
    })

    if (response?.status === 200) {
      const profileData = response.data?.data

      if (profileData?.email) {
        getMemberInfo(profileData)
      } else {
        setProfile({ ...profileData, memberType: MEMBER_TYPE.FREE })
        // updataMemberType(MEMBER_TYPE.FREE)
      }
    } else {
      setProfile(undefined)
      setIsFinish(true)
      // updataMemberType(MEMBER_TYPE.NON)
    }
    setIsLoading(false)
  }, [getMemberInfo, locale, removeProfile])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  useEffect(() => {
    // 在用户未获取到最新的账户激活状态下，跳转了页面后返回了上一页，根据验证步骤种下的标识进行无感刷新用户的激活状态
    const refreshProfile = async () => {
      const userActivedFlag = localStorage.getItem('userActivedFlag')
      if (!userActivedFlag) return

      try {
        const flag = JSON.parse(userActivedFlag)
        const shouldFetchProfile =
          location.href === flag.href && flag.activated === false
        if (!shouldFetchProfile) return

        await fetchProfile()
        if (profile?.activated) {
          localStorage?.removeItem('userActivedFlag')
        } else {
          refreshProfile()
        }
      } catch (e) {
        // Handle JSON parsing error
      }
    }

    refreshProfile()
  }, [fetchProfile, profile?.activated])

  return (
    <ProfileContext.Provider value={{ isLoading, isFinish, profile }}>
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfile = () => useContext(ProfileContext)
