import { Fetcher } from '@commerce/utils/types'
import { API_TOKEN, API_URL, I18N_API_TOKEN, I18N_API_URL } from './const'
import { handleFetchResponse } from './utils'

const fetcher: Fetcher = async ({
  url = API_URL,
  method = 'POST',
  variables,
  locale,
  query,
}) => {
  const { ...vars } = variables ?? {}
  const LOCALE = locale || 'us'
  const TOKEN = (I18N_API_TOKEN as Record<string, string>)[LOCALE] || API_TOKEN
  const URL = (I18N_API_URL as Record<string, string>)[LOCALE] || url

  const data = await fetch(URL, {
    method,
    body: JSON.stringify({ query, variables: vars }),
    headers: {
      'X-Shopify-Storefront-Access-Token': TOKEN!,
      'Content-Type': 'application/json',
    },
  })

  return handleFetchResponse(data)
}

export default fetcher
