// 引入 NiceModal 和 useModal 从 '@ebay/nice-modal-react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
// 引入 Modal 组件从 '@mui/material'
import Modal from '@mui/material/Modal'
// 引入 LoadingDots 组件从 '@components/ui'
import { LoadingDots } from '@components/ui'

// 创建一个 AppLoading 组件
const AppLoading = NiceModal.create(() => {
  // 使用 useModal 钩子获取 modal 状态
  const modal = useModal()
  return (
    // 使用 Modal 组件显示加载状态
    <Modal
      open={modal.visible} // 控制 Modal 的显示与隐藏
      aria-labelledby="app-loading" // 设置无障碍标签
      className="flex items-center justify-center" // 设置样式，居中显示
    >
      {/* // 使用 LoadingDots 组件显示加载动画，自定义样式 */}
      <LoadingDots className="gap-2" dotClassName="!bg-[#E3E3E3]" />
    </Modal>
  )
})
// 导出 AppLoading 组件
export default AppLoading
