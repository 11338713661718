import getProductsByQueryCam from '@shopify/utils/queries/get-products-by-query'
import { ShopifyConfig } from '@shopify/api'
import {
  API_URL,
  API_TOKEN,
  I18N_API_URL,
  I18N_API_TOKEN,
} from 'lib/config/const'
const getProductsByQueryCamFun = async (
  query: string,
  config: ShopifyConfig,
  locale: string = 'us'
) => {
  const LOCALE = locale ?? 'us'
  const URL = (I18N_API_URL as { [key: string]: string })[LOCALE] ?? API_URL
  const TOKEN =
    (I18N_API_TOKEN as { [key: string]: string })[LOCALE] ?? API_TOKEN
  try {
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': TOKEN,
      },
      body: JSON.stringify({
        query: getProductsByQueryCam,
        variables: { query: query },
      }),
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const { data } = await response.json()
    return { data: data }
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error)
    return { data: null }
  }
}
export default getProductsByQueryCamFun
