/**
 * Encode object to url parameters
 *
 * @param      {Object} paramsObj The object needs to encode as url parameters
 * @return     {String} Encoded url parameters
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (params) =>
  `?${Object.keys(params)
    .map((param) => `${param}=${encodeURIComponent(params[param])}`)
    .join('&')}`
