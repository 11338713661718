import { languageTerritory } from '@commerce/seo/use-hreflang'
import React from 'react'

const useForceHtmlLangAttribute = (desiredLang: string) => {
  React.useEffect(() => {
    const overrideLang = languageTerritory(desiredLang)

    if (!overrideLang) return
    document.documentElement.lang = overrideLang

    const langObserver = new MutationObserver(() => {
      if (document.documentElement.lang !== overrideLang) {
        document.documentElement.lang = overrideLang
      }
    })
    langObserver.observe(document.documentElement, {
      attributeFilter: ['lang'],
    })

    return () => {
      langObserver.disconnect()
    }
  }, [desiredLang])
}

export default useForceHtmlLangAttribute
