import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { useRouter } from 'next/router'

import debounce from 'lodash.debounce'

import { Cart as CartType } from 'lib/shopify/types'
import { normalizeCart } from 'lib/utils/normalize'
import { syncCart } from 'lib/shopify/api/cart'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { editCartItemsMutation } from 'lib/shopify/mutations/cart'
import { SHOPIFY_CHECKOUT_ID_COOKIE } from 'lib/config/const'

// todo!
import { ValidationError, CommerceError } from '@commerce/utils/errors'
import { useCommerce } from '@commerce/index'
import { getPersonaCookie } from '@lib/utils/thirdparty'

import { useCart } from './use-cart'

interface UpdateItemOptions {
  cartId?: string
  item?: CartType.ExportLineItem
}

export async function updateItemFn(
  locale: string = 'us',
  lines: {
    id: string
    merchandiseId: string
    quantity: number
    attributes: any
  }[],
  id?: string
) {
  if (lines.some((item) => !Number.isInteger(item.quantity))) {
    throw new ValidationError({
      message: 'The item quantity has to be a valid integer',
    })

    // Also allow the update hook to remove an item if the quantity is lower than 1
    // if (item.quantity! < 1) {
    //   return removeItemHandler.fetcher({
    //     options: removeItemHandler.fetchOptions,
    //     input: { itemId },
    //     fetch,
    //   })
    // }
  }

  const personaCookie = getPersonaCookie()

  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]
  const cartId = id || Cookies.get(checkoutIdCookie)
  if (!cartId) {
    throw new ValidationError({
      message: 'Invalid input used for this operation: Miss cartId',
    })
  }
  const { res } = await fetchGraphqlApi<CartType.ShopifyUpdateCartOperation>({
    locale,
    query: editCartItemsMutation,
    variables: {
      cartId,
      lines: lines.map((item) => ({
        id: item.id,
        quantity: item.quantity,
        attributes: personaCookie
          ? [...(item.attributes || []), { key: personaCookie, value: '-' }]
          : item?.attributes || [],
      })),
    },
    cache: 'no-store',
  })

  if (res?.cartLinesUpdate?.cart) syncCart(res.cartLinesUpdate.cart, locale)
  return normalizeCart(res.cartLinesUpdate.cart)
}

export function useUpdateItem(params?: UpdateItemOptions) {
  const cartId = params?.cartId
  const item = params?.item
  const { locale } = useRouter()
  const { mutate, data } = useCart()
  const { shop } = useCommerce()

  const updateItem = useCallback(
    async (input: any) => {
      if (Array.isArray(input)) {
        // 快速批量更新
        const lines = input.map((item) => ({
          id: item.id,
          merchandiseId: item.variantId,
          quantity: item.quantity,
          attributes: item?.customAttributes,
        }))
        const resultData = await updateItemFn(locale, lines, cartId)
        await mutate(resultData, false)
        return data
      }

      let result = true
      const orderLimits = shop?.orderLimits || {}
      const itemId = input.id ?? item?.id
      const variantId = item?.variant?.id ?? item?.variantId
      if (!itemId || !variantId) {
        throw new ValidationError({
          message: 'Invalid input used for this operation',
        })
      }
      const errors: Array<string> = []

      //shopify会对同一个商品不同活动时做拆分，这里需要汇总同一个sku的库存数
      //这里是修改所以汇总时一定要排除当前Item
      const lines: Record<string, any> = {}
      data?.lineItems?.forEach((line: any) => {
        if (line.id !== itemId) {
          lines[line.variantId] = lines[line.variantId] || {
            quantity: 0,
            variant: {
              currentlyNotInStock: line?.variant?.currentlyNotInStock,
              quantityAvailable: line?.variant?.quantityAvailable,
              sku: line?.variant?.sku,
            },
            options: line.options,
            name: line?.name,
          }
          lines[line.variantId].quantity =
            lines[line.variantId].quantity + line.quantity
        }
      })
      if (lines?.[`${variantId}`]?.quantity) {
        const variant = {
          ...(item?.variant || {
            currentlyNotInStock: false,
            quantityAvailable: 0,
            sku: '',
          }),
        }
        //库存,如果允许超卖(允许超卖的商品也有9999限制,9999数可配置)
        const available = variant?.currentlyNotInStock
          ? orderLimits?.quantityMax || 9999
          : variant.quantityAvailable
        //根据配置判断最大加购
        const orderLimit =
          orderLimits?.limits?.[variant?.sku || ''] ||
          orderLimits?.limits?.['*']
        const limitMax = orderLimit?.max
        const max =
          limitMax !== undefined && limitMax <= available ? limitMax : available
        const sumQuantity = lines?.[`${variantId}`]?.quantity + input.quantity
        if (sumQuantity > max) {
          result = false
          let tips =
            orderLimits?.tips?.updateErrorTip ||
            `Must have at most {max} of this item.`
          const options = item?.options?.map((option: any) => {
            return `${option?.name}:${option?.value}`
          })
          tips = tips
            ?.replace('{name}', item?.name)
            ?.replace('{options}', options?.join(',') || item?.name)
            ?.replace('{max}', max)
          errors.push(tips)
        }
      }
      if (result) {
        const lines = [
          {
            id: itemId,
            merchandiseId: variantId,
            quantity: input.quantity,
            attributes: input?.customAttributes,
          },
        ]
        const debounceUpdateItem = debounce(async () => {
          const resultData = await updateItemFn(locale, lines, cartId)
          await mutate(resultData, false)
          return data
        }, 500)
        return debounceUpdateItem()
      } else {
        throw new CommerceError({
          message: `${errors?.join(',')}`,
          code: 'orderLimit',
        })
      }
    },
    [fetch, mutate, data, cartId]
  )
  return updateItem
}
