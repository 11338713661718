// start_ai_generated
import { FC, useMemo } from 'react'
import { useCommerce } from '@shopify'
import type { Product } from '@commerce/types/product'
import { formatCurrency } from '@lib/utils'

interface ProductPriceProps {
  product?: Product
  metafields?: any
  value?: number
  className?: string
  isSuffix?: boolean
  withLabel?: string
}

const ProductPrice: FC<ProductPriceProps> = ({
  product,
  value,
  metafields,
  className,
  isSuffix = false,
  withLabel = '',
}) => {
  const { shop } = useCommerce()
  const moneyFormat = shop.moneyFormat

  const { price } = useMemo(() => {
    const val = value !== undefined ? value : product?.price?.value || 0
    const price = formatCurrency(moneyFormat, val)

    return { price }
  }, [product, value, moneyFormat])

  const symbol =
    moneyFormat.includes('$') || moneyFormat.includes('£') ? '.' : ','

  const [part1, part2] = price.split(symbol)

  return (
    <span className={className}>
      {isSuffix ? (
        <>
          {part1}
          <small className="text-[72%]">
            {symbol}
            {part2}
          </small>
        </>
      ) : (
        price
      )}
      {withLabel ? ' ' + withLabel : ''}
    </span>
  )
}

export default ProductPrice

// end_ai_generated
