import Cookies from 'js-cookie'
import useSWR from 'swr'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { getCheckoutQuery } from 'lib/shopify/mutations/checkout'
import { Checkout as CheckoutType } from 'lib/shopify/types'
import { createCart, syncCart } from 'lib/shopify/api/cart'
import { Cart as CartType } from 'lib/shopify/types'
import { BaseCartLine, Attribute } from 'lib/shopify/gql'
import { normalizeCart } from 'lib/utils/normalize'
import {
  SHOPIFY_CART_COOKIE,
  SHOPIFY_CHECKOUT_ID_COOKIE,
  SHOPIFY_CHECKOUT_URL_COOKIE,
  SHOPIFY_COOKIE_EXPIRE,
} from 'lib/config/const'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { getCartQuery } from 'lib/shopify/queries/cart'
import { editCartItemsMutation } from 'lib/shopify/mutations/cart'
import { getAttributesFromLineItems } from './use-update-attributes'
import { getOrderCustomAttribute } from 'lib/utils/useCustomAttributes'

// todo!
import { getPersonaCookie } from '@lib/utils/thirdparty'
import { btoaID } from '@lib/utils/tools'

export async function getCart({ locale, id }: { locale: string; id?: string }) {
  const checkoutUrlCookie = SHOPIFY_CHECKOUT_URL_COOKIE[locale]
  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]
  const shopifyCartCookie = SHOPIFY_CART_COOKIE[locale]

  const cookies_options = {
    Domain: window?.location?.hostname,
    SameSite: 'Strict',
    Secure: true,
    expires: SHOPIFY_COOKIE_EXPIRE,
  }

  const cookiesCartId = Cookies.get(checkoutIdCookie)
  const cartId = id ?? cookiesCartId
  const hostUrl = window.location.host.split('.')

  const getCartCookie = Cookies.get(shopifyCartCookie)
  const cartCookie = getCartCookie ? JSON.parse(getCartCookie) : {}

  // 兼容方案：在升级 checkout.liquid 前，无法从cart带多个discount到checkout
  const checkoutId = localStorage?.getItem('checkoutwebid') || ''
  if (checkoutId) {
    const { res } =
      await fetchGraphqlApi<CheckoutType.ShopifyGetCheckoutOperation>({
        locale,
        query: getCheckoutQuery,
        variables: {
          checkoutId,
        },
        cache: 'no-store',
      })
    if (res?.node?.completedAt) {
      try {
        Cookies.remove(checkoutIdCookie, cookies_options)
        Cookies.remove(checkoutUrlCookie, cookies_options)
        Cookies.remove(shopifyCartCookie, {
          domain:
            hostUrl.length > 2
              ? `.${hostUrl[1]}.${hostUrl[2]}`
              : window?.location?.hostname,
          expires: 14,
        })
        localStorage?.setItem('checkoutwebid', '')
      } catch (e) {
        console.log(e)
      }
      return null
    }
  }

  if (
    cartCookie &&
    cartCookie?.items?.length > 0 &&
    cartCookie?.type !== 'storefontCart'
  ) {
    const lineItems = cartCookie.items.map(
      (item: {
        id: string
        quantity: string
        attributes: any[]
        selling_plan: string
      }) => {
        return item.selling_plan
          ? {
              merchandiseId: btoaID(item.id),
              quantity: item.quantity,
              attributes: item.attributes,
              sellingPlanId: item.selling_plan,
            }
          : {
              merchandiseId: btoaID(item.id),
              quantity: item.quantity,
              attributes: item.attributes,
            }
      }
    )
    Cookies.remove(shopifyCartCookie, {
      domain:
        hostUrl.length > 2
          ? `.${hostUrl[1]}.${hostUrl[2]}`
          : window?.location?.hostname,
      expires: 14,
    })

    const orderCustomAttribute = getOrderCustomAttribute({ locale })
    const LineItemsAttributes = getAttributesFromLineItems(lineItems)
    const attributes = [...orderCustomAttribute, ...LineItemsAttributes]

    const cart = await createCart(locale, lineItems, attributes)
    if (cart) syncCart(cart, locale)
    return cart && normalizeCart(cart)
  }

  if (cartId) {
    const { res } = await fetchGraphqlApi<CartType.ShopifyCartOperation>({
      locale,
      query: getCartQuery,
      variables: { cartId },
      cache: 'no-store',
    })
    const cart = res.cart
    if (
      !cart ||
      cart?.lines?.edges?.length <= 0 ||
      cartId?.includes('shopify/Checkout')
    ) {
      Cookies.remove(checkoutIdCookie, cookies_options)
      Cookies.remove(checkoutUrlCookie, cookies_options)
      Cookies.remove(shopifyCartCookie, {
        domain:
          hostUrl.length > 2
            ? `.${hostUrl[1]}.${hostUrl[2]}`
            : window?.location?.hostname,
        expires: 14,
      })
      return null
    }

    // 获取更新购物车的属性
    const { lineItems } = normalizeCart(cart)
    const inputLineItem = lineItems.map((item: CartType.ExportLineItem) => ({
      merchandiseId: item.variantId,
      quantity: item.quantity,
      attributes: item.customAttributes,
    }))
    const orderCustomAttribute = getOrderCustomAttribute({ locale })
    const LineItemsAttributes = getAttributesFromLineItems(inputLineItem)
    const attributes = [...orderCustomAttribute, ...LineItemsAttributes]

    // 当本地存在这个 cookie 时，传对应值到 checkout custom attributes 给后端
    const personaCookie = getPersonaCookie()
    const hasPersonaAttributes = (item: { node: BaseCartLine }): boolean =>
      item.node.attributes?.some((attribute: Attribute) => {
        return attribute.key === personaCookie
      })
    // 如果有这个 cookie 购物车内所有商品都需要加上此参数
    const hasAllSaved = cart?.lines?.edges.every((item) =>
      hasPersonaAttributes(item)
    )

    if (personaCookie && !hasAllSaved) {
      // 如果有活动 cookie 并且购物车内还有没有添加这个参数的商品，在此商品上加上
      const lineItems = cart?.lines?.edges?.map(
        (line: { node: BaseCartLine }) => {
          if (hasPersonaAttributes(line)) {
            return {
              id: line.node.id,
            }
          } else {
            return {
              id: line.node.id,
              attributes: [
                ...(line.node.attributes || []),
                { key: personaCookie, value: '-' },
              ],
            }
          }
        }
      )

      const {
        res: { cartLinesUpdate },
      } = await fetchGraphqlApi<CartType.ShopifyUpdateCartOperation>({
        query: editCartItemsMutation,
        variables: {
          cartId,
          lines: lineItems,
          attributes,
        },
        cache: 'no-store',
      })
      return normalizeCart(cartLinesUpdate?.cart)
    } else {
      return normalizeCart(cart)
    }
  }
  return null
}

export function useCart(id?: string) {
  const { locale } = useRouter()

  const response = useSWR({ locale, id, getCartQuery }, getCart)

  return useMemo(
    () =>
      Object.create(response, {
        isEmpty: {
          get() {
            return (response.data?.lineItems?.length ?? 0) <= 0
          },
          enumerable: true,
        },
      }),
    [response]
  )
}
