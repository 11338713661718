import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { getGaId } from '@lib/utils/tools'

const getProductPersonalizeBySkus = async ({
  locale,
  skus,
  clientId,
  formatData,
  recom_type,
  limit, //条数限制
  version = 'v1',
  plan = '', // a b test
}) => {
  if (!skus || skus.length === 0) return {}
  const url =
    version == 'v1'
      ? `/api/multipass/rainbowbridge/personalize/relation`
      : `/api/multipass/rainbowbridge/${version}/personalize/relation/by_sku`
  const gaId = getGaId()
  const body = {
    skus,
    shopify_domain: I18N_STORE_DOMAIN[locale],
    user_id: clientId || gaId || '',
    limit,
  }
  if (recom_type) {
    body.recom_type = recom_type
  }
  const result = await fetcher({
    url,
    locale,
    method: 'GET',
    repeatKey: 'skus',
    body,
  })
  if (!result?.errors && result?.data) {
    const resultData = result?.data
    if (formatData) {
      return formatData(resultData)
    } else {
      return resultData
    }
  } else {
    return false
  }
}

export default getProductPersonalizeBySkus
