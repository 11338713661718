import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

interface Country {
  code: string
  name: string
}

interface Subdivision {
  code: string
  name: string
}
interface Geolocation {
  city: string
  country: Country
  subdivision: Subdivision
  timezone: string
  latitude: number
  longitude: number
}

interface Product {
  handle: string
  sku: string
}

interface GlobalContextValue {
  loginLoading?: boolean
  setLoginLoading?: () => void
  bannerTheme?: string
  setBannerTheme?: () => void
  geolocation?: Geolocation
  geoCode?: string
  plusMonthly?: Product
  setPlusMonthly?: () => void
  plusAnnual?: Product
  setPlusAnnual?: () => void
  memberRegisterCard?: any
  setMemberRegisterCard?: () => void
}

const contextInitialValue: GlobalContextValue = {}

const GlobalContext = createContext<GlobalContextValue | any>(
  contextInitialValue
)

export function GlobalProvider({ children }: PropsWithChildren<{}>) {
  const [loginLoading, setLoginLoading] = useState(false)
  const [bannerTheme, setBannerTheme] = useState('light')
  const [geolocation, setGeolocation] = useState({})
  const [geoCode, setGeoCode] = useState('')
  const [plusMonthly, setPlusMonthly] = useState({})
  const [plusAnnual, setPlusAnnual] = useState({})
  const [memberRegisterCard, setMemberRegisterCard] = useState({})

  const getGeolocation = async () => {
    const result = await fetch('/geolocation')
    try {
      const { geo } = await result.json()
      setGeoCode(geo?.country?.code?.toLowerCase())
      setGeolocation(geo)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getGeolocation()
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        loginLoading,
        setLoginLoading,
        bannerTheme,
        setBannerTheme,
        geolocation,
        geoCode,
        plusMonthly,
        setPlusMonthly,
        plusAnnual,
        setPlusAnnual,
        memberRegisterCard,
        setMemberRegisterCard,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobal = () => useContext(GlobalContext)
