import 'vanilla-cookieconsent'
import { useCallback, useEffect, useState, useRef } from 'react'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import {
  consentModalCollect,
  modalCollect,
  settingsModalCollect,
} from './collect'
import { useRouter } from 'next/router'
import { tsHtml2LocaleLink } from '@lib/utils/tools'

const LOCALE_MAP = {
  'eu-de': 'de',
  uk: 'gbeng',
  'eu-en': 'eueng',
}

function CookieConsent({ cookieConsent, disableBeforeConsent }) {
  const [ready, setReady] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const { locale } = useRouter()

  const firstAction = useRef(false)

  const gtmTrigger = useCallback((categories) => {
    if (categories.includes('advertising')) {
      pageGTMEvent({
        event: 'cookie allow advertising',
      })
    } else {
      pageGTMEvent({
        event: 'cookie disallow advertising',
      })
    }

    if (categories.includes('performance')) {
      pageGTMEvent({
        event: 'cookie allow performance',
      })
    } else {
      pageGTMEvent({
        event: 'cookie disallow performance',
      })
    }

    if (categories.includes('analytics')) {
      pageGTMEvent({
        event: 'cookie allow analytics',
      })
    } else {
      pageGTMEvent({
        event: 'cookie disallow analytics',
      })
    }
  }, [])

  const eventTrigger = useCallback((cookieCategories) => {
    if (cookieCategories.includes('advertising')) {
      const allowEvent = new Event('cookie allow advertising')
      window.dispatchEvent(allowEvent)
    } else {
      const disallowEvent = new Event('cookie disallow advertising')
      window.dispatchEvent(disallowEvent)
    }

    if (cookieCategories.includes('performance')) {
      const allowEvent = new Event('cookie allow performance')
      window.dispatchEvent(allowEvent)
    } else {
      const disallowEvent = new Event('cookie disallow performance')
      window.dispatchEvent(disallowEvent)
    }

    if (cookieCategories.includes('analytics')) {
      const allowEvent = new Event('cookie allow analytics')
      window.dispatchEvent(allowEvent)
    } else {
      const disallowEvent = new Event('cookie disallow analytics')
      window.dispatchEvent(disallowEvent)
    }
  }, [])

  const handleFirstAction = useCallback(
    (categories) => {
      window.consentedCookie = true
      window.cookieCategories = categories
      gtmTrigger(categories)
    },
    [gtmTrigger]
  )

  const handleAccept = useCallback(
    (config) => {
      window.consentedCookie = true
      window.cookieCategories = config.categories

      if (
        config.categories.length === 1 &&
        config.categories[0] === 'necessary'
      ) {
        window.disableCookies = true
      } else {
        window.disableCookies = false
      }

      let CMP = {
        s: '',
        m: '0',
        a: '0',
        p: '0',
      }

      if (config.categories.includes('analytics')) {
        CMP.a = '1'
      }
      if (config.categories.includes('advertising')) {
        CMP.m = '1'
      }
      if (config.categories.includes('performance')) {
        CMP.p = '1'
      }

      document.cookie = `_tracking_consent=${encodeURIComponent(
        JSON.stringify({
          v: '2.1',
          region: (LOCALE_MAP[locale] || 'us').toUpperCase(),
          reg: 'GDPR',
          con: { CMP },
          lim: ['GDPR_BLOCK_ALL'],
        })
      )}; expires=${new Date(
        new Date() * 1 + 365 * 864e5
      ).toUTCString()}; sameSite=Lax; domain=.eufy.com; path=/`

      eventTrigger(config.categories)
      window.CookieConsentApi?.updateScripts()
    },
    [eventTrigger, locale]
  )

  const handleChange = useCallback(
    (config) => {
      handleAccept(config)
      if (!firstAction.current) {
        gtmTrigger(config.categories)
      } else {
        firstAction.current = false
      }
    },
    [handleAccept, gtmTrigger]
  )

  const start = useCallback(() => {
    setLoaded(true)

    if (!document.getElementById('cc--main') && cookieConsent) {
      if (disableBeforeConsent !== 'true' && disableBeforeConsent !== true) {
        document.body.className += ' not-gdpr'
      }

      if (cookieConsent?.languages?.en) {
        const description =
          cookieConsent?.languages?.en?.consent_modal?.description || ''
        const contact =
          cookieConsent?.languages?.en?.settings_modal?.blocks?.[4]
            ?.description || ''
        if (description)
          cookieConsent.languages.en.consent_modal.description =
            tsHtml2LocaleLink({ html: description, locale })
        if (contact)
          cookieConsent.languages.en.settings_modal.blocks[4].description =
            tsHtml2LocaleLink({ html: contact, locale })
      }

      window.CookieConsentApi = window.initCookieConsent()
      window.CookieConsentApi.run({
        autoclear_cookies: true, // default: false
        page_scripts: true, // default: false
        mode: 'opt-in', // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        onAccept: (cookieConsentConfig) => {
          handleAccept(cookieConsentConfig)
        },
        onChange: (cookieConsentConfig) => {
          handleChange(cookieConsentConfig)
        },
        onFirstAction: ({ accepted_categories }) => {
          firstAction.current = true
          handleFirstAction(accepted_categories)
        },
        ...cookieConsent,
      })

      if (disableBeforeConsent === 'false' || !disableBeforeConsent) {
        // 点击空白区关闭
        function hasParentById(el, parentId) {
          if (el.id === parentId) {
            return true
          }

          if (el.parentElement) {
            return hasParentById(el.parentElement, parentId)
          }
        }

        function handleBodyClick(event) {
          if (window.CookieConsentApi) {
            if (hasParentById(event.target, 'cc_div')) {
              return
            }
            window.CookieConsentApi.hide()
          }
          document.body.removeEventListener('click', handleBodyClick)
        }

        document.body.addEventListener('click', handleBodyClick, {
          once: true,
        })

        // 为consent弹窗增加close按钮
        if (!document.getElementById('cm')) {
          return
        }
        const closeButton = document.createElement('div')
        closeButton.innerHTML = `<div id="consent-close" class="consent-close"></div>`

        document.getElementById('cm').appendChild(closeButton)

        document
          .getElementById('consent-close')
          .addEventListener('click', function () {
            consentModalCollect('close')
            window.CookieConsentApi.accept('all')
            window.CookieConsentApi.hide()
          })
      }

      // 埋点添加
      if (!window.consentedCookie) {
        modalCollect('pop_1', 'impression')
      }

      // Cookie Settings
      document
        .getElementById('c-s-bn')
        ?.addEventListener('click', function (event) {
          consentModalCollect(event.target.innerText)
        })
      // Accept All
      document
        .getElementById('c-p-bn')
        ?.addEventListener('click', function (event) {
          consentModalCollect(event.target.innerText)
        })
      // Accept All的tag值
      document.getElementById('c-p-bn')?.setAttribute('tag', 'Auto-0000070')

      document
        .getElementById('cookie-notice')
        ?.addEventListener('click', function (event) {
          consentModalCollect(event.target.innerText)
        })
      document
        .getElementById('cookie-preferences')
        ?.addEventListener('click', function (event) {
          consentModalCollect(event.target.innerText)
        })
      document
        .querySelector('[data-cc="c-settings"]:not(#cookie-preferences)')
        ?.addEventListener('click', function (event) {
          modalCollect('entry_click', event.target.innerText)
        })

      document
        .getElementById('s-c-bn')
        ?.addEventListener('click', function (event) {
          settingsModalCollect('close')
        })
      document
        .getElementById('s-sv-bn')
        ?.addEventListener('click', function (event) {
          settingsModalCollect(event.target.innerText)
        })
      document
        .getElementById('s-all-bn')
        ?.addEventListener('click', function (event) {
          settingsModalCollect(event.target.innerText)
        })
      document
        .getElementById('s-rall-bn')
        ?.addEventListener('click', function (event) {
          settingsModalCollect(event.target.innerText)
        })
      document
        .getElementById('contact')
        ?.addEventListener('click', function (event) {
          settingsModalCollect(event.target.innerText)
        })
    }
  }, [
    disableBeforeConsent,
    cookieConsent,
    handleChange,
    handleFirstAction,
    handleAccept,
  ])

  useEffect(() => {
    window.cookieCategories = []
    let ccCookieValue
    document.cookie.split(';').forEach((item) => {
      const [cookieName, cookieValue] = item.trim().split('=')
      if (cookieName === 'cc_cookie') {
        ccCookieValue = JSON.parse(cookieValue)
      }
    })
    if (ccCookieValue) {
      window.cookieCategories = ccCookieValue.categories
      window.consentedCookie = true
    }
  }, [])

  useEffect(() => {
    if (ready && !loaded) {
      start()
    }
  }, [ready, loaded, start])

  useEffect(() => {
    // webpagetest 测试忽略
    if (window.navigator.userAgent.includes('PTST')) {
      return
    }

    let timer
    // 尽量在ga pageview 事件后加载
    timer = setInterval(() => {
      if (window.google_tag_manager) {
        setReady(true)
        clearInterval(timer)
      }
    }, 500)
  }, [])

  return null
}

export default CookieConsent
