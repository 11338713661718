import Cookies from 'js-cookie'

import { getCustomerToken } from '@shopify/utils/customer-token'

export const getAdCookie = () => {
  const { search, href } = (window && window.location) || {}
  const query = getQuery()
  return {
    fbuy_ref_code: Cookies.get('fbuy_ref_code'),
    affiliate: Cookies.get('affiliate'),
    ref: Cookies.get('ref_ads'),
    inviter_code: Cookies.get('inviter_code'),
    register_source: decodeURIComponent(
      query.redirect || Cookies.get('reg_source') || href
    ),
    deals_type: Cookies.get('deal'),
    transfer_id: Cookies.get('transfer_id'),
  }
}

export const addCookie = ({ locale, shopifyUrl }) => {
  if (!locale || !shopifyUrl) return false
  const query = getQuery()
  const hostUrl = window.location.host.split('.')
  const domain =
    hostUrl.length > 2
      ? `.${hostUrl[1]}.${hostUrl[2]}`
      : window.location.hostname
  Cookies.set('current-language', locale, {
    domain: window.location.hostname,
  })
  Cookies.set('shopify-url', shopifyUrl, {
    domain: window.location.hostname,
  })
  if (query.utm_content) {
    Cookies.set('UTM_AD_CONTENT', query.utm_content, {
      domain,
      expires: 365,
    })
  }
  if (query.utm_source) {
    const refAds = `utm_source=${query.utm_source || ''}&utm_media=${
      query.utm_media || query.utm_medium || ''
    }&utm_content=${query.utm_content || ''}&utm_campaign=${
      query.utm_campaign || ''
    }&utm_term=${query.utm_term || ''}`
    Cookies.set('utm_source', query.utm_source, {
      domain,
      expires: 14,
    })
    Cookies.set('ref_ads', refAds, {
      domain,
      expires: 14,
    })
    Cookies.set('reg_source', window.location.href, {
      domain,
      expires: 14,
    })
    Cookies.set('ref_query', window.location.search || '', {
      domain,
      expires: 14,
    })
  }
  if (query.persona) {
    Cookies.set('persona', query.persona, {
      domain,
      expires: 365,
    })
  }
  // Google gclid参数，用于支付广告追踪
  if (query.gclid)
    Cookies.set('affiliate', `gclid=${query.gclid}`, {
      domain,
      expires: 14,
    })
  if (query.tag)
    Cookies.set('pla_tag', query.tag, {
      domain,
      expires: 14,
    })
  if (query.aic)
    Cookies.set('aic', query.aic, {
      domain,
      expires: 14,
    })
  if (query.deal)
    Cookies.set('deal', query.deal, {
      domain,
      expires: 14,
    })
  if (query.ic || query.inviter_code) {
    // 给其他API使用
    Cookies.set('inviter_code', query.ic || query.inviter_code, {
      domain,
      expires: 14,
    })
  }
  // for postback(studentbeans)
  if (query.aff_sub)
    Cookies.set('aff_sub', query.aff_sub, {
      domain,
      expires: 14,
    })
  // friendbuy refer code
  if (query.fbuy_ref_code)
    Cookies.set('fbuy_ref_code', query.fbuy_ref_code, {
      domain,
      expires: 14,
    })

  // awin awc cookies
  if (query.awc) {
    fetch(`/api/awin/cookies?awc=${query.awc}`).then((res) => {
      console.log('awin cookies set', res.status)
    })
  }
}

export const getQuery = (query = '') => {
  const url = query || window.location.search //获取url中"?"符后的字串
  const theRequest = new Object()
  if (url.indexOf('?') != -1) {
    const str = url.substring(1),
      strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURIComponent(
        strs[i].split('=')[1]
      )
    }
  }
  return theRequest
}

export const funcUrlDel = (name) => {
  const loca = window && window.location
  const query = loca.search.substr(1)
  let baseUrl = loca.origin + loca.pathname
  if (query.indexOf(name) > -1) {
    const obj = {}
    const arr = query.split('&')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=')
      obj[arr[i][0]] = arr[i][1]
    }
    delete obj[name]
    Object.keys(obj).length > 0 && (baseUrl = loca.origin + loca.pathname + '?')
    const url =
      baseUrl +
      JSON.stringify(obj)
        .replace(/[\"\{\}]/g, '')
        .replace(/\:/g, '=')
        .replace(/\,/g, '&')
    return url
  }
}

export const getMultipassReady = (locale) => {
  const multipassToken = Cookies.get('multipassinfo')
  const token = getCustomerToken(locale)
  if (token) return { status: 'logged' }
  if (multipassToken && !token)
    return { status: 'multipassReady', multipassToken }
  return false
}

export const parseCookies = (cookies) =>
  cookies.split('&').reduce((pre, cur) => {
    const c = cur.split('=')
    if (c.length >= 2) {
      pre[c[0]] = c[1]
    }
    return pre
  }, {})

export const parseQueryString = (url) => {
  const obj = {}
  let keyvalue = []
  let key = '',
    value = ''
  const paraString =
    url && url.substring(url.indexOf('?') + 1, url.length).split('&')
  for (const i in paraString) {
    keyvalue = paraString[i].split('=')
    key = keyvalue[0]
    value = keyvalue[1]
    obj[key] = value
  }
  return obj
}

export const getReferrerSource = (referrals) => {
  const { ref, register_source } = getAdCookie()
  const ref_ads = parseQueryString(ref)
  const { securityUtm, websiteUtm, eufyhomeUtm, eufylifeUtm } = referrals || {}
  const utm_media = ref_ads?.utm_media || ref_ads?.utm_medium
  const utm_source = ref_ads?.utm_source

  const utmTypes = [securityUtm, websiteUtm, eufyhomeUtm]
  const isQueryReferral =
    utmTypes.some(
      (utm) => utm?.utm_media === utm_media && utm?.utm_source === utm_source
    ) ||
    (eufylifeUtm && eufylifeUtm?.utm_source === utm_source)

  return isQueryReferral ? register_source : ''
}

export const getReferralSourceCustomAttr = ({
  referrals,
  isShowAttr = false,
}) => {
  const referrerSource = getReferrerSource(referrals)
  const key = isShowAttr ? 'utm_params' : '_utm_params'
  const referrerSourceAttribute = referrerSource
    ? {
        key,
        value: referrerSource,
      }
    : ''
  return referrerSourceAttribute ? [referrerSourceAttribute] : []
}
