/* eslint-disable @next/next/no-img-element */

import { useEffect, forwardRef } from 'react'
import { images, isShopifycdn, imgSrcSetsize } from './utils'

const Picture = forwardRef(function Picture(
  {
    className = '',
    imgClassName = '',
    imgWidth = 0,
    imgHeight = 0,
    source = '',
    quality = 2,
    loading = 'lazy',
    onLoadFun = null,
    alt = '',
    children = null,
    ...rest
  },
  forwardRef
) {
  useEffect(() => {}, [])

  const imgOnLoadFun = () => {
    if (onLoadFun) onLoadFun()
  }

  const pictureLoader = ({ source }) => {
    if (typeof source !== 'string' || !source) return []
    const deviceSizes = images?.deviceSizes || [
      360, 768, 1024, 1200, 1539, 1920,
    ]
    let srcArr
    try {
      if (isShopifycdn(source)) {
        srcArr = source
          .replace(/[\r\n]/g, '')
          .replace(/\s\s+/g, ' ')
          .split(',')
          .map((item) => item.split(' ').filter(Boolean))
      } else {
        srcArr = [source.trim()].map((item) => item.split(' ').filter(Boolean))
      }
    } catch (error) {
      console.log(error)
      return []
    }
    const sourceList = deviceSizes.map((width) => {
      return sourceSrcSet({ srcArr, width })
    })

    const img = imgSrcSetsize({ src: srcArr[0][0], width: 1920, quality })

    sourceList.push(defaultImg({ img }))
    return sourceList
  }

  const defaultImg = ({ img }) => {
    const attr = {}
    if (imgWidth) attr.width = imgWidth
    if (imgHeight) attr.height = imgHeight

    return (
      <img
        key="default"
        src={img}
        loading={loading}
        alt={alt || ''}
        className={`w-full ${imgClassName}`}
        onLoad={imgOnLoadFun()}
        {...attr}
      />
    )
  }

  const sourceSrcSet = ({ srcArr, width }) => {
    const def = imgSrcSetsize({ src: srcArr[0][0], width, quality })
    let result
    srcArr.forEach((ele) => {
      var img = ele[0]
      if (ele.length > 1 && parseInt(ele[1], 10) >= width) {
        result = imgSrcSetsize({ src: img, width, quality })
      }
    })
    const srcset = result || def
    return (
      <source key={width} srcSet={srcset} media={`(max-width: ${width}px)`} />
    )
  }

  return (
    <picture
      ref={forwardRef}
      className={`block overflow-hidden ${className}`}
      {...rest}
    >
      {pictureLoader({ source })}
      {children}
    </picture>
  )
})
export default Picture
