import dynamic from 'next/dynamic'

const ProductArrowRight = dynamic(() => import('./ProductArrowRight'))
const ProductArrowLeft = dynamic(() => import('./ProductArrowLeft'))
const ProductWarranty = dynamic(() => import('./ProductWarranty'))
const Product30day = dynamic(() => import('./Product30day'))
const ProductLifetime = dynamic(() => import('./ProductLifetime'))
const ProductShipping = dynamic(() => import('./ProductShipping'))
const ProductLiveChat = dynamic(() => import('./ProductLiveChat'))
const ProductReferral = dynamic(() => import('./ProductReferral'))
const ProductDownload = dynamic(() => import('./ProductDownload'))
const ProductDelayShip = dynamic(() => import('./ProductDelayShip'))
const ProductAva = dynamic(() => import('./ProductAva'))

const icons = (props: any) =>
  [
    {
      key: 'productArrowRight',
      icon: <ProductArrowRight {...props}></ProductArrowRight>,
    },
    {
      key: 'productArrowLeft',
      icon: <ProductArrowLeft {...props}></ProductArrowLeft>,
    },
    {
      key: 'productWarranty',
      icon: <ProductWarranty {...props}></ProductWarranty>,
    },
    {
      key: 'product30-day',
      icon: <Product30day {...props}></Product30day>,
    },
    {
      key: 'productLifetime',
      icon: <ProductLifetime {...props}></ProductLifetime>,
    },
    {
      key: 'productShipping',
      icon: <ProductShipping {...props}></ProductShipping>,
    },
    {
      key: 'productLiveChat',
      icon: <ProductLiveChat {...props}></ProductLiveChat>,
    },
    {
      key: 'productReferral',
      icon: <ProductReferral {...props}></ProductReferral>,
    },
    {
      key: 'productDownload',
      icon: <ProductDownload {...props}></ProductDownload>,
    },
    {
      key: 'productDelayShip',
      icon: <ProductDelayShip {...props}></ProductDelayShip>,
    },
    {
      key: 'productAva',
      icon: <ProductAva {...props}></ProductAva>,
    },
  ] as const

export default icons
