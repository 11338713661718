import { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import jump from 'jump.js'
import Icon from '@components/icons'

/**
 * 回到页面顶部按钮功能, 包含按钮出现消失的逻辑，将此组件作为页面父组件即可
 */
const Scroll = ({ children, className, show }) => {
  const ref = useRef()
  const [pos, setPos] = useState(false)

  const handleScroll = () => {
    if (window.scrollY >= 3500) {
      setPos(true)
    } else {
      setPos(false)
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    jump('#top', { duration: 500 })
    setPos(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div ref={ref} className={classNames('relative', className)}>
      {children}
      {pos && show ? (
        <a
          href="#top"
          className="fixed bottom-[278px] right-4 z-10 block cursor-pointer rounded-full min-md:bottom-[161px] min-md:right-10"
          onClick={handleClick}
          aria-label="go to top"
        >
          <Icon iconKey="gototop" className="w-12 text-white" />
        </a>
      ) : null}
    </div>
  )
}

export default Scroll
