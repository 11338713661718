var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3ebe3ce8b821a5a0dab3ebe47fbf03b861c10358"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  allowUrls: [/https?:\/\/((beta|release|uat|www)\.)?eufy\.com/],
  dsn: 'https://5d1e70bfa67dcbad4d4da455c965c921@o487797.ingest.us.sentry.io/4505748072366080',
  environment: process.env.NEXT_PUBLIC_ENV,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.008,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  replaysOnErrorSampleRate: 0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
  ],

  beforeSend: (event, hint) => {
    if (event.tags?.errorScope) {
      if (
        event.exception?.values?.some((value) => {
          const lastFrame =
            value.stacktrace?.frames?.[
              (value.stacktrace?.frames?.length || 0) - 1
            ]

          return lastFrame?.filename?.includes('src/client/script')
        })
      ) {
        return null
      }

      return event
    }

    if (
      event.exception?.values?.some(
        ({ value }) =>
          value?.includes("Unexpected token ':'") ||
          value?.includes("unexpected token: ':'") ||
          value?.includes('Retry count of 5 exceeded') ||
          value?.includes('The operation is not supported') ||
          value?.includes('Blocked a frame with origin') ||
          value?.includes('load error:') ||
          value?.includes('Loading chunk') ||
          value?.includes('Load failed') ||
          value?.includes('The operation is insecure') ||
          value?.includes('Failed to load bugsnag') ||
          value?.includes('ADMITAD')
      )
    ) {
      return null
    }

    if (
      event.exception?.values?.some((value) => {
        const lastFrame =
          value.stacktrace?.frames?.[
            (value.stacktrace?.frames?.length || 0) - 1
          ]

        return (
          lastFrame?.in_app &&
          lastFrame.filename &&
          lastFrame.filename !== '<anonymous>' &&
          !lastFrame.filename.includes('node_modules') &&
          !lastFrame.filename.includes('src/client/script') &&
          value.stacktrace?.frames?.some(
            (frame) => frame.function === 'onClick'
          )
        )
      })
    ) {
      event.tags = {
        ...event.tags,
        errorScope: 'Client',
        errorType: 'RuntimeClickError',
      }
      return event
    }

    return null
  },

  beforeSendTransaction: (event) => {
    if (event.environment !== 'production') {
      return null
    }

    // 监控us的首页和listing页
    if (
      event.transaction === '/' ||
      event.transaction?.startsWith('/products') ||
      event.transaction?.startsWith('/[...paths]')
    ) {
      event.transaction = window.location.pathname
      return event
    }

    return null
  },
})
