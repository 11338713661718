import metafieldsItems from './metafields-items-query'

export const getPageByHandleQuery = /* GraphQL */ `
  query getPage($handle: String!) {
    page(handle: $handle) {
        title
        handle
        body
        bodySummary
        seo {
            title
            description
        }
        MF_pageType: metafield(namespace: "pageType", key: "category") {
            ${metafieldsItems}
        }
        MF_banner: metafield(namespace: "global", key: "banner") {
            ${metafieldsItems}
        }
        MF_calendar: metafield(namespace: "global", key: "calendar") {
            ${metafieldsItems}
        }
        MF_previewData: metafield(namespace: "staging", key: "_preview") {
            ${metafieldsItems}
        }
        MF_seoSetting: metafield(namespace: "seo", key: "setting") {
            ${metafieldsItems}
        }
        MF_template : metafield(namespace: "template", key: "name") {
                            ${metafieldsItems}
        }
        MF_navSetting: metafield(namespace: "nav", key: "setting") {
                            ${metafieldsItems}
        }
        MF_country: metafield(namespace: "country", key: "setting") {
                            ${metafieldsItems}
        }
        MF_thirdparty: metafield(namespace: "thirdparty", key: "setting") {
                            ${metafieldsItems}
        }
        MF_content: metafield(namespace: "pages", key: "content") {
                            ${metafieldsItems}
        }
        MF_orderLimits: metafield(namespace: "settings", key: "orderLimits") {
            ${metafieldsItems}
        }
        MF_content1: metafield(namespace: "pages", key: "content1") {
                            ${metafieldsItems}
        }
        MF_common: metafield(namespace: "pages", key: "common") {
                            ${metafieldsItems}
        }
        MF_pageCommon: metafield(namespace: "page", key: "common") {
            ${metafieldsItems}
        }
        MF_relatedProducts: metafield(namespace: "relatedSource", key: "products") {
                            ${metafieldsItems}
        }
        MF_preRender: metafield(namespace: "build", key: "preRender") {
                            ${metafieldsItems}
        }
        MF_printing: metafield(namespace: "pages", key: "printing") {
                            ${metafieldsItems}
        }
        MF_component_order: metafield(namespace: "component", key: "_order") {
                            ${metafieldsItems}
        }
        MF_hotSpotsList: metafield(namespace: "component", key: "hotSpotsList") {
            ${metafieldsItems}
        }
        MF_referralInfo: metafield(namespace: "global", key: "info") {
            ${metafieldsItems}
        }
        MF_referralProducts: metafield(namespace: "global", key: "products") {
            ${metafieldsItems}
        }
        MF_liveHelper: metafield(namespace: "global", key: "live-helper") {
            ${metafieldsItems}
        }
        MF_registrationsDealsType: metafield(namespace: "global", key: "registrationsDealsType") {
            ${metafieldsItems}
        }
        MF_giftCard: metafield(namespace: "global", key: "giftCard") {
            ${metafieldsItems}
        }
        MF_hideSearch: metafield(namespace: "global", key: "hideSearch") {
            ${metafieldsItems}
        }
        MF_showNewPop: metafield(namespace: "global", key: "showNewPop") {
            ${metafieldsItems}
        }
        MF_liveStream: metafield(namespace: "global", key: "live_stream") {
            ${metafieldsItems}
        }
    }
  }
`
export default getPageByHandleQuery
