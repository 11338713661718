import cn from 'classnames'
import s from './ProductPrice.module.css'
import { formatCurrency } from '@lib/utils'
import { useCommerce } from '@shopify'

const ProductPrice = ({
  index,
  free,
  price = '',
  className = '',
  basePrice = '',
  availableForSale,
  settings,
  styles,
  comingSoon = '',
  variantInfos = {},
  isDigits = true,
  onClick,
}) => {
  const { shop } = useCommerce()

  return (
    <>
      <div
        onClick={() => onClick && onClick()}
        className={cn(className, s.prices)}
      >
        {availableForSale || settings?.noSoldOut ? (
          <>
            <span
              tag="Auto-000004241"
              className={cn(s.price, styles?.price, basePrice && 'mr-1', {
                'text-[#ed844a]': free !== false,
              })}
            >
              {free !== false
                ? settings?.free
                : formatCurrency(shop.moneyFormat, price, isDigits)}
            </span>
            {basePrice ? (
              <span
                className={cn(s.basePrice, styles?.basePrice, isDigits)}
                tag="Auto-000004242"
              >
                {formatCurrency(shop.moneyFormat, basePrice, isDigits)}
              </span>
            ) : null}
          </>
        ) : (
          <span
            tag="Auto-0000227"
            className={cn(s.soldOut, styles?.soldOut)}
            dangerouslySetInnerHTML={{
              __html:
                comingSoon || variantInfos?.comingSoon || settings?.soldOut,
            }}
          ></span>
        )}
      </div>
    </>
  )
}

export default ProductPrice
