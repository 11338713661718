import dynamic from 'next/dynamic'

const Phone = dynamic(() => import('./Phone'))
const Laptop = dynamic(() => import('./Laptop'))
const Tablet = dynamic(() => import('./Tablet'))
const Others = dynamic(() => import('./Others'))
const RollV6 = dynamic(() => import('./RollV6'))
const Built = dynamic(() => import('./Built'))
const Static = dynamic(() => import('./Static'))
const Lidar = dynamic(() => import('./Lidar'))
const Auto = dynamic(() => import('./Auto'))
const Self = dynamic(() => import('./Self'))
const Bristle = dynamic(() => import('./Bristle'))
const Play = dynamic(() => import('./Play'))
const Deep = dynamic(() => import('./Deep'))
const Gift = dynamic(() => import('./Gift'))
const Face = dynamic(() => import('./Face'))
const Person = dynamic(() => import('./Person'))
const Vehicle = dynamic(() => import('./Vehicle'))
const Pet = dynamic(() => import('./Pet'))
const Package = dynamic(() => import('./Package'))

const icons = (props: any) =>
  [
    {
      key: 'phone',
      icon: <Phone {...props}></Phone>,
    },
    {
      key: 'laptop',
      icon: <Laptop {...props}></Laptop>,
    },
    {
      key: 'tablet',
      icon: <Tablet {...props}></Tablet>,
    },
    {
      key: 'others',
      icon: <Others {...props}></Others>,
    },
    {
      key: 'RollV6',
      icon: <RollV6 {...props}></RollV6>,
    },
    {
      key: 'Built',
      icon: <Built {...props}></Built>,
    },
    {
      key: 'Static',
      icon: <Static {...props}></Static>,
    },
    {
      key: 'Lidar',
      icon: <Lidar {...props}></Lidar>,
    },
    {
      key: 'Auto',
      icon: <Auto {...props}></Auto>,
    },
    {
      key: 'Self',
      icon: <Self {...props}></Self>,
    },
    {
      key: 'Bristle',
      icon: <Bristle {...props}></Bristle>,
    },
    {
      key: 'Play',
      icon: <Play {...props}></Play>,
    },
    {
      key: 'Deep',
      icon: <Deep {...props}></Deep>,
    },
    {
      key: 'Gift',
      icon: <Gift {...props}></Gift>,
    },
    {
      key: 'face',
      icon: <Face {...props}></Face>,
    },
    {
      key: 'person',
      icon: <Person {...props}></Person>,
    },
    {
      key: 'vehicle',
      icon: <Vehicle {...props}></Vehicle>,
    },
    {
      key: 'pet',
      icon: <Pet {...props}></Pet>,
    },
    {
      key: 'package',
      icon: <Package {...props}></Package>,
    },
  ] as const
export default icons
