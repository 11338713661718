import { parse, stringify } from 'query-string'
import Cookies from 'js-cookie'

export const GTM_ID = process.env.NEXT_PUBLIC_I18N_GTM_ID

export const CLARITYID = {
  us: '4ufb04fc20',
  ca: 'cntgazf16j',
  uk: '4ufajrdbah',
  'eu-de': '4ufa1uvd2y',
  'eu-en': 'h44yvijk9b',
  nl: 'h44zes5i3t',
  'eu-fr': 'jrckfxnpbm',
  'eu-it': 'jtqokcrggt',
  'eu-es': 'krmzusa0nc',
  au: 'jf1phqgrw9',
  'cz-en': 'mfs3k7sn5f',
  'dk-en': 'mfs408609f',
  'gr-en': 'mfs4gx1lgq',
  'mk-en': 'mfs5jsrj15',
  'hu-en': 'mfs61mnlxn',
  'se-en': 'mfs6kujv8s',
  'hr-en': 'mfs7ajbet4',
  'bg-en': 'mfs7ndei12',
  'ee-en': 'mfs7ystozx',
  'fi-en': 'mfs8b4gxiq',
  'lt-en': 'mfs8oaq6pm',
  'pl-en': 'mfs8zbp35g',
  'ro-en': 'mfs9mj49fx',
  'si-en': 'mfs9wpl8d1',
  'sk-en': 'mfsa8jyclr',
  'no-en': 'mfsask2ccs',
  'ad-en': 'mfsb5vqia3',
  'al-en': 'mfsbjj26rv',
  'ba-en': 'mfsc11nqwr',
  'me-en': 'mfsce3b8lq',
  'rs-en': 'mfscom87jz',
  'gl-en': 'mfsczvwhdg',
  'is-en': 'mfsd818gc2',
  'ua-en': 'mfsdkdrnap',
  'by-en': 'mfse0pwa1n',
  'md-en': 'mfseb9teqc',
  'lv-en': 'mfselxu7z1',
  'at-de': 'mfsflvteh9',
  'lu-de': 'mfsfvud2ic',
  'li-de': 'mfsg52tsh3',
  'va-it': 'mfsgpsp58j',
  'sm-it': 'mfsh7ueppe',
  'be-nl': 'mfshhbeccc',
}

export const RECAPTCHA_SECRET = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY

export const GTMtagsToCollection = (tags) => {
  const collections = [
    {
      name: 'clean',
      tags: [
        'robovac',
        'clean',
        'Clean',
        'Appliances',
        'Appliance',
        'appliances',
        'appliance',
        'smart-dynamic-navigation-series',
        'bounce-series',
        'Vaccum',
        'cordless-handheld',
      ],
    },
    {
      name: 'security',
      tags: ['security', 'Security'],
    },
    {
      name: 'baby',
      tags: ['baby', 'Baby'],
    },
  ]

  const target = collections
    .filter((item) => {
      return item.tags.some(
        (tag) => tag === tags || tags?.some((t) => t?.trim() === tag)
      )
    })
    ?.pop()
  return target ? target?.name : ''
}

export const tagsToCollection = (tags) => {
  const collections = [
    {
      name: 'Security',
      handle: 'security-camera',
      deHandle: 'security-camera',
      tags: ['security', 'Security'],
    },
    {
      name: 'Clean',
      handle: 'clean',
      deHandle: 'clean',
      tags: [
        'robovac',
        'clean',
        'Clean',
        'Appliances',
        'Appliance',
        'appliances',
        'appliance',
        'smart-dynamic-navigation-series',
        'bounce-series',
        'Vaccum',
        'cordless-handheld',
      ],
    },
    {
      name: 'Baby',
      handle: 'baby',
      deHandle: 'babyphon',
      tags: ['baby', 'Baby'],
    },
  ]

  const target = collections
    .filter((item) => {
      return item.tags.some(
        (tag) => tag === tags || tags?.some((t) => t === tag)
      )
    })
    ?.pop()
  return (
    target || {
      name: 'All',
      handle: 'all',
    }
  )
}

// GTM - dataLayer
export const pageGTMEvent = (obj) => {
  if (typeof window === 'undefined') {
    return ''
  }
  window.dataLayer = window?.dataLayer || []
  try {
    window?.dataLayer?.push({ event_parameters: null })
    window?.dataLayer?.push(obj || {})
  } catch (error) {
    console.log('pageGTMEvent error', error)
  }
}

// Twttr
export const pageTwttr = (obj) => {
  if (!twttr.conversion.trackPid) return false
  twttr.conversion.trackPid(obj.event, { ...obj })
}

// google recaptcha
export const reExecute = async ({ action }) => {
  if (!window?.grecaptcha?.enterprise?.execute) return false
  else {
    return await window.grecaptcha.enterprise.execute(`${RECAPTCHA_SECRET}`, {
      action,
    })
  }
}

// 获取亚马逊链接参数，客户端才能使用此方法
export const getAmazonLinkParams = (asin, ad_campaign, locale) => {
  if (typeof window === 'undefined') {
    return ''
  }
  const massMapping = {
    us: 'maas_adg_api_8014460300101_static_6_26',
    'eu-de': 'maas_adg_api_3314480650102_macro_2_26',
    uk: 'maas_adg_api_588563440394542563_macro_1_26',
  }
  const q = (key) => parse(window.location.search)[key] || 'unknown'
  // console.log('q', parse(window.location.search))
  return stringify({
    maas: massMapping[locale],
    ref_: 'aa_maas',
    aa_campaignid: (ad_campaign || '') + (asin || ''),
    aa_adgroupid: q('utm_term'),
    aa_creativeid: `${q('utm_source')}_${q('utm_medium')}_${q('utm_campaign')}`,
  })
}

export const appendAMZPixel = (src) => {
  const img = document.createElement('img')
  img.src = src
  document.getElementById('amazon_pixel')?.appendChild(img)
}

export const admitadRetag = ({ code, level }) => {
  window._retag = window?._retag || []
  window._retag.push({ code, level })
  var id = 'admitad-retag'
  if (document.getElementById(id)) return
  var s = document.createElement('script')
  s.async = true
  s.id = id
  var r = new Date().getDate()
  s.src =
    (document.location.protocol == 'https:' ? 'https:' : 'http:') +
    '//cdn.lenmit.com/static/js/retag.js?r=' +
    r
  var a = document.getElementsByTagName('script')[0]
  a.parentNode.insertBefore(s, a)
}

export const getPersonaCookie = () => {
  // 活动 cookie
  return Cookies.get('persona')
}
