import s from './BannerLink.module.css'
import { Link } from '@components/ui'

// 全图 banner 链接 + banner 上有按钮的情况
// 或者产品集合的产品图的跳转
// 参考苹果，全图链接不作为无障碍链接，使用 banner 上的按钮作为无障碍链接
// 使用方法：将此组件和图片放在一个 relative 的盒子里即可
const BannerLink = ({ to, onClick = () => {}, ...rest }) => {
  if (!to) {
    return null
  }
  return (
    <Link
      className={s.link}
      href={to}
      aria-hidden="true"
      tabIndex="-1"
      onClick={onClick}
      {...rest}
    />
  )
}

export default BannerLink
