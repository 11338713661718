const Logo = ({ className = '', ...props }) => (
  <svg
    version="1.1"
    id="图层_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="32"
    height="32"
    viewBox="0 0 1084 237.33"
    style={{ enableBackground: 'new 0 0 1084 237.33' }}
    xmlSpace="preserve"
    className={className}
    {...props}
  >
    <path
      className="st0"
      style={{fill:'#88F387' }}
      d="M669.83,115.98c-1-1-2.63-1-3.63,0l-18.52,18.52c-1,1-1,2.63,0,3.63l18.52,18.52c1,1,2.63,1,3.63,0l18.52-18.52
      c1-1,1-2.63,0-3.63L669.83,115.98z"
    />
    <g>
      <path
        className="st1"
       style={{ fill:'#071D49' }}
        d="M550.66,106.93V92.81h-31.33v100.81h31.33v-52.2h0.02c0.32-11.22,6.99-20.21,19.25-20.21
        c5.97,0,9.65,0.88,14,2.07V94.91c-1.83-0.2-3.55-0.32-5.31-0.32C567.44,94.59,558.41,100.05,550.66,106.93z"
      />
      <path
        className="st1"
        d="M137.7,56.67h-33.22l-0.06,0.17L54.63,193.62h33.35l9.13-26.77h48.14l8.95,26.77h33.35L137.77,56.83
        L137.7,56.67z M104.99,143.73l16.42-48.14l16.1,48.14H104.99z"
      />
      <path
        className="st1"
        d="M259.67,95.87c-11.18,0-20.21,4.19-27.96,11.07V92.81h-31.33v100.81h31.33v-52.2h0.02
        c0.32-11.21,7.96-20.21,19.25-20.21c11.29,0,19,8.95,19.31,20.16l0,0.05v52.2h31.33v-53.43h-0.01c0-0.14,0.01-0.27,0.01-0.41
        C301.61,115.53,283.92,95.87,259.67,95.87z"
      />
      <path
        className="st1"
        d="M823.14,103.28c-5.76-6.71-16.01-10.49-25.9-10.49c0,0-0.47-0.02-1.06,0.01
        c-25.14,1.56-44.08,23.49-44.08,50.25c0,22.5,14.15,41.6,33.6,48.03c3.93,1.47,8.18,2.27,12.62,2.27c9.62,0,19.69-2.77,24.82-9.91
        v10.17h31.33V92.76h-31.33V103.28z M800.05,165.06c-12.16,0-22.02-9.86-22.02-22.02c0-12.16,9.86-22.02,22.02-22.02
        s22.02,9.86,22.02,22.02C822.07,155.21,812.21,165.06,800.05,165.06z"
      />
      <polygon
        className="st1"
        points="962.92,92.75 929.24,92.75 898.94,130.07 898.94,56.67 867.28,56.67 867.28,193.6 898.94,193.6 
        898.94,156.1 929.15,193.62 965.2,193.62 923.57,141.45 	"
      />
      <polygon
        className="st1"
        points="410.11,92.75 376.42,92.75 346.13,130.07 346.13,56.67 314.46,56.67 314.46,193.6 346.13,193.6 
        346.13,156.1 376.34,193.62 412.39,193.62 370.75,141.45 	"
      />
      <path
        className="st1"
        d="M628.1,74.24L628.1,74.24l-17.58-17.58l0,0h-13.75v30.55h0v106.4h31.33v-75.07l10.2,10.2c1,1,2.63,1,3.63,0
        l18.52-18.52c1-1,1-2.63,0-3.63L628.1,74.24z"
      />
      <path
        className="st1"
        d="M725.52,56.67l-17.58,17.58v0l-32.35,32.35c-1,1-1,2.63,0,3.63l18.52,18.52c1,1,2.63,1,3.63,0l10.2-10.2v75.07
        h31.33V87.22h0V56.66L725.52,56.67L725.52,56.67z"
      />
      <path
        className="st1"
        d="M1058.83,152.34c0.54-2.95,0.83-5.98,0.83-9.08c0-27.81-22.55-50.36-50.36-50.36
        c-27.81,0-50.36,22.55-50.36,50.36c0,27.81,22.55,50.36,50.36,50.36c21.76,0,40.3-13.81,47.33-33.14h-29.52
        c-4.09,5.37-10.55,8.84-17.82,8.84c-10.5,0-19.31-7.23-21.73-16.98h38.1H1058.83z M1009.31,117.49c10.16,0,18.73,6.76,21.47,16.03
        h-42.95C990.58,124.25,999.15,117.49,1009.31,117.49z"
      />
      <path
        className="st1"
        d="M456.21,92.9c-27.81,0-50.36,22.55-50.36,50.36c0,27.81,22.55,50.36,50.36,50.36
        c21.76,0,40.3-13.81,47.33-33.14h-29.52c-4.09,5.37-10.55,8.84-17.82,8.84c-10.5,0-19.31-7.23-21.73-16.98h38.1h33.16
        c0.54-2.95,0.83-5.98,0.83-9.08C506.57,115.45,484.02,92.9,456.21,92.9z M434.73,133.51c2.75-9.26,11.32-16.03,21.47-16.03
        s18.73,6.76,21.47,16.03H434.73z"
      />
    </g>
  </svg>
)

export default Logo
