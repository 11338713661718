import dynamic from 'next/dynamic'

const CreditSpecies = dynamic(() => import('./Species'))
const CreditCodes = dynamic(() => import('./Codes'))
const CreditDraw = dynamic(() => import('./Draw'))
const CreditGifts = dynamic(() => import('./Gifts'))
const CreditDiscounts = dynamic(() => import('./Discounts'))
const CreditLock = dynamic(() => import('./Lock'))
const CreditSubscribe = dynamic(() => import('./Subscribe'))
const CreditShop = dynamic(() => import('./Shop'))
const CreditProfile = dynamic(() => import('./Profile'))
const CreditEmail = dynamic(() => import('./Email'))
const CreditCar = dynamic(() => import('./Car'))

const icons = (props: any) =>
  [
    {
      key: 'creditSpecies',
      icon: <CreditSpecies {...props}></CreditSpecies>,
    },
    {
      key: 'creditCodes',
      icon: <CreditCodes {...props}></CreditCodes>,
    },
    {
      key: 'creditDraw',
      icon: <CreditDraw {...props}></CreditDraw>,
    },
    {
      key: 'creditGifts',
      icon: <CreditGifts {...props}></CreditGifts>,
    },
    {
      key: 'creditDiscounts',
      icon: <CreditDiscounts {...props}></CreditDiscounts>,
    },
    {
      key: 'creditLock',
      icon: <CreditLock {...props}></CreditLock>,
    },
    {
      key: 'creditSubscribe',
      icon: <CreditSubscribe {...props}></CreditSubscribe>,
    },
    {
      key: 'creditShop',
      icon: <CreditShop {...props}></CreditShop>,
    },
    {
      key: 'creditProfile',
      icon: <CreditProfile {...props}></CreditProfile>,
    },
    {
      key: 'creditEmail',
      icon: <CreditEmail {...props}></CreditEmail>,
    },
    {
      key: 'creditCar',
      icon: <CreditCar {...props}></CreditCar>,
    },
  ] as const

export default icons
