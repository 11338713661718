import { i18n, features } from 'commerce.config'

import { getDataFromFeature } from '@lib/utils/tools'

export const BRAND = process.env.NEXT_PUBLIC_BRAND || 'eufy'

export const COMMERCE_FEATURES = features

export const SHOPIFY_COOKIE_EXPIRE = 30

export const USE_DEFAULT_LOGIN = process.env.NEXT_PUBLIC_USE_DEFAULT_LOGIN

export const MULPASS_URL =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? `https://passport.${BRAND}.com`
    : `https://beta.passport.${BRAND}.com`

export const API_TOKEN = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN

export const STORE_DOMAIN = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN

export const API_URL = `https://${STORE_DOMAIN}/api/2024-01/graphql.json`

export const SHORT_LOCALE_MAP: Record<string, string> = getDataFromFeature({
  features,
  key: 'shortLocale',
})

export const LOCALE_ASCRIPTION_MAP: Record<string, string> = getDataFromFeature(
  { features, key: 'sourceDataLocale' }
)

export const LISTING_SHOW_CHECKOUT_MAP: Record<string, boolean> =
  getDataFromFeature({ features, key: 'showCheckoutBar' })

export const I18N_API_TOKEN =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? getDataFromFeature({ features, key: 'storefrontToken' })
    : getDataFromFeature({ features, key: 'betaStorefrontToken' })

export const I18N_STORE_DOMAIN = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    const ASLOCALE_MAP = LOCALE_ASCRIPTION_MAP[locale] || locale
    result[locale] = `${
      process.env.NEXT_PUBLIC_ENV === 'production' ? '' : 'beta-'
    }${BRAND}-${
      SHORT_LOCALE_MAP[ASLOCALE_MAP || ''] || ASLOCALE_MAP
    }.myshopify.com`
  })
  return result
})()

export const FAKE_PRICE = 9999999.99

export const I18N_THEME_DOMAIN = I18N_STORE_DOMAIN
  ? (function () {
      const { locales = [] } = i18n
      const result: { [key: string]: string } = {}
      locales.forEach((locale) => {
        result[locale] =
          process.env.NEXT_PUBLIC_ENV === 'production'
            ? `${SHORT_LOCALE_MAP[locale] || locale}.${BRAND}.com`
            : I18N_STORE_DOMAIN[locale]
      })
      return result
    })()
  : ''

export const I18N_API_URL =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? I18N_THEME_DOMAIN &&
      (function () {
        const result: { [key: string]: string } = {}
        Object.keys(I18N_THEME_DOMAIN).forEach((locale) => {
          result[
            locale
          ] = `https://${I18N_THEME_DOMAIN[locale]}/api/2024-01/graphql.json`
        })
        return result
      })()
    : I18N_STORE_DOMAIN &&
      (function () {
        const result: { [key: string]: string } = {}
        Object.keys(I18N_STORE_DOMAIN).forEach((locale) => {
          result[
            locale
          ] = `https://${I18N_STORE_DOMAIN[locale]}/api/2024-01/graphql.json`
        })
        return result
      })()

export const SHOPIFY_CART_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCart_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CHECKOUT_ID_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCheckoutId_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CHECKOUT_URL_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCheckoutUrl_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CUSTOMER_TOKEN_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCustomerToken_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const ORIGIN_URL = {
  eufy: 'https://www.eufy.com',
}[BRAND]

export const EUFY_COUNTRY_SUFFIX: {
  [key: string]: string
} = getDataFromFeature({ features, key: 'title' })
