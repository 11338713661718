// start_ai_generated
import cn from 'classnames'

const Mask = ({ className = '', onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'absolute left-0 top-0 h-full w-full bg-[#000]/70',
        className
      )}
    ></div>
  )
}

export default Mask
// end_ai_generated
