import { useRouter } from 'next/router'
import { FC } from 'react'
import { languageTerritory } from '@commerce/product/use-price'
import cn from 'classnames'
interface Props {
  settings: any
  price: number
  className?: string
}

const Klarna: FC<Props> = ({ settings, price, className }) => {
  const { locale } = useRouter()
  const klarnaLocale = languageTerritory(locale || 'us')

  return (
    <div className={cn('klarna', className)}>
      {
        // @ts-ignore
        <klarna-placement
          data-key="credit-promotion-badge"
          data-locale={settings?.locale || klarnaLocale}
          data-purchase-amount={price * 100}
          // @ts-ignore
        ></klarna-placement>
      }
    </div>
  )
}

export default Klarna
