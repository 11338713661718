import React, { FC, useEffect, useMemo, useState } from 'react'

import { I18N_STORE_DOMAIN } from '@shopify/const'
import Script from 'next/script'
import { useUI } from '@components/ui/context'
import useCustomer from '@shopify/customer/use-customer'

// Extend the Window interface to include __shulexConfig
declare global {
  interface Window {
    __shulexConfig?: {
      email: string
      brand: string
      country?: string
      language?: string
    }
  }
}

function isValidKey(
  key: string | number | symbol,
  object: object
): key is keyof typeof object {
  return key in object
}

// eslint-disable-next-line no-unused-vars
enum CustomerServiceType {
  // eslint-disable-next-line no-unused-vars
  ComponentLiveChat = 'ComponentLiveChat',
  // eslint-disable-next-line no-unused-vars
  ComponentFirework = 'ComponentFirework',
  // eslint-disable-next-line no-unused-vars
  ComponentLiveStream = 'ComponentLiveStream',
}

interface Fwcdn3Metafields {
  storyblockChannelId?: string
  livehelperChannelId?: string
  storyblockPlaylist?: string
}

interface CustomerServiceComponents {
  ComponentLiveChat?: React.JSX.Element
  ComponentFirework?: any
  ComponentLiveStream?: any
}

interface CustomerServiceStrategyProps {
  locale?: string
  fwcdn3Metafields?: Fwcdn3Metafields | any
  path?: string
  pathname?: string
  liveHelper?: string | boolean
  liveChat: boolean
  metafields: any
}

export const useCustomerService = ({
  liveHelper = false,
  liveChat = false,
  fwcdn3Metafields,
  metafields,
}: CustomerServiceStrategyProps) => {
  const [customerService, setCustomerService] = useState('')
  const { setCustomerServiceType } = useUI()
  const { data: customer } = useCustomer()
  const thirdparty = metafields?.thirdparty

  // const handleFireworkOneOneStatus = useCallback(
  //   (agents_available: any) => {
  //     if (agents_available && fwcdn3Metafields?.livehelperChannelId) {
  //       setCustomerService(CustomerServiceType.ComponentFirework)
  //     } else {
  //       setCustomerService(CustomerServiceType.ComponentLiveChat)
  //     }
  //   },
  //   [fwcdn3Metafields?.livehelperChannelId]
  // )

  // const getAgentsAvailability = useCallback(async () => {
  //   const res = await fetch(
  //     `https://fireworkapi1.com/api/chat_channels/${fwcdn3Metafields?.livehelperChannelId}/agents_availability`
  //   )
  //   const { agents_available } = await res.json()
  //   handleFireworkOneOneStatus(agents_available)
  // }, [handleFireworkOneOneStatus, fwcdn3Metafields?.livehelperChannelId])

  useEffect(() => {
    if (liveHelper === 'true' && fwcdn3Metafields?.livehelperChannelId) {
      setCustomerService(CustomerServiceType.ComponentFirework)
    } else if (liveChat) {
      setCustomerService(CustomerServiceType.ComponentLiveChat)
    }
  }, [liveHelper, liveChat, fwcdn3Metafields?.livehelperChannelId])

  useEffect(() => {
    setCustomerServiceType(customerService)
  }, [customerService, setCustomerServiceType])

  useEffect(() => {
    window.__shulexConfig = {
      email: customer?.email || '',
      brand: 'eufy',
      country: thirdparty?.liveChat?.country,
      language: thirdparty?.liveChat?.language,
    }
  }, [customer, thirdparty?.liveChat?.country, thirdparty?.liveChat?.language])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const myElement = document.querySelector('shulex-chatbot-lancher')

      if (myElement) {
        // 然后获取该元素的Shadow root
        const shadowRoot = myElement.shadowRoot

        // 在Shadow root中查找你需要的元素
        const innerElement = shadowRoot?.getElementById('livechat_launcher_btn')

        // 现在你可以对innerElement进行操作
        if (innerElement) {
          // 为元素添加 z-index 属性
          innerElement.style.setProperty('z-index', '50', 'important')

          // 如果屏幕宽度小于 768px，设置样式
          if (window.matchMedia('(max-width: 768px)').matches) {
            innerElement.style.setProperty('bottom', '140px', 'important')
            innerElement.style.setProperty('right', '1rem', 'important')
          }
        }
        clearInterval(intervalId)
      }
    }, 100)

    // 清理函数，组件卸载时清除定时器
    return () => clearInterval(intervalId)
  }, [])

  return { customerServiceType: customerService }
}

const CustomerServiceStrategy: FC<CustomerServiceStrategyProps> = ({
  locale,
  path = '',
  pathname = '',
  liveHelper = false,
  liveChat = false,
  fwcdn3Metafields,
  metafields,
}) => {
  const { customerServiceType } = useCustomerService({
    locale,
    path,
    pathname,
    liveHelper,
    liveChat,
    fwcdn3Metafields,
    metafields,
  })

  const CustomerServiceComponents: CustomerServiceComponents = useMemo(() => {
    return {
      // liveChat
      ComponentLiveChat: (
        <>
          {metafields?.thirdparty?.liveChat?.id ? (
            <Script
              id="liveChat"
              src={`https://apps.voc.ai/api_v2/gpt/bots/livechat/embed.js?id=${metafields?.thirdparty?.liveChat?.id}&token=${metafields?.thirdparty?.liveChat?.token}`}
            ></Script>
          ) : (
            <>
              <Script
                type="text/javascript"
                id="livechat_script"
                src="https://service.force.com/embeddedservice/5.0/esw.min.js"
              />
              <Script
                type="text/javascript"
                id="livechat_code"
                defer
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                  __html: `function getQueryVariable(variable) {
          var query = window.location.search.substring(1);
          var vars = query.split("&");
          for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
          }
          return(false);
        }
        var initESW = function(gslbBaseURL) {
          embedded_svc.settings.displayHelpButton = true;
          embedded_svc.settings.language = 'en';
          embedded_svc.settings.defaultMinimizedText = 'Live Chat';
          embedded_svc.settings.disabledMinimizedText = 'Live Chat';
          embedded_svc.settings.chatbotAvatarImgURL = 'https://support.soundcore.com/resource/ankerBotHeaderPic';
          embedded_svc.settings.extraPrechatInfo = [{
            "entityName":"Contact",
            "saveToTranscript":"Contact",
            "showOnCreate":true,
            "entityFieldMaps":[
              {
                "doCreate":true,
                "doFind":false,
                "fieldName":"LastName",
                "isExactMatch":false,
                "label":"Last Name"
              },{
                "doCreate":true,
                "doFind":false,
                "fieldName":"FirstName",
                "isExactMatch":false,
                "label":"First Name"
              },{
                "doCreate":true,
                "doFind":true,
                "fieldName":"ContactKey__c",
                "isExactMatch":true,
                "label":"Email"
              },{
                "doCreate":true,
                "doFind":false,
                "fieldName":"Send_Transcript__c",
                "isExactMatch":false,
                "label":"Need the chat transcript?"
              }
            ]
          }];
          embedded_svc.settings.extraPrechatFormDetails = [
            {
              "label": "PageUrl",
              "value":  window.location.href,
              "displayToAgent": true,
              "transcriptFields": ["PageUrl__c"]
            },{
              "label":"First Name", 
              "transcriptFields": ["First_Name__c"]
            },{
              "label":"Last Name", 
              "transcriptFields": ["Last_Name__c"]
            },{
              "label":"Email", 
              "transcriptFields": ["Email__c"]
            },{
              "label":"Need the chat transcript?", 
              "transcriptFields": ["Email_Transcript__c"]
            },{
              "label":"Source", 
              "value":  "Web Site",
              "transcriptFields": ["Source__c"]
            },{
              "label":"Source Desc", 
              "value": '${I18N_STORE_DOMAIN[locale || 'us']}',
              "transcriptFields": ["Source_Desc__c"]
            },{
              "label":"Custom Language", 
              "value":  "EN",
              "transcriptFields": ["Custom_Language__c"]
            },{
                "label":"Brand", 
                "value":  "eufy",
                "transcriptFields": ["Brand__c"]
            }
          ];
          embedded_svc.settings.enabledFeatures = ['LiveAgent'];
          embedded_svc.settings.entryFeature = 'LiveAgent';
          embedded_svc.init(
            'https://ankertechnologycompanyltd.my.salesforce.com',
            'https://support.eufy.com',
            gslbBaseURL,
            '00D5g000004DkWQ',
            'Eufylife',
            {
              baseLiveAgentContentURL: 'https://c.la2-c1-ukb.salesforceliveagent.com/content',
              deploymentId: '5725g000000kKK5',
              buttonId: '5735g000000kLWl',
              baseLiveAgentURL: 'https://d.la2-c1-ukb.salesforceliveagent.com/chat',
              eswLiveAgentDevName: 'Eufylife',
              isOfflineSupportEnabled: false
            }
          );
        };
        if (!window.embedded_svc) {
          var s = document.createElement('script');
          s.setAttribute('src', 'https://ankertechnologycompanyltd.my.salesforce.com/embeddedservice/5.0/esw.min.js');
          s.onload = function() {
            initESW(null);
          };
          document.body.appendChild(s);
        } else {
          initESW('https://service.force.com');
        }`,
                }}
              />
            </>
          )}
        </>
      ),
      // firework1v1 导购
      ComponentFirework: (
        <>
          {/* channel_id：jgwYe5/RoPnvA 测试渠道id 
            channel_id：W5EPvy 线上渠道id */}
          {/* <Script
            fw-script
            async
            strategy="lazyOnload"
            type="text/javascript"
            src="https://asset.fwcdn3.com/js/live-helper.js"
            data-fwparam_chat_channel_id={fwcdn3Metafields?.livehelperChannelId}
          ></Script> */}
          {/* @ts-ignore */}
          <fw-live-helper
            style={{ zIndex: 59, position: 'fixed' }}
            class="fw-live-helper"
            bypass_sa_availability
            chat_channel_id={fwcdn3Metafields?.livehelperChannelId}
          />
          {/* <FireworkOneOne /> */}
        </>
      ),
      // 直播
      // ComponentLiveStream: (
      //   // @ts-ignore
      //   <fw-storyblock
      //     channel="eufy161624707"
      //     playlist="5aNepo"
      //     mode="pinned"
      //     autoplay="true"
      //     player_placement="bottom-left"
      //     // @ts-ignore
      //   ></fw-storyblock>
      // ),
    }
  }, [
    locale,
    fwcdn3Metafields?.livehelperChannelId,
    metafields?.thirdparty?.liveChat?.id,
    metafields?.thirdparty?.liveChat?.token,
  ])

  const CustomerServiceComponent = isValidKey(
    customerServiceType,
    CustomerServiceComponents
  )
    ? CustomerServiceComponents[customerServiceType]
    : null
  return CustomerServiceComponent
}

export default CustomerServiceStrategy
