import dynamic from 'next/dynamic'

const Menu = dynamic(() => import('./Menu'))
const NavMenu = dynamic(() => import('./NavMenu'))
const NavBack = dynamic(() => import('./NavBack'))
const NavClose = dynamic(() => import('./NavClose'))
const Countries = dynamic(() => import('./Countries'))
const Innovation = dynamic(() => import('./Innovation'))
const User = dynamic(() => import('./User'))
const Users = dynamic(() => import('./Users'))
const Download = dynamic(() => import('./Download'))
const TriangleDown = dynamic(() => import('./TriangleDown'))
const Chat = dynamic(() => import('./Chat'))
const Fast = dynamic(() => import('./Fast'))
const Hassle = dynamic(() => import('./Hassle'))
const MoneyBack = dynamic(() => import('./MoneyBack'))
const Close = dynamic(() => import('./Close'))
const Cart = dynamic(() => import('./Cart'))
const Anker = dynamic(() => import('./Anker'))
const Search = dynamic(() => import('./Search'))
const NavSearch = dynamic(() => import('./NavSearch'))
const NavLive = dynamic(() => import('./NavLive'))
const NavUser = dynamic(() => import('./NavUser'))
const Clock = dynamic(() => import('./Clock'))
const Year5 = dynamic(() => import('./Year5'))
const Card = dynamic(() => import('./Card'))
const Question = dynamic(() => import('./Question'))
const XCircle = dynamic(() => import('./XCircle'))
const LiveChat = dynamic(() => import('./LiveChat'))
const UserAnkerMake = dynamic(() => import('./UserAnkerMake'))
const UserAnkerMakeMob = dynamic(() => import('./UserAnkerMakeMob'))
const Play = dynamic(() => import('./Play'))
const CartAnkerMake = dynamic(() => import('./CartAnkerMake'))
const CartEufy = dynamic(() => import('./CartEufy'))
const Deal = dynamic(() => import('./Deal'))
const FastAirPlane = dynamic(() => import('./FastAirPlane'))
const Guarantee = dynamic(() => import('./Guarantee'))
const PayLater = dynamic(() => import('./PayLater'))
const OrderHours = dynamic(() => import('./OrderHours'))
const SalesTax = dynamic(() => import('./SalesTax'))
const Warranty = dynamic(() => import('./Warranty'))
const ArrowDown = dynamic(() => import('./ArrowDown'))
const ArrowPrev = dynamic(() => import('./ArrowPrev'))
const ArrowNext = dynamic(() => import('./ArrowNext'))
const Service = dynamic(() => import('./Service'))
const ProductTag = dynamic(() => import('./ProductTag'))
const Tell = dynamic(() => import('./Tell'))
const Email = dynamic(() => import('./Email'))
const LiveChatFooter = dynamic(() => import('./LiveChatFooter'))
const WarrantyFooter = dynamic(() => import('./WarrantyFooter'))
const GuaranteeFooter = dynamic(() => import('./GuaranteeFooter'))
const FastFooter = dynamic(() => import('./FastFooter'))
const Support = dynamic(() => import('./Support'))
const AnkerFooter = dynamic(() => import('./AnkerFooter'))
const AnkermakeFooter = dynamic(() => import('./AnkermakeFooter'))
const EufyFooter = dynamic(() => import('./EufyFooter'))
const NebulaFooter = dynamic(() => import('./NebulaFooter'))
const SoundcoreFooter = dynamic(() => import('./SoundcoreFooter'))
const AnkerworkFooter = dynamic(() => import('./AnkerworkFooter'))
const FaceBook = dynamic(() => import('./FaceBook'))
// const Twitter = dynamic(() => import('./Twitter'))
const LinkedIn = dynamic(() => import('./LinkedIn'))
const Instagram = dynamic(() => import('./Instagram'))
const MyRegistry = dynamic(() => import('./MyRegistry'))
const Back = dynamic(() => import('./Back'))
const Minus = dynamic(() => import('./Minus'))
const Plus = dynamic(() => import('./Plus'))
const SlickPrev = dynamic(() => import('./SlickPrev'))
const SlickNext = dynamic(() => import('./SlickNext'))
const SwipePre = dynamic(() => import('./SwipePre'))
const SwipeNext = dynamic(() => import('./SwipeNext'))
const Note = dynamic(() => import('./Note'))
const EufyClose = dynamic(() => import('./EufyClose'))
const Replay = dynamic(() => import('./Replay'))
const ArrowLeft = dynamic(() => import('./ArrowLeft'))
const ArrowRight = dynamic(() => import('./ArrowRight'))
const CircleLeft = dynamic(() => import('./CircleLeft'))
const CircleRight = dynamic(() => import('./CircleRight'))
const CirclePrev = dynamic(() => import('./CirclePrev'))
const CircleNext = dynamic(() => import('./CircleNext'))
const TipsCorrect = dynamic(() => import('./TipsCorrect'))
const TipsError = dynamic(() => import('./TipsError'))
const CheckBoxCorrect = dynamic(() => import('./CheckBoxCorrect'))
const Delete = dynamic(() => import('./Delete'))
const Loading = dynamic(() => import('./Loading'))
const ArrowPlain = dynamic(() => import('./ArrowPlain'))
const ChevronDown = dynamic(() => import('./ChevronDown'))
const PlusFill = dynamic(() => import('./PlusFill'))
const LongArrow = dynamic(() => import('./LongArrow'))
const PlusFillRect = dynamic(() => import('./PlusFillRect'))
const MinusFillRect = dynamic(() => import('./MinusFillRect'))
const Check = dynamic(() => import('./Check'))
const ToTop = dynamic(() => import('./ToTop'))
const Badge = dynamic(() => import('./Badge'))
const RightSuccess = dynamic(() => import('./RightSuccess'))

const icons = (props: any) =>
  [
    {
      key: 'menu',
      icon: <Menu {...props}></Menu>,
    },
    {
      key: 'navmenu',
      icon: <NavMenu {...props}></NavMenu>,
    },
    {
      key: 'navback',
      icon: <NavBack {...props}></NavBack>,
    },
    {
      key: 'navclose',
      icon: <NavClose {...props}></NavClose>,
    },
    {
      key: 'deal',
      icon: <Deal {...props}></Deal>,
    },
    {
      key: 'tell',
      icon: <Tell {...props}></Tell>,
    },
    {
      key: 'faceBook',
      icon: <FaceBook {...props}></FaceBook>,
    },
    // {
    //   key: 'twitter',
    //   icon: <Twitter {...props}></Twitter>,
    // },
    {
      key: 'linkedIn',
      icon: <LinkedIn {...props}></LinkedIn>,
    },
    {
      key: 'instagram',
      icon: <Instagram {...props}></Instagram>,
    },
    {
      key: 'liveChatFooter',
      icon: <LiveChatFooter {...props}></LiveChatFooter>,
    },
    {
      key: 'email',
      icon: <Email {...props}></Email>,
    },
    {
      key: 'warrantyFooter',
      icon: <WarrantyFooter {...props}></WarrantyFooter>,
    },
    {
      key: 'guaranteeFooter',
      icon: <GuaranteeFooter {...props}></GuaranteeFooter>,
    },
    {
      key: 'fastFooter',
      icon: <FastFooter {...props}></FastFooter>,
    },
    {
      key: 'support',
      icon: <Support {...props}></Support>,
    },
    {
      key: 'ankerFooter',
      icon: <AnkerFooter {...props}></AnkerFooter>,
    },
    {
      key: 'ankermakeFooter',
      icon: <AnkermakeFooter {...props}></AnkermakeFooter>,
    },
    {
      key: 'eufyFooter',
      icon: <EufyFooter {...props}></EufyFooter>,
    },
    {
      key: 'nebulaFooter',
      icon: <NebulaFooter {...props}></NebulaFooter>,
    },
    {
      key: 'soundcoreFooter',
      icon: <SoundcoreFooter {...props}></SoundcoreFooter>,
    },
    {
      key: 'ankerworkFooter',
      icon: <AnkerworkFooter {...props}></AnkerworkFooter>,
    },
    {
      key: 'fast-air-plane',
      icon: <FastAirPlane {...props}></FastAirPlane>,
    },
    {
      key: 'guarantee',
      icon: <Guarantee {...props}></Guarantee>,
    },
    {
      key: 'pay-later',
      icon: <PayLater {...props}></PayLater>,
    },
    {
      key: 'order-hours',
      icon: <OrderHours {...props}></OrderHours>,
    },
    {
      key: 'sales-tax',
      icon: <SalesTax {...props}></SalesTax>,
    },
    {
      key: 'warranty',
      icon: <Warranty {...props}></Warranty>,
    },
    {
      key: 'clock',
      icon: <Clock {...props}></Clock>,
    },
    {
      key: 'cart',
      icon: <Cart {...props}></Cart>,
    },
    {
      key: 'search',
      icon: <Search {...props}></Search>,
    },
    {
      key: 'navsearch',
      icon: <NavSearch {...props}></NavSearch>,
    },
    {
      key: 'navlive',
      icon: <NavLive {...props}></NavLive>,
    },
    {
      key: 'navuser',
      icon: <NavUser {...props}></NavUser>,
    },
    {
      key: 'close',
      icon: <Close {...props}></Close>,
    },
    {
      key: 'countries',
      icon: <Countries {...props}></Countries>,
    },
    {
      key: 'innovation',
      icon: <Innovation {...props}></Innovation>,
    },
    {
      key: 'users',
      icon: <Users {...props}></Users>,
    },
    {
      key: 'user',
      icon: <User {...props}></User>,
    },
    {
      key: 'userankermake',
      icon: <UserAnkerMake {...props}></UserAnkerMake>,
    },
    {
      key: 'userankermakemob',
      icon: <UserAnkerMakeMob {...props}></UserAnkerMakeMob>,
    },
    {
      key: 'cartankermake',
      icon: <CartAnkerMake {...props}></CartAnkerMake>,
    },
    {
      key: 'carteufy',
      icon: <CartEufy {...props}></CartEufy>,
    },
    {
      key: 'download',
      icon: <Download {...props}></Download>,
    },
    {
      key: 'triangleDown',
      icon: <TriangleDown {...props}></TriangleDown>,
    },
    {
      key: 'chat',
      icon: <Chat {...props}></Chat>,
    },
    {
      key: 'fast',
      icon: <Fast {...props}></Fast>,
    },
    {
      key: 'hassle',
      icon: <Hassle {...props}></Hassle>,
    },
    {
      key: 'moneyBack',
      icon: <MoneyBack {...props}></MoneyBack>,
    },
    {
      key: 'anker',
      icon: <Anker {...props}></Anker>,
    },
    {
      key: 'year5',
      icon: <Year5 {...props}></Year5>,
    },
    {
      key: 'card',
      icon: <Card {...props}></Card>,
    },
    {
      key: 'question',
      icon: <Question {...props}></Question>,
    },
    {
      key: 'XCircle',
      icon: <XCircle {...props}></XCircle>,
    },
    {
      key: 'liveChat',
      icon: <LiveChat {...props}></LiveChat>,
    },
    {
      key: 'arrowDown',
      icon: <ArrowDown {...props}></ArrowDown>,
    },
    {
      key: 'arrowPrev',
      icon: <ArrowPrev {...props}></ArrowPrev>,
    },
    {
      key: 'arrowNext',
      icon: <ArrowNext {...props}></ArrowNext>,
    },
    {
      key: 'service',
      icon: <Service {...props}></Service>,
    },
    {
      key: 'play',
      icon: <Play {...props}></Play>,
    },
    {
      key: 'productTag',
      icon: <ProductTag {...props}></ProductTag>,
    },
    {
      key: 'MyRegistry',
      icon: <MyRegistry {...props}></MyRegistry>,
    },
    {
      key: 'back',
      icon: <Back {...props}></Back>,
    },
    {
      key: 'minus',
      icon: <Minus {...props}></Minus>,
    },
    {
      key: 'plus',
      icon: <Plus {...props}></Plus>,
    },
    {
      key: 'slickPrev',
      icon: <SlickPrev {...props}></SlickPrev>,
    },
    {
      key: 'slickNext',
      icon: <SlickNext {...props}></SlickNext>,
    },
    {
      key: 'note',
      icon: <Note {...props}></Note>,
    },
    {
      key: 'eufy-close',
      icon: <EufyClose {...props}></EufyClose>,
    },
    {
      key: 'swipePre',
      icon: <SwipePre {...props}></SwipePre>,
    },
    {
      key: 'swipeNext',
      icon: <SwipeNext {...props}></SwipeNext>,
    },
    {
      key: 'replay',
      icon: <Replay {...props}></Replay>,
    },
    {
      key: 'arrowLeft',
      icon: <ArrowLeft {...props}></ArrowLeft>,
    },
    {
      key: 'arrowRight',
      icon: <ArrowRight {...props}></ArrowRight>,
    },
    {
      key: 'circleLeft',
      icon: <CircleLeft {...props}></CircleLeft>,
    },
    {
      key: 'circleRight',
      icon: <CircleRight {...props}></CircleRight>,
    },
    {
      key: 'circlePrev',
      icon: <CirclePrev {...props}></CirclePrev>,
    },
    {
      key: 'circleNext',
      icon: <CircleNext {...props}></CircleNext>,
    },
    {
      key: 'tipsCorrect',
      icon: <TipsCorrect {...props}></TipsCorrect>,
    },
    {
      key: 'tipsError',
      icon: <TipsError {...props}></TipsError>,
    },
    {
      key: 'checkBoxCorrect',
      icon: <CheckBoxCorrect {...props}></CheckBoxCorrect>,
    },
    {
      key: 'delete',
      icon: <Delete {...props}></Delete>,
    },
    {
      key: 'loading',
      icon: <Loading {...props}></Loading>,
    },
    {
      key: 'arrowPlain',
      icon: <ArrowPlain {...props} />,
    },
    {
      key: 'chevronDown',
      icon: <ChevronDown {...props} />,
    },
    {
      key: 'plusFill',
      icon: <PlusFill {...props} />,
    },
    {
      key: 'longArrow',
      icon: <LongArrow {...props} />,
    },
    {
      key: 'plusFillRect',
      icon: <PlusFillRect {...props} />,
    },
    {
      key: 'minusFillRect',
      icon: <MinusFillRect {...props} />,
    },
    {
      key: 'check',
      icon: <Check {...props} />,
    },
    {
      key: 'gototop',
      icon: <ToTop {...props} />,
    },
    {
      key: 'badge',
      icon: <Badge {...props} />,
    },
    {
      key: 'rightSuccess',
      icon: <RightSuccess {...props} />,
    },
  ] as const

export type IconKey = ReturnType<typeof icons>[number]['key']
export default icons
