import { formatCurrency } from '@lib/utils'
import { useCommerce } from '@shopify'
import { CouponType } from 'templates/Pages/Credit/const'

export default function useDiscount({
  discountType,
  value,
}: {
  discountType: CouponType | 'percentage'
  value: string | number
}) {
  value = Math.abs(Number(value))
  const { shop } = useCommerce()
  const moneyFormat = shop.moneyFormat
  let formated = ''
  switch (discountType) {
    case 'fixed_amount':
      formated = formatCurrency(moneyFormat, value)
      break
    case 'percent':
    case 'percentage':
      formated = `${value}%`
      break
    default:
  }

  return [formated]
}
