import Cookies from 'js-cookie'

import {
  SHOPIFY_CHECKOUT_ID_COOKIE,
  SHOPIFY_CHECKOUT_URL_COOKIE,
  SHOPIFY_COOKIE_EXPIRE,
} from 'lib/config/const'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { createCartMutation } from 'lib/shopify/mutations/cart'
import { Cart as CartType } from 'lib/shopify/types'

export default async function createCart(
  locale: string = 'us',
  lines: { merchandiseId: string; quantity: number }[],
  attributes?: {},
  options: {
    override: boolean // 是否写入cookie覆盖当前购物车
    buyerIdentity?: any
    autoApplyCode?: boolean
    discountCodes?: string[]
  } = {
    override: true,
  }
): Promise<CartType.Cart> {
  const {
    override = true,
    buyerIdentity,
    autoApplyCode,
    discountCodes,
  } = options
  // 活动折扣 cookie 全站生效，后端拿不到，所以暂时注释
  // const personaCookie = getPersonaCookie()
  const variables1 =
    attributes && Object.keys(attributes).length
      ? { lines, attributes }
      : { lines }
  const variables2 =
    autoApplyCode && discountCodes && discountCodes.length
      ? { ...variables1, discountCodes }
      : variables1
  const variables =
    buyerIdentity && Object.keys(buyerIdentity).length
      ? { ...variables2, buyerIdentity }
      : variables2

  const { res, status } =
    await fetchGraphqlApi<CartType.ShopifyCreateCartOperation>({
      locale,
      query: createCartMutation,
      variables,
      cache: 'no-store',
    })

  const cart = res?.cartCreate?.cart
  const checkoutUrlCookie = SHOPIFY_CHECKOUT_URL_COOKIE[locale]
  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]

  if (cart && override) {
    const checkoutId = cart?.id
    const options = {
      Domain: window.location.hostname,
      SameSite: 'Strict',
      Secure: true,
      expires: SHOPIFY_COOKIE_EXPIRE,
    }
    Cookies.set(checkoutIdCookie, checkoutId, options)
    if (cart?.checkoutUrl) {
      Cookies.set(checkoutUrlCookie, cart.checkoutUrl, options)
    }
  }

  return cart!
}
