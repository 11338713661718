import dynamic from 'next/dynamic'

const Messenger = dynamic(() => import('./Messenger'))
const Refer = dynamic(() => import('./Refer'))

const icons = (props: any) =>
  [
    {
      key: 'messenger',
      icon: <Messenger {...props}></Messenger>,
    },
    {
      key: 'refer',
      icon: <Refer {...props}></Refer>,
    },
  ] as const

export default icons
