import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { DefaultSeo, NextSeo } from 'next-seo'
import config from '@config/seo.json'
import { EUFY_COUNTRY_SUFFIX } from '@shopify/const'
import { formatInfoFromShop } from '@lib/utils/tools'

const Head = ({ pageProps }) => {
  const { locale, asPath, pathname } = useRouter()
  const { metafields, seo } = pageProps
  const { additionalTitle, additionalDesc } = formatInfoFromShop({
    seoInfo: metafields?.seoInfo,
    asPath,
    pathname,
  })

  const data = { ...config, ...seo }
  const description = metafields?.descriptionTag || data.description

  const SEO = useMemo(() => {
    data.description = `${description || ''}${
      description?.endsWith('!') || description?.endsWith('.') ? ' ' : '. '
    }${additionalDesc}`.substring(0, 500)

    data.title = `${metafields?.titleTag || data.title}${
      additionalTitle ? ' ' + additionalTitle : ''
    } - ${EUFY_COUNTRY_SUFFIX?.[locale] || ''}`

    data.noindex = !!metafields?.hideSearch || false
    return data
  }, [])

  return (
    <>
      {SEO.noindex === true ? <NextSeo {...SEO} /> : <DefaultSeo {...SEO} />}
      <NextHead>
        <title>{SEO?.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        <link rel="dns-prefetch" href="https://cdn.judge.me/" />
        <link rel="preconnect dns-prefetch" href="https://cdn.shopify.com" />
        <link rel="preconnect dns-prefetch" href="https://cdn.shopifycdn.net" />
        <link rel="apple-touch-icon" href="/favicon.png"></link>

        {locale === 'au' && (
          <meta
            name="google-site-verification"
            content="Ejfahf9B5Lhlnbcshb4H6BVX69O_NG_NU5ivkP6nkZw"
          />
        )}
        {SEO && SEO?.openGraph?.images?.[0]?.url && (
          <meta
            property="twitter:image"
            content={SEO?.openGraph?.images?.[0]?.url}
          />
        )}
      </NextHead>
      {SEO?.h1 && (
        <h1
          className="m-0 h-0 overflow-hidden p-0 opacity-0 outline-0"
          dangerouslySetInnerHTML={{ __html: SEO?.h1 }}
        />
      )}
    </>
  )
}

export default Head
