import dynamic from 'next/dynamic'

const Anker = dynamic(() => import('./Anker'))
const Power = dynamic(() => import('./Power'))
const AnkerSM = dynamic(() => import('./Anker-sm'))
const AnkerIn = dynamic(() => import('./AnkerIn'))
const Eufy = dynamic(() => import('./Eufy'))
const Soundcore = dynamic(() => import('./Soundcore'))
const Ankermake = dynamic(() => import('./AnkerMake'))
const Nebula = dynamic(() => import('./Nebula'))
const Ankerwork = dynamic(() => import('./AnkerWork'))
const Ganprime = dynamic(() => import('./Ganprime'))
const AnkerAu = dynamic(() => import('./AnkerAu'))

// 注意：Branch 的 key 要求全小写
const icons = (props: any) =>
  [
    {
      key: 'anker',
      icon: <Anker {...props}></Anker>,
    },
    {
      key: 'power',
      icon: <Power {...props}></Power>,
    },
    {
      key: 'anker-sm',
      icon: <AnkerSM {...props}></AnkerSM>,
    },
    {
      key: 'ankerin',
      icon: <AnkerIn {...props}></AnkerIn>,
    },
    {
      key: 'eufy',
      icon: <Eufy {...props}></Eufy>,
    },
    {
      key: 'soundcore',
      icon: <Soundcore {...props}></Soundcore>,
    },
    {
      key: 'ankermake',
      icon: <Ankermake {...props}></Ankermake>,
    },
    {
      key: 'nebula',
      icon: <Nebula {...props}></Nebula>,
    },
    {
      key: 'ankerwork',
      icon: <Ankerwork {...props}></Ankerwork>,
    },
    {
      key: 'ganprime',
      icon: <Ganprime {...props}></Ganprime>,
    },
    {
      key: 'ankerau',
      icon: <AnkerAu {...props}></AnkerAu>,
    },
  ] as const

export default icons
