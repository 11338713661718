import common from './common'
import series from './series'
import collections from './collections'
import brands from './brands'
import apps from './apps'
import payment from './payment'
import referrals from './referrals'
import credit from './credit'
import product from './product'

type MapKey<T extends (p: any) => readonly { key: string }[]> =
  ReturnType<T>[number]['key']

export type IconKeys =
  | MapKey<typeof common>
  | MapKey<typeof series>
  | MapKey<typeof collections>
  | MapKey<typeof brands>
  | MapKey<typeof apps>
  | MapKey<typeof payment>
  | MapKey<typeof referrals>
  | MapKey<typeof credit>
  | MapKey<typeof product>

interface IconProps {
  iconKey?: IconKeys
  onClick?: () => void
  [props: string]: any
}

const Icon = ({ iconKey, ...rest }: IconProps) => {
  let a11yProps: any = {}
  if ('onClick' in rest) {
    a11yProps.tabIndex = '0'
    a11yProps.role = 'button'
    a11yProps.onKeyPress = (e: any) => {
      if (e.key == 'Enter') {
        rest.onClick && rest.onClick()
      }
    }
  }
  const icons = [
    ...common({ ...a11yProps, ...rest }),
    ...series({ ...a11yProps, ...a11yProps, ...rest }),
    ...collections({ ...a11yProps, ...rest }),
    ...brands({ ...a11yProps, ...rest }),
    ...apps({ ...a11yProps, ...rest }),
    ...payment({ ...a11yProps, ...rest }),
    ...referrals({ ...a11yProps, ...rest }),
    ...credit({ ...a11yProps, ...rest }),
    ...product({ ...a11yProps, ...rest }),
  ]
  if (!iconKey) return null
  const iconObj = icons.find((icon) => icon.key === iconKey)
  if (iconObj) return iconObj.icon
  return null
}

export default Icon
