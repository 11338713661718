export const images = {
  domains: ['cdn.shopify.com', 'cdn.shopifycdn.net', 'www.facebook.com'],
  deviceSizes: [375, 767, 1023, 1279, 1439, 1539, 1919],
}

export const isShopifycdn = (src) => {
  const domains = images?.domains || []
  return domains.some((domain) => src.includes(domain))
}

export const imgSrcSetsize = ({ src, width, quality }) => {
  const size = parseInt(width * quality, 10)
  return src
    ?.split('/')
    ?.map((item) => {
      if (
        isShopifycdn(src) &&
        /\.(png|apng|jpg|jpeg|gif|svg|webp|tif)(\?.*)?/.test(item) &&
        /_[0-9]{2,4}x/.test(src) === false
      ) {
        const name = item.split('.')
        return name
          .map((v, i) => {
            if (i === name.length - 2) {
              return `${v}_${size < 3960 ? size : 3980}x`
            }
            return v
          })
          .join('.')
      }
      return item
    })
    .join('/')
}
