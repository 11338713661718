import { FC, useMemo } from 'react'
import cn from 'classnames'
import { Text, Picture, Link } from '@components/ui'

interface BlogBannerProps {
  metafields: Record<string, any>
}

const BlogBanner: FC<BlogBannerProps> = ({ metafields }) => {
  const theme = useMemo(() => metafields?.theme || 'white', [metafields?.theme])

  return (
    <Link href={metafields?.url || ''}>
      <div
        className={cn(
          'relative min-h-[70%] w-full overflow-hidden rounded-2xl bg-white',
          'min-md:min-h-[540px] min-md:w-[470px]'
        )}
      >
        <Picture
          // @ts-ignore
          className="min-h-[70%] object-contain min-md:min-h-[540px]"
          imgClassName="min-h-[70%] object-contain min-md:min-h-[540px]"
          source={`${metafields?.imagePc}, ${metafields?.imageMob} 768`}
        />
        <div
          className={cn(
            'absolute left-0 top-0 h-full w-full px-[13px] py-[48px] min-md:px-[48px]',
            'text-center leading-[1.2]',
            `text-${theme}`
          )}
        >
          <div
            className={cn('flex h-full flex-col justify-between', {
              ['mt-[60px] !justify-start min-md:mt-[120px]']:
                metafields?.position === 'top',
            })}
          >
            <div>
              <Text
                className="mb-2 text-[22px] font-bold min-md:text-[26px]"
                html={metafields?.title}
              />
              <Text
                className="text-[16px] font-semibold"
                html={metafields?.subtitle}
              />
            </div>
            <div>
              <Text
                className="mb-5 text-[16px] font-semibold"
                html={metafields?.desc}
              />
              <div className="flex justify-center gap-x-3">
                <Link
                  className={cn(
                    'rounded-full px-[16px] py-[14px] text-[16px] font-semibold',
                    'outline-none only-of-type:min-w-[200px]',
                    `border-${theme} text-${theme}`,
                    metafields.btnType === 'primary'
                      ? `bg-${theme} !text-[#005d8e]`
                      : ''
                  )}
                  href={metafields?.url || ''}
                >
                  {metafields?.btnText}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default BlogBanner
