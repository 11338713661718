import Mask from '../Mask'
import { useRouter } from 'next/router'
import { FC, useState, useMemo, useEffect, useCallback } from 'react'
import cn from 'classnames'
import { Text, Picture, Link } from '@components/ui'
import Icon from '@components/icons'
import dayjs from 'dayjs'

type CountdownTime = {
  days: string[]
  hours: string[]
  minutes: string[]
  seconds: string[]
}

interface Button {
  type?: string
  text?: string
  url?: string
}

interface Content {
  title?: string
  subtitle?: string
  desc?: string
  imgPc?: string
  imgMob?: string
  btns?: Button[]
}

interface siteModalData {
  index?: boolean
  storeFrontIndex?: boolean
  handles?: string[]
  pages_handles?: string[]
  collections_handles?: string[]
  blogs_handles?: string[]
  type?: number
  theme?: string
  deals?: string
  content?: Content
  countdownTime?: string
  daysText?: string
  hrsText?: string
  minsText?: string
  secText?: string
  timeItemBgColor?: string
  timeLabelColor?: string
}

interface SiteModalProps {
  metafields: Record<string, any>
}

const SiteModal: FC<SiteModalProps> = ({ metafields }) => {
  const { locale, asPath, pathname } = useRouter()
  const { siteModal }: { siteModal?: siteModalData } = metafields
  const content: Content | undefined = siteModal?.content
  const [show, setShow] = useState(false)

  const theme = useMemo(() => siteModal?.theme || 'white', [siteModal?.theme])

  const tag = useMemo(
    () => `eufy-${locale}-${siteModal?.deals}-sitePop`,
    [locale, siteModal?.deals]
  )

  const handleClose = useCallback(() => {
    setShow(false)
    if (localStorage) localStorage[tag] = 1
  }, [tag])

  useEffect(() => {
    let result = false

    if (
      // 系列页
      siteModal?.collections_handles &&
      asPath?.includes('collections')
    ) {
      for (const collection_handle of siteModal?.collections_handles) {
        const paths = asPath.toLowerCase().split('/')
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === collection_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!collection_handle && temp
        if (result) break
      }
    }
    // blog页
    if (siteModal?.blogs_handles && asPath?.includes('blogs')) {
      for (const blog_handle of siteModal.blogs_handles) {
        if (blog_handle === 'home' && pathname === '/blogs') {
          result = true
        } else {
          const paths = asPath.toLowerCase().split('/')
          let temp = false
          for (const pathItem of paths) {
            temp = pathItem.toLowerCase() === blog_handle.toLowerCase()
            if (temp) {
              break
            }
          }
          result = !!blog_handle && temp
        }
        if (result) break
      }
    }
    // page页
    if (siteModal?.pages_handles && pathname === '/[...pages]') {
      const paths = asPath.toLowerCase().split('/')
      for (const pages_handle of siteModal?.pages_handles) {
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === pages_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!pages_handle && temp
        if (result) break
      }
    }
    // 产品
    if (siteModal?.handles && asPath?.includes('products')) {
      const paths = asPath.toLowerCase().split('/')
      for (const handle of siteModal?.handles) {
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!handle && temp
        if (result) break
      }
    }

    // 首页默认展示
    if (pathname === '/') {
      result = true
    }

    // 全站设置关闭或者点击关闭后不展示
    if (siteModal?.storeFrontIndex === false || localStorage?.[tag]) {
      result = false
    }

    setShow(result)
  }, [siteModal, asPath, pathname, tag])

  return siteModal ? (
    <div
      className={cn(
        'pointer-events-none invisible opacity-0 transition-opacity',
        'fixed left-0 top-0 z-[141] h-full w-full',
        'flex items-center justify-center px-4',
        {
          '!pointer-events-auto !visible !opacity-100': show,
        }
      )}
    >
      <Mask />
      <div
        className={cn(
          'relative min-h-[70%] w-full rounded-[16px] bg-white',
          'min-md:min-h-[620px] min-md:w-[540px]'
        )}
      >
        <Picture
          // @ts-ignore
          className="object-cover"
          imgClassName="object-cover"
          source={`${content?.imgPc}, ${content?.imgMob} 768`}
        />
        <div
          data-tag="Auto-0000074"
          className={cn(
            'absolute right-2 top-2 z-[1] h-8 w-8 cursor-pointer p-[5px]',
            `text-${theme} fill-${theme}`
          )}
          onClick={handleClose}
        >
          <Icon iconKey="close" />
        </div>

        <div
          className={cn(
            'absolute left-0 top-0 h-full w-full px-[13px] py-[48px] min-md:px-[48px]',
            'text-center leading-[1.2]',
            `text-${theme}`
          )}
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <Text
                className="mb-2 text-[22px] font-bold min-md:text-[26px]"
                html={content?.title}
              ></Text>
              <Text
                className="mb-4 text-[16px] font-semibold opacity-[.75] min-md:mb-3"
                html={content?.subtitle}
              ></Text>
              <CountdownTimeComp data={metafields?.siteModal} />
            </div>
            <div>
              <Text
                className="mb-5 text-[16px] font-semibold"
                html={content?.desc}
              ></Text>
              <div className="flex justify-center gap-x-3">
                {content?.btns?.map((btn, idx) => (
                  <Link
                    className={cn(
                      'rounded-full border-[2px] border-solid px-[16px] py-[14px] text-[16px] font-semibold',
                      'only-of-type:min-w-[200px]',
                      `border-${theme} text-${theme}`,
                      btn.type === 'primary' ? `bg-[#042637] !text-[#FFF]` : ''
                    )}
                    key={idx}
                    href={btn?.url || ''}
                    onClick={() => {
                      if (localStorage) localStorage[tag] = 1
                    }}
                  >
                    {btn?.text}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const CountdownTimeComp = ({ data }: { data: siteModalData }) => {
  const [countdownTime, setCountdownTime] = useState<CountdownTime>(
    {} as CountdownTime
  )
  useEffect(() => {
    if (data?.countdownTime) {
      const calculateTime = () => {
        const now = dayjs()
        const target = dayjs(data?.countdownTime)
        const difference = target.diff(now)
        if (difference > 0) {
          const days = target.diff(now, 'day')
          const hours = target.diff(now, 'hour') % 24
          const minutes = target.diff(now, 'minute') % 60
          const seconds = target.diff(now, 'second') % 60
          return {
            days: days.toString().padStart(2, '0').split(''),
            hours: hours.toString().padStart(2, '0').split(''),
            minutes: minutes.toString().padStart(2, '0').split(''),
            seconds: seconds.toString().padStart(2, '0').split(''),
          }
        }
      }
      setInterval(() => {
        setCountdownTime((calculateTime() || {}) as CountdownTime)
      }, 1000)
    }
  }, [data?.countdownTime])
  return (
    !!Reflect.ownKeys(countdownTime).length && (
      <div className="relative flex items-center justify-center gap-2 min-md:gap-3">
        {data?.daysText && (
          <TimeItem
            timeItemBgColor={data?.timeItemBgColor}
            timeLabelColor={data?.timeLabelColor}
            label={data?.daysText}
            time={countdownTime?.days?.join('')}
          />
        )}
        <Text html=":" className="text-[24px] text-[#005D8E]" />
        <TimeItem
          timeItemBgColor={data?.timeItemBgColor}
          timeLabelColor={data?.timeLabelColor}
          label={data?.hrsText}
          time={countdownTime?.hours?.join('')}
        />
        <Text html=":" className="text-[24px] text-[#005D8E]" />
        <TimeItem
          timeItemBgColor={data?.timeItemBgColor}
          timeLabelColor={data?.timeLabelColor}
          label={data?.minsText}
          time={countdownTime?.minutes?.join('')}
        />
        <Text html=":" className="text-[24px] text-[#005D8E]" />
        <TimeItem
          timeItemBgColor={data?.timeItemBgColor}
          timeLabelColor={data?.timeLabelColor}
          label={data?.secText}
          time={countdownTime?.seconds?.join('')}
        />
      </div>
    )
  )
}

const TimeItem = ({
  time,
  label,
  timeItemBgColor,
  timeLabelColor,
}: {
  time?: string
  label?: string
  timeItemBgColor?: string
  timeLabelColor?: string
}) => {
  return (
    <div
      className="flex h-[40px] w-[46px] flex-col items-center justify-center rounded-[8px] bg-[#414141] text-[12px] text-white"
      style={{ backgroundColor: timeItemBgColor }}
    >
      <div className="text-[16px] font-semibold">{time}</div>
      <div
        className="font-medium opacity-[.75]"
        style={{ color: timeLabelColor }}
      >
        {label}
      </div>
    </div>
  )
}

export default SiteModal
