import { useEffect } from 'react'

// start_ai_generated
const isFunction = (value: unknown): value is (...args: any) => any =>
  typeof value === 'function'

const useMount = (fn: () => void) => {
  if (!isFunction(fn)) {
    console.error(
      `useMount: parameter \`fn\` expected to be a function, but got "${typeof fn}".`
    )
  }

  useEffect(() => {
    fn?.()
  }, [])
}
// end_ai_generated

export default useMount
