import { APP_NAME } from '@shopify/const'

export const RegistrationsStatus = {
  MAIN: 'main',
  QUICK_LOGIN: 'quickLogin',
  REMAINDER: 'remainder',
  PASSWORD_LOGIN: 'passwordLogin',
  COUPONS: 'coupons',
  ACTIVATE: 'activate',
  AUTH_CODE: 'authCode',
  AUTH_CODE_SUCCESS: 'authCodeSuccess',
  /**
   * 没有back 按钮的激活提示
   */
  ACTIVATE_NO_BACK_BTN: 'activateNoBackBtn',
  SUBSCRIBE_SMS: 'subscribeSMS',
}

export const getAppName = (locale) => {
  return APP_NAME[locale || '']
}
