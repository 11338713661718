import { FC } from 'react'
import cn from 'classnames'
import s from './LoadingDots.module.css'
interface LoadingProps {
  className?: string
  dotClassName?: any
}

const LoadingDots: FC<LoadingProps> = (props) => {
  const { className, dotClassName } = props
  return (
    <span className={cn(s.root, className)}>
      <span className={cn(s.dot, dotClassName)} key={`dot_1`} />
      <span className={cn(s.dot, dotClassName)} key={`dot_2`} />
      <span className={cn(s.dot, dotClassName)} key={`dot_3`} />
    </span>
  )
}

export default LoadingDots
