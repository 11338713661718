import cartFragment from '../fragments/cart'

export const addToCartMutation = /* GraphQL */ `
  mutation addToCart($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`

export const createCartMutation = /* GraphQL */ `
  mutation createCart(
    $lines: [CartLineInput!]
    $attributes: [AttributeInput!]
    $buyerIdentity: CartBuyerIdentityInput
    $discountCodes: [String!]
  ) {
    cartCreate(
      input: {
        lines: $lines
        attributes: $attributes
        buyerIdentity: $buyerIdentity
        discountCodes: $discountCodes
      }
    ) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`

export const editCartItemsMutation = /* GraphQL */ `
  mutation editCartItems($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`

export const removeFromCartMutation = /* GraphQL */ `
  mutation removeFromCart($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`

export const discountCodesUpdateCartMutation = /* GraphQL */ `
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`

export const cartAttributesUpdateMutation = /* GraphQL */ `
  mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`

export const getCartAttributesQuery = /* GraphQL */ `
  query getCartAttributes($cartId: ID!) {
    cart(id: $cartId) {
      attributes {
        key
        value
      }
    }
  }
`

export const cartBuyerIdentityUpdate =
  /* GraphQL */
  `
    mutation cartBuyerIdentityUpdate(
      $buyerIdentity: CartBuyerIdentityInput!
      $cartId: ID!
    ) {
      cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
        cart {
          ...cart
        }
        userErrors {
          field
          message
        }
      }
    }
    ${cartFragment}
  `

export const cartSelectedDeliveryOptionsUpdateMutation = /* GraphQL */ `
  mutation cartSelectedDeliveryOptionsUpdate(
    $cartId: ID!
    $selectedDeliveryOptions: [CartSelectedDeliveryOptionInput!]!
  ) {
    cartSelectedDeliveryOptionsUpdate(
      cartId: $cartId
      selectedDeliveryOptions: $selectedDeliveryOptions
    ) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`
