/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useMemo, useRef } from 'react'
import { useCommerce } from '@commerce/index'
import {
  getAttributesFromLineItems,
  cartAttributesUpdateFn,
} from 'lib/shopify/api/cart/use-update-attributes'
import { getOrderCustomAttribute } from 'lib/utils/useCustomAttributes'
import {
  useAddItem,
  useRemoveItem,
  useCart,
  useCartAttributesUpdate,
} from 'lib/shopify/api/cart'
import usePrice from '@shopify/product/use-price'
import useProduct from '@shopify/product/use-product'
import useCustomer from '@shopify/customer/use-customer'
import { useCodeApply, useRemoveCode } from 'lib/shopify/api/cart'
import { Button, Text, Link, LoadingDots } from '@components/ui'
import { useUI } from '@components/ui/context'
import { Cross } from '@components/icons/next'
import Icon from '@components/icons'
import SidebarLayout from '@components/common/SidebarLayout'
import { commerceEnabled, getUniqueListBy } from '@lib/utils/tools'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { btoaID, getGaId } from '@lib/utils/tools'
import getProductPersonalizeBySkus from '@lib/apis/get-product-personalize-by-skus'
import { BRAND } from '@shopify/const'
import Slider from 'react-slick'

import s from './CartSidebarView.module.css'
import CodeInput from '../CodeInput'
import { beginCheckout, viewItems } from './trace'
import { ProductIconBar } from '@components/product'
import { getGtmGroup } from '@components/helper'
import { useInView } from 'react-intersection-observer'
import isMobile from 'ismobilejs'
import CartItem from '../CartItem'
import CartRecommendItem from '../CartItem/CartRecommendItem'
import { formatCurrency } from '@lib/utils'
import useGradientGiftsCampaign from '../useGradientGiftsCampaign'
import useBreakpointDiscount from '../useBreakpointDiscount'
import InstalmentsModal from '@components/product/InstalmentsModal'
import Affirm from '@components/product/Affirm'
import Paypal from '@components/product/Paypal'
import Klarna from '@components/product/ProductInfoV2/Klarna'
import CartPlus from '@components/icons/member/cartPlus.svg'
import Plus from '@components/icons/member/plus.svg'
import { useProfile } from '@components/common/ProfileContext'
import ProductPrice from '@components/product/ProductPriceV2'
import Cookies from 'js-cookie'
import { getAppName } from '@shopify/utils'
import { RegistrationsStatus } from '@components/common/Registrations/const'
import getProductPersonalizeByUser from '@lib/apis/get-product-personalize-by-user'
import useProducts from '@shopify/product/use-products'
import { features } from 'commerce.config'

const CartSidebarView = ({ metafields }) => {
  const error = null
  const success = null
  const {
    shoppingInfo,
    infos = {},
    cartRecommends,
    quantityBreakDiscount: quantityBreakDiscountData,
    criteoSettings,
    shopCommon,
    thirdparty,
    summerCampaignDiscount,
    gradient_gifts,
    preRender,
    shopPreRender,
    memberSetting,
  } = metafields
  const {
    plus_monthly_product,
    plus_annual_product,
    cart: memberCart,
  } = memberSetting || {}
  const { shop } = useCommerce()
  const KEY = `${shop?.primaryDomain?.host}_discounts_code`

  const { data: customer } = useCustomer()
  const { locale, query, asPath } = useRouter()
  const {
    closeSidebar,
    setSidebarView,
    displaySidebar: show,
    setRegistrationsPopup,
    setRegistrationsOptions,
    registrationsOptions,
    setRegistrationsModelStatus,
    setCartSidebarMutate,
  } = useUI()
  const { cartCookie } = useCommerce()
  const { data, isLoading, isEmpty, mutate } = useCart()

  const codeApply = useCodeApply({ shoppingInfo })
  const removeCode = useRemoveCode()
  const addItem = useAddItem()
  const removeItem = useRemoveItem()
  const cartAttributesUpdate = useCartAttributesUpdate()
  const [codeError, setCodeError] = useState(null)
  const [personalizeProducts, setPersonalizeProducts] = useState([]) //自动推荐算法
  const [recommendsProducts, setRecommendsProducts] = useState([]) //运营配置
  const [recommendsAddedSku, setRecommendsAddedSkuAdded] = useState() // 推荐商品中加入购物车
  const [codeApplying, setCodeApplying] = useState(false)
  const [codeRemoving, setCodeRemoving] = useState(false)
  const [addMonthlyPlusLoading, setAddMonthlyPlusLoading] = useState(false)

  useEffect(() => {
    setCartSidebarMutate(mutate)
  }, [mutate])

  const appliedCode = useMemo(() => {
    const apply = data?.discountCodes
      ?.filter((item) => item?.applicable)
      ?.map((item) => item?.code)
    return apply?.length > 0 ? apply : ['']
  }, [data?.discountCodes])

  const deliveryAmount = useMemo(() => {
    return parseFloat(data?.deliveryAmount) || 0
  }, [data?.deliveryAmount])

  // 优化后的代码，减少了重复的 parseFloat 调用，提高了代码的可读性和性能
  const discountAmount = useMemo(() => {
    const parse = (value) => parseFloat(value) || 0
    const lineItemsDiscount =
      data?.lineItems?.reduce(
        (sum, item) =>
          sum + (parse(item?.subtotalAmount) - parse(item?.totalAmount)),
        0
      ) || 0
    const totalDiscount =
      parse(data?.subtotalPrice) - parse(data?.totalPrice) || 0
    return lineItemsDiscount + totalDiscount + deliveryAmount
  }, [data?.lineItems, data?.subtotalPrice, data?.totalPrice, deliveryAmount])

  const { price: discount } = usePrice(
    discountAmount && {
      amount: discountAmount * -1,
      currencyCode: data.currency.code,
    }
  )

  const hasDiscount = useMemo(
    () => data?.discountCodes?.some((code) => code.applicable),
    [data?.discountCodes]
  )

  const applyCode = async (code) => {
    setCodeApplying(true)
    setCodeError(null)
    try {
      await codeApply({ discountCode: code })
      // setAppliedCode(code)
      setCodeApplying(false)
      localStorage?.setItem(KEY, code)
    } catch (error) {
      console.log('apply code error:', error)
      setCodeApplying(false)
      setCodeError(error.message)
    }
  }

  const codeRemove = async (successCbk, code) => {
    try {
      setCodeRemoving(true)
      await removeCode({ discountCode: code })
      successCbk && successCbk()
      setCodeRemoving(false)
    } catch (error) {
      setCodeRemoving(false)
    }
  }

  const handleCodeRemove = (code) =>
    codeRemove(() => {
      // 如果去除了所有的普通 code，并且有符合满减条件，默认应用上满减
      const isLast = appliedCode?.length <= 1
      if (isNormalCode(code) && quantityBreakCode && isLast) {
        applyCode(quantityBreakCode)
      }
      if (isQuantityBreakCode(code)) {
        setQuantityBreakCodeRemoved(true)
      }
    }, code)

  const {
    involvedLines: gradientGiftsLines,
    reorder,
    nextSummerLevel,
    involvedSubTotal,
  } = useGradientGiftsCampaign({
    campaign: gradient_gifts,
    _giveaway: '_giveaway_gradient_gifts',
  })

  const {
    involvedLines: summerCampaignLines,
    reorder: summerReorder,
    nextSummerLevel: nextLevel,
    involvedSubTotal: nextInvolvedSubTotal,
  } = useGradientGiftsCampaign({
    campaign: summerCampaignDiscount,
    _giveaway: '_giveaway_summerCampaignDiscount',
  })

  const {
    showLearnMore,
    quantityBreakCode,
    setQuantityBreakCodeRemoved,
    isQuantityBreakCode,
    isNormalCode,
  } = useBreakpointDiscount({
    quantityBreakDiscountData,
    disabled: gradientGiftsLines?.length > 0 || summerCampaignLines?.length > 0,
    hasDiscount,
    applyCode,
    codeRemove,
    setCodeError,
    appliedCode: appliedCode[0],
  })

  const subTotalAmount = useMemo(() => {
    let amount = 0
    data?.lineItems?.map((line) => {
      amount += +line?.subtotalAmount
    })
    return Math.floor(amount * 100) / 100
  }, [data])

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(subTotalAmount),
      currencyCode: data.currency.code,
    }
  )

  const { price: total } = usePrice(
    data && {
      amount: Number(data?.totalPrice || data?.lineItemsSubtotalPrice),
      currencyCode: data.currency.code,
    }
  )

  const bundleItem = useMemo(() => {
    const hasBundle = []
    const actionItem = []
    data?.lineItems?.forEach((item) => {
      const sku = item?.variant?.sku
      const index = hasBundle.indexOf(sku)
      if (sku && index > -1) {
        hasBundle.push('bundle-' + sku)
        actionItem.push(data?.lineItems[index])
      } else {
        hasBundle.push(sku)
        actionItem.push(null)
      }
    })
    return actionItem
  }, [data?.lineItems])

  const CUSTOMCHECKOUT = useMemo(
    () => commerceEnabled('customCheckout', locale),
    [locale]
  )

  const handleClose = () => {
    closeSidebar()
    pageGTMEvent({
      event: 'close_side_cart',
      page_group: document.location.href,
    })
  }

  const goToCheckout = () => setSidebarView('CHECKOUT_VIEW')

  // 初始化推荐商品
  const recommends = useMemo(() => {
    const skus = data?.lineItems?.map((item) => item?.variant?.sku) || []
    const results = []
    cartRecommends?.items?.forEach(([a, b]) => {
      if (skus.some((s) => a.includes(s)) && !results.includes(b)) {
        results.push(b)
      } else if (skus.some((s) => b.includes(s)) && !results.includes(a)) {
        results.push(a)
      }
    })
    return results
      .map((v) => v.split(','))
      .filter((res) => res && skus.every((sku) => sku !== res[1]))
  }, [])

  // 获取推荐产品相关数据
  const { data: recommendsProductsResult } = useProduct({
    handles: cartRecommends?.show
      ? recommends.map(([handle, sku]) => handle).join(',')
      : '',
  })

  // 生成最终需要的数据
  useEffect(() => {
    // 筛掉没有找到数据的推荐商品
    const availableRecommends = recommends?.filter(([handle, sku]) =>
      recommendsProductsResult?.products?.find(
        (product) => product?.handle?.toLowerCase() === handle?.toLowerCase()
      )
    )
    const products = recommendsProductsResult?.products
      ?.map((item, index) => {
        const [handle, sku, discountPrice] = availableRecommends[index]
        const variant = item.variants.find((v) => v.sku === sku)
        let mainProduct
        cartRecommends?.items?.forEach(([a, b]) => {
          if (a.includes(sku)) {
            mainProduct = b
          } else if (b.includes(sku)) {
            mainProduct = a
          }
        })
        return {
          ...item,
          variant,
          __discountPrice: discountPrice,
          quantity: 1,
          mainProduct,
        }
      })
      ?.filter(({ variant }) => variant?.availableForSale)
    setRecommendsProducts(products)
  }, [])

  const getBundleSize = (name) => {
    const bundle_size = data?.lineItems?.filter((item) => {
      return item?.customAttributes?.some((obj) => obj.value === name)
    })
    return bundle_size ? bundle_size?.length : 1
  }

  // const fetchPersonalizeProducts = useCallback(
  //   async (productPersonalizeVersion) => {
  //     const skus = data?.lineItems?.map((item) => item?.variant?.sku) || []
  //     if (skus.length === 0) return
  //     const clientId = getGaId()

  //     // 通过链接参数做 AB
  //     let queries = parse(window.location.search)
  //     const result = await getProductPersonalizeBySkus({
  //       locale,
  //       skus,
  //       clientId,
  //       limit: 5,
  //       recom_type: 'shopping',
  //       version: productPersonalizeVersion,
  //       plan: queries?.cartPlan?.toLowerCase() || '',
  //     })
  //     if (result?.data) {
  //       // 所有商品推荐集合
  //       const products = Object.keys(result?.data).reduce((pre, cur) => {
  //         return pre && result?.data?.[cur]
  //           ? [...pre, ...result?.data?.[cur]]
  //           : []
  //       }, [])

  //       const finalProducts = getUniqueListBy(products, 'sku') //商品去重
  //         .filter((p) => !skus.includes(p.sku)) //去除跟购物车内相同商品
  //         .sort((a, b) => b.ranking - a.ranking) //根据推荐图排名
  //         .slice(0, 5) //取购物车推荐排名前 5
  //         .map(normalizeProduct)

  //       setPersonalizeProducts(finalProducts)
  //     }
  //   },
  //   [locale, data?.lineItems]
  // )

  // const fetchPersonalizeProducts = useCallback()
  const locales = features ? Object.keys(features) : ['us']
  const [questQuery, setQuestQuery] = useState('')
  const [handles, setHandles] = useState([])
  const [skus, setSkus] = useState([])
  const [sortArr, setSortArr] = useState([])
  const FetchData = async (query, locale, locales) => {
    const { products: result } = useProducts(query, locale, locales)

    const midResResult = result?.filter((item) => handles.includes(item.handle))
    let resResult = []
    for (let i = 0; i < handles.length; i++) {
      midResResult.forEach((item) => {
        if (item?.handle == handles[i] && item?.availableForSale) {
          resResult.push(item)
        }
      })
    }

    for (let i = 0; i < sortArr.length; i++) {
      for (let j = 0; j < resResult.length; j++) {
        if (resResult[j]?.handle == sortArr[i].handle) {
          resResult.splice(sortArr[i].sort - 1, 0, resResult.splice(j, 1)[0])
        }
      }
    }

    resResult = resResult.slice(0, 5)
    let resArr = []
    resResult.map((item) => {
      const variant = item?.variants.find((v) => skus.includes(v.sku))

      resArr.push({
        quantity: 1,
        mainProduct: variant.sku,
        name: item.title,
        id: item?.id,
        path: `/${item.handle}`,
        variant: {
          id: variant?.id,
          name: item.title,
          sku: variant.sku,
          price: variant.price,
          availableForSale: item?.availableForSale,
          image: {
            url: variant?.image?.url,
            altText: variant?.image?.altText,
          },
          options: variant?.options,
          title: variant?.name,
        },
      })
    })
    if (
      resResult.length &&
      resResult &&
      personalizeProducts &&
      personalizeProducts.toString() != resResult.toString()
    ) {
      setPersonalizeProducts((str) => {
        if (str && str.toString() == resArr.toString()) {
          return str
        } else {
          return resArr
        }
      })
    }
  }
  FetchData(questQuery, locale, locales)

  function deduplicateProducts(products) {
    const productMap = new Map()

    products.forEach((product) => {
      const existingProduct = productMap.get(product.sku)

      // 如果当前sku不存在，或者新对象有sort属性，或者现有对象没有sort属性，则添加/更新
      if (!existingProduct || product.sort || !existingProduct.sort) {
        productMap.set(product.sku, product)
      }
    })

    // 从Map对象获取去重后的产品数组
    return Array.from(productMap.values())
  }
  const fetchPersonalizeProducts = useCallback(
    async (addsku) => {
      if (recommendsAddedSku) {
        setRecommendsProducts((v) => {
          if (v) {
            return v.filter(
              (p) =>
                p.variant?.sku !== recommendsAddedSku &&
                p.variant?.sku !== addsku
            )
          } else {
            return v
          }
        })
        setPersonalizeProducts(
          personalizeProducts?.filter(
            (p) =>
              p.variant?.sku !== recommendsAddedSku && p.variant?.sku !== addsku
          )
        )
      }

      const skus = [
        ...new Set(
          [
            ...(data?.lineItems?.flatMap((item) => item?.variant?.sku) ?? []),
            recommendsAddedSku,
            addsku,
          ].filter(Boolean)
        ),
      ]

      const res = await getProductPersonalizeBySkus({
        locale,
        skus,
        limit: 5,
        version: 'v2',
        recom_type: 'shopping',
      })
      if (res?.data) {
        const compressProducts = Array.isArray(res?.data)
          ? res?.data
          : Object.keys(res?.data).reduce((pre, cur) => {
              return pre && res?.data?.[cur]
                ? [...pre, ...res?.data?.[cur]]
                : []
            }, [])

        const minRes = deduplicateProducts(compressProducts, 'sku') //商品去重
          .filter((p) => !skus.includes(p.sku)) //去除跟购物车内相同商品
          .sort((a, b) => b.ranking - a.ranking) //根据推荐图排名

        minRes.forEach((item) => {
          if (item?.sort) {
            setSortArr((prev) => {
              const newArr = [...prev, { sort: item.sort, handle: item.handle }]
              return [
                ...new Map(newArr.map((item) => [item.handle, item])).values(),
              ].sort((a, b) => a.sort - b.sort)
            })
          }
        })
        const finalProducts = minRes
        let query = ''
        const queryHandles = finalProducts.map((item) => item?.handle)
        const querySkus = finalProducts.map((item) => item?.sku)
        setHandles(queryHandles)
        setSkus(querySkus)
        finalProducts.map((item) => {
          query += `${item?.handle}&${item?.sku} OR `
        })
        query = query.substring(0, query.length - 3)
        setQuestQuery(query)
      }
    },
    [locale, data, recommendsAddedSku]
  )
  const [cartItems, setCartItems] = useState([])
  useEffect(() => {
    setCartItems(data?.lineItems)
    if (
      data?.lineItems &&
      data?.lineItems?.toString() != cartItems?.toString()
    ) {
      fetchPersonalizeProducts()
    }
  }, [data?.lineItems])
  useEffect(() => {
    let relationVersion = ''
    const handlePersonalizeRelationTestEvent = (event) => {
      if (event?.detail?.version && show && !isLoading && !isEmpty) {
        relationVersion = event?.detail?.version
        relationVersion && fetchPersonalizeProducts(event?.detail?.version)
      }
    }
    if (show) {
      // 给window对象添加个性化关系测试事件的监听器
      window.addEventListener(
        'personalizeRelationTestEvent',
        handlePersonalizeRelationTestEvent,
        { once: true }
      )
      window.VWO = window.VWO || []
      VWO.event =
        VWO.event ||
        function () {
          VWO.push(['event'].concat([].slice.call(arguments)))
        }
      VWO.event('cartDisplay')
      // criteoTrace({ data, locale, criteoSettings, customer, thirdparty })
      if (!isLoading && !isEmpty) {
        // 设置一个定时器，如果在10秒内没有接收到版本信息，则默认请求v1版本
        // let timer = setTimeout(() => {
        //   if (!relationVersion) {
        //     fetchPersonalizeProducts('v1')
        //     clearTimeout(timer)
        //   }
        // }, 5 * 1000)
      }
    }
    return () => {
      window.removeEventListener(
        'personalizeRelationTestEvent',
        handlePersonalizeRelationTestEvent
      )
    }
  }, [show, isLoading, isEmpty, recommendsAddedSku])

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const { profile } = useProfile()

  const { data: monthlyProduct } = useProduct({
    handles: plus_monthly_product?.handle,
  })

  const monthlyPlus = useMemo(() => {
    return data?.lineItems.some(
      (item) => plus_monthly_product?.sku === item.variant?.sku
    )
  }, [data?.lineItems, plus_monthly_product?.sku])

  const annualPlus = useMemo(() => {
    return data?.lineItems.some(
      (item) => plus_annual_product?.sku === item.variant?.sku
    )
  }, [data?.lineItems, plus_annual_product?.sku])

  const without_plus_sku = useMemo(() => {
    return !(monthlyPlus || annualPlus)
  }, [annualPlus, monthlyPlus])

  const addMonthlyPlus = async () => {
    if (addMonthlyPlusLoading) return
    let variantId
    let sellingPlanId
    for (const item of monthlyProduct?.products || []) {
      const variant = item.variants.find(
        (v) => v.sku === plus_monthly_product?.sku
      )
      sellingPlanId = variant?.sellingPlanAllocations?.[0]?.sellingPlan?.id
      if (variant) {
        variantId = variant.id
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'add_to_cart',
          event_parameters: {
            page_group: 'Product Detail Page_' + plus_monthly_product?.sku, //如果是活动页，改为"Activity Page_" + $Pagehandle；如果是首页，传“Home Page"
            position: 'cart add', //位置标记。如果是listing的底部悬浮按钮，传Product Detail Page_Sticky;如果是listing的首屏按钮，传Product Detail Page_Checkout；如果是侧边栏，传Cart Pop Up；如果是活动页/首页，则传该模块的小标题（该模块有ref参数则传ref参数）
            info: !!profile ? 'member' : 'non-member', //用户登录状态member、non-member（GTM上线login_status事件参数后删掉或可覆盖）
            value: variant?.price, //折后总价
            currency: monthlyProduct?.products?.[0]?.price?.currencyCode,
            items: [
              {
                item_id: plus_monthly_product?.sku, //产品SKU
                item_name: variant?.name,
                item_brand: BRAND,
                item_variant: variantId,
                price: variant?.price, //原价
                quantity: variant?.quantityAvailable,
              },
            ],
          },
        })
        break
      }
    }

    if (variantId) {
      setAddMonthlyPlusLoading(true)
      await addItem({
        variantId,
        quantity: 1,
        sellingPlanId,
      })
      setAddMonthlyPlusLoading(false)
    }
  }

  useEffect(() => {
    const removePlusProduct = (productType) => {
      const product = data.lineItems?.find(
        (item) =>
          item.handle === productType?.handle &&
          item.variant?.sku === productType?.sku
      )
      if (product) {
        removeItem({ id: product.id })
      }
    }

    if (profile?.isMonthlyPlus && data) {
      removePlusProduct(plus_monthly_product)
    }
    if (profile?.isAnnualPlus && data) {
      removePlusProduct(plus_annual_product)
    }
  }, [
    data,
    plus_annual_product,
    plus_monthly_product,
    profile?.isAnnualPlus,
    profile?.isMonthlyPlus,
    removeItem,
  ])

  const userType = useMemo(() => {
    let userInfo = Cookies.get('userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      let arr = typeof userInfo?.id == 'string' && userInfo?.id.split('/')
      userInfo.setId = arr[arr.length - 1]
    }
    const customerInfo = userInfo || customer
    if (!customerInfo) {
      return 'new_user_unlogin'
    }
    if (customer) {
      const { orders = {} } = customer
      if (orders?.edges?.length === 1) {
        return 'old_user_orders_once'
      } else if (orders?.edges?.length > 1) {
        return 'old_user_orders_twice'
      }
    }
    return 'new_user_login'
  }, [customer])

  const handleCartCheckout = useCallback(async () => {
    beginCheckout({
      data,
      group: getGtmGroup({ query, asPath }),
    })
    // 在结算前更新cart的attrbutes,确保订单关联到正确的custom attributes
    const inputLineItem = data.lineItems.map((item) => ({
      merchandiseId: item.variantId,
      quantity: item.quantity,
      attributes: item.customAttributes,
    }))
    const orderCustomAttribute = getOrderCustomAttribute({ locale })
    const LineItemsAttributes = getAttributesFromLineItems(inputLineItem)
    let is_presale
    const dataCustomAttributes = data.customAttributes.map((item) => {
      if (item.key === '_checkout_delivery_custom') {
        const value = JSON.parse(item.value)
        const is_prime = profile?.isPlus

        is_presale = data.lineItems.some((item) =>
          item.customAttributes.some((item) => item.key === '_is_presale')
        )
        value.is_presale = is_presale
        value.is_prime = is_prime
        return {
          key: item.key,
          value: JSON.stringify(value),
        }
        // 结算时更新最新的会员等级状态
      } else if (item.key === '_member_type') {
        return {
          key: item.key,
          value: profile?.memberType || '0',
        }
      } else {
        return item
      }
    })
    const token = Cookies.get(`${app}-info`)
    const weight = data.lineItems?.reduce((prev, cur) => {
      return (prev += (cur.variant?.weight || 0) * cur.quantity)
    }, 0)
    const attributes = getUniqueListBy(
      [
        ...orderCustomAttribute,
        ...LineItemsAttributes,
        ...dataCustomAttributes,
        {
          key: '_token',
          value: token,
        },
        {
          key: '_user_type',
          value: userType,
        },
        {
          key: '_weight',
          value: weight.toFixed(2),
        },
      ],
      'key'
    ).filter((item) => item.value)
    if (is_presale) {
      attributes.push({
        key: '_is_presale',
        value: 'true',
      })
    }
    await cartAttributesUpdateFn(locale, attributes, data.id)
    if (!without_plus_sku && !profile?.activated) {
      if (!profile) {
        closeSidebar()
        setRegistrationsPopup(true)
        setRegistrationsOptions({
          ...registrationsOptions,
          loginRedirectUrl: data.url,
          memberRegistration: true,
          monthlyPlus,
          annualPlus,
          cart: data,
          checkout: true,
        })
        setRegistrationsModelStatus(RegistrationsStatus.QUICK_LOGIN)
      } else {
        closeSidebar()
        setRegistrationsPopup(true)
        setRegistrationsModelStatus(RegistrationsStatus.AUTH_CODE)
        setRegistrationsOptions({
          ...registrationsOptions,
          sendAuthCodeManually: true,
          memberRegistration: true,
          loginRedirectUrl: data.url,
          monthlyPlus,
          annualPlus,
          cart: data,
        })
      }
      return
    }
    location.href = data.url
  }, [
    annualPlus,
    app,
    asPath,
    closeSidebar,
    data,
    locale,
    monthlyPlus,
    profile,
    query,
    registrationsOptions,
    setRegistrationsModelStatus,
    setRegistrationsOptions,
    setRegistrationsPopup,
    userType,
    without_plus_sku,
  ])

  /* useEffect(() => {
    // 关闭时重新获取
    if (!show) {
      fetchPersonalizeProducts()
    }
  }, [show]) */

  // 更新购物车中的会员等级，刷新用户身份标识
  // useEffect(() => {
  //   const memberTypeAttr = data?.customAttributes.find(
  //     (item) => item.key === '_member_type'
  //   )
  //   // const memberType = profile?.memberType || '0'
  //   if (memberTypeAttr?.value !== profile?.memberType) {
  //     const attributes = data?.customAttributes.reduce((acc, item) => {
  //       if (item.key === '_member_type') {
  //         acc.push({ key: '_member_type', value: profile?.memberType || '0' })
  //       } else {
  //         acc.push(item)
  //       }
  //       return acc
  //     }, [])
  //     if (attributes?.length > 0) {
  //       console.log('is update member')
  //       cartAttributesUpdateFn(locale, attributes, data.id)
  //     }
  //   }
  // }, [data, profile, locale])

  if (!shoppingInfo) return null
  return (
    <SidebarLayout
      className={cn(s.root, {
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
      headerTitle={
        isEmpty
          ? `<b>${shoppingInfo?.myCart || 'My Cart'}</b> (0)`
          : `<b>${
              shoppingInfo?.myCart || 'My Cart'
            }</b> <span tag='Auto-00000412'>${data?.lineItems?.length}</spna> ${
              shoppingInfo?.items || 'items'
            }`
      }
    >
      {isLoading || isEmpty ? (
        <div className="flex flex-1 flex-col items-center px-4">
          <span className="flex h-16 w-16 items-center justify-center p-12">
            <Icon iconKey="cart" className="absolute h-8 w-8 text-black" />
          </span>
          <h2 className="text-center text-2xl font-bold">
            {shoppingInfo?.emptyCart}
          </h2>
          <Link
            href={shoppingInfo.continueLink || `/${locale}/collections/all`}
            className="px-10 pt-2 text-center hover:underline"
          >
            {shoppingInfo?.continue}
          </Link>
        </div>
      ) : error ? (
        <div className="flex flex-1 flex-col items-center justify-center px-4">
          <span className="flex h-16 w-16 items-center justify-center rounded-full border border-white">
            <Cross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-center text-xl font-light">
            {shoppingInfo?.errorCart}
          </h2>
        </div>
      ) : (
        <div className={s.cartContainer}>
          {/* 原购物车 => PC 左 */}
          <div className={s.left}>
            {/* 免邮 only us */}
            {without_plus_sku && memberCart && !profile?.isPlus ? (
              <div
                className="flex items-center justify-between gap-[16px] px-6 py-3 leading-[1.2]"
                style={{
                  background:
                    'linear-gradient(92deg, #FFF3E4 0%, #FCF4E8 100%)',
                }}
              >
                <div className="">
                  <div className="flex text-[20px] font-bold text-[#462706]">
                    <CartPlus className="mr-[6px] inline-block h-6 w-6 shrink-0" />
                    <Text html={memberCart.title} />
                  </div>
                  <p
                    className="mt-1 text-[14px] font-medium text-[rgba(70,39,6,0.75)]"
                    dangerouslySetInnerHTML={{ __html: memberCart.subtitle }}
                  ></p>
                </div>
                <button
                  className="flex h-[40px] min-w-[95px] max-w-[120px] shrink-0 items-center justify-center rounded-full bg-[#9d6929] px-4 text-[20px] font-semibold text-white"
                  onClick={addMonthlyPlus}
                >
                  {addMonthlyPlusLoading ? (
                    <LoadingDots />
                  ) : (
                    <div className="flex items-center justify-center">
                      <Plus className="h-6 w-6" /> {shopCommon?.addbtn || 'Add'}
                    </div>
                  )}
                </button>
              </div>
            ) : (
              <>
                {memberCart ? (
                  <div
                    className="flex items-center justify-center px-2 py-[18px] text-[16px] font-semibold text-[#462706]"
                    style={{
                      background:
                        'linear-gradient(92deg, #FFF3E4 0%, #FCF4E8 100%)',
                    }}
                  >
                    <Text
                      html={memberCart.tip.replace(
                        '${num}',
                        Math.ceil(
                          Number(
                            data?.totalPrice || data?.lineItemsSubtotalPrice
                          ) * (memberCart.credits_times || 1.5)
                        )
                      )}
                    />
                  </div>
                ) : (
                  <div className={`px-2 py-1 ${s.shoppingInfo}`}>
                    <Text html={shoppingInfo?.main} />
                  </div>
                )}
              </>
            )}

            {/* 主内容部分 */}
            <div className={s.cartItems}>
              {/* 商品 List */}
              <ul className="border-accent-2 bg-white px-4 py-4 sm:py-2">
                {data?.lineItems
                  ?.sort(reorder)
                  ?.sort(summerReorder)
                  ?.map((item, index) => (
                    <CartItem
                      modal={item?.product?.MF_cartModal?.value || 'default'}
                      form="CartSidebarView"
                      key={`${item.id}${item.variant.price}${index}`}
                      item={item}
                      index={index}
                      bundleItem={bundleItem[item.id]}
                      position={index + 1}
                      currencyCode={data?.currency?.code}
                      shopCommon={shopCommon}
                      allSku={data?.lineItems?.map(
                        (item) => item?.variant?.sku
                      )}
                      // removeItemCbk={handleRemoveItem}
                      quantityBreakDescription={
                        showLearnMore && (
                          <>
                            <DiscountDescription
                              data={quantityBreakDiscountData}
                            />
                            {/* 当前有应用上的 code 不且是满减的 code 且已满足满减情况提示用户 */}
                            {isNormalCode(appliedCode) && quantityBreakCode && (
                              <Text
                                html={quantityBreakDiscountData?.unAvailable}
                                className="text-xs text-red md:text-[3vw]"
                                variant="paragraph"
                              />
                            )}
                          </>
                        )
                      }
                    />
                  ))}
              </ul>
              {shoppingInfo?.showDiscounts && (
                <CodeInput
                  shoppingInfo={shoppingInfo}
                  codeError={codeError}
                  onSubmit={applyCode}
                  setCodeError={setCodeError}
                  codeApplying={codeApplying}
                  codeRemoving={codeRemoving}
                  handleCodeRemove={handleCodeRemove}
                  discountCodes={data?.discountCodes}
                  s={s}
                />
              )}
              {/* 邮费税率 */}
              <div
                className={cn(
                  'flex flex-col border-b border-gray-200 bg-white px-4 py-2',
                  {
                    ['border-t']: !(
                      !hasDiscount && shoppingInfo?.showDiscounts
                    ),
                  }
                )}
              >
                {shoppingInfo?.shipping && (
                  <div className="flex justify-between py-1">
                    <span>{shoppingInfo?.shipping}</span>
                    <span className="font-bold tracking-wide">
                      {deliveryAmount !== 0 ? (
                        <>
                          <ProductPrice value={deliveryAmount} />
                        </>
                      ) : (
                        <>{shoppingInfo?.free}</>
                      )}
                    </span>
                  </div>
                )}
                {shoppingInfo?.taxes && (
                  <div className="flex justify-between py-1">
                    <span>{shoppingInfo?.taxes}</span>
                    <span>{shoppingInfo?.taxesInfo}</span>
                  </div>
                )}
              </div>
              {/* 产品推荐 => 移动端 下方 */}
              <MobileRecommends
                personalizeProducts={personalizeProducts}
                recommendsProducts={recommendsProducts}
                cartRecommends={cartRecommends}
                shoppingInfo={shoppingInfo}
                getBundleSize={getBundleSize}
                setRecommendsAddedSkuAdded={setRecommendsAddedSkuAdded}
                data={data}
                shopCommon={shopCommon}
              />
              {/* 卖点部分 */}
              <div className={cn(s.reasons, BRAND)}>
                <Text
                  className={cn(s.title, BRAND)}
                  html={infos?.store_iconBar?.title}
                  variant="paragraph"
                />
                <ProductIconBar
                  iconBars={infos?.store_iconBar?.data}
                  direction={infos?.store_iconBar?.direction}
                  s={
                    BRAND === 'ankermake'
                      ? {
                          iconBars:
                            'relative flex justify-between items-stretch content-between py-3 flex-col',
                        }
                      : {
                          iconBars:
                            'grid grid-cols-2 gap-x-9 gap-y-2 bg-[#F3F4F6]',
                          iconBar: 'flex items-start',
                          barText: 'text-[13px] leading-[18px]',
                          icon: 'w-6 h-6 mr-3 flex-shrink-0',
                        }
                  }
                  interaction={BRAND != 'ankermake' && false}
                />
              </div>
            </div>
            {/* 底部悬浮条 */}
            <div className={s.stickyBar}>
              {discountAmount > 0 && (
                <ul className="border-b border-accent-2 py-2">
                  {/* subtotal */}
                  <li className="flex justify-between py-1">
                    <span>{shoppingInfo?.subtotal}</span>
                    <span>{subTotal}</span>
                  </li>
                  {/* discount */}
                  <li className="py-1">
                    {/* <div className="mb-1">{shoppingInfo?.discount}</div> */}
                    <div className="flex items-center justify-between gap-2">
                      <div className="flex-1">
                        {/* 折扣标签 */}
                        {/* {appliedCode && (
                          <div className={s.codeIconBox}>
                            <TagIcon className="mr-1 inline-block h-4 w-4 align-text-bottom"></TagIcon>
                            <Text
                              variant="inline"
                              html={appliedCode.join('<br>')}
                              className="align-middle "
                            />
                            <button
                              aria-label="remove code"
                              variant="plain"
                              className="ml-2"
                              onClick={handleCodeRemove}
                              disabled={codeRemoving}
                              loading={codeRemoving.toString()}
                            >
                              {codeRemoving ? (
                                <Spin className="h-4 w-4" />
                              ) : (
                                <XMarkIcon className="inline-block h-4 w-4 align-top"></XMarkIcon>
                              )}
                            </button>
                          </div>
                        )} */}
                        {shoppingInfo?.discount}
                      </div>
                      <div>{discount}</div>
                    </div>
                  </li>
                </ul>
              )}
              {/* total */}
              <div className="mb-2 flex justify-between pt-4 font-bold">
                <span>{shoppingInfo?.total}</span>
                <span className="text-xl font-semibold" tag="Auto-00000413">
                  {formatCurrency(shop.moneyFormat, total)}
                </span>
              </div>
              {/* checkout 按钮 */}
              <div tag="Auto-00000414">
                {CUSTOMCHECKOUT ? (
                  <Button Component="a" width="100%" onClick={goToCheckout}>
                    Proceed to Checkout (
                    {formatCurrency(shop.moneyFormat, total)}
                  </Button>
                ) : (
                  <Button
                    // href="/api/checkout"
                    Component="a"
                    width="100%"
                    onClick={handleCartCheckout}
                  >
                    {shoppingInfo?.checkoutBtn}
                  </Button>
                )}
              </div>
              {Number(data?.totalPrice) > 100 && (
                <InstalmentsModal metafields={metafields}>
                  {thirdparty?.paypal?.src && (
                    <div className="border-b border-[#D9D9D9] px-4 py-4 last:border-b-0">
                      <Paypal price={data?.totalPrice} />
                    </div>
                  )}
                  {metafields.klarnaSettings?.clientId &&
                    (preRender?.hideKlarna !== undefined
                      ? !preRender?.hideKlarna
                      : !shopPreRender?.hideKlarna) && (
                      <div className="border-b border-[#D9D9D9] px-4 py-4 last:border-b-0">
                        <Klarna
                          price={data?.totalPrice}
                          settings={metafields?.klarnaSettings}
                        />
                      </div>
                    )}
                  {thirdparty?.affirm_key &&
                    (preRender?.hideAffirm !== undefined
                      ? !preRender?.hideAffirm
                      : !shopPreRender?.hideAffirm) && (
                      <div className="border-b border-[#D9D9D9] px-4 py-4 last:border-b-0">
                        <Affirm price={data?.totalPrice} />
                      </div>
                    )}
                </InstalmentsModal>
              )}
            </div>
          </div>
          {/* 产品推荐 => PC端 右侧 */}
          <DesktopRecommends
            personalizeProducts={personalizeProducts}
            recommendsProducts={recommendsProducts}
            cartRecommends={cartRecommends}
            shoppingInfo={shoppingInfo}
            getBundleSize={getBundleSize}
            setRecommendsAddedSkuAdded={setRecommendsAddedSkuAdded}
            data={data}
            shopCommon={shopCommon}
            show={show}
          />
        </div>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView

const normalizeProduct = (product) => () => {
  return {
    quantity: 1,
    mainProduct: product.sku,
    name: product.product_title,
    id: product?.product_shopify_id,
    path: `/${product.handle}`,
    variant: {
      id: btoaID(product.variant_shopify_id),
      name: product.product_title,
      sku: product.sku,
      price: product.variant_price,
      availableForSale: true,
      image: {
        url: product.variant_image,
        altText: product.product_title,
      },
      options: product.selected_options,
      title: product.variant_title,
    },
  }
}

const DiscountDescription = ({ data }) => {
  const { locale } = useRouter()
  return (
    <div className="flex items-center justify-between pt-2 text-xs md:text-[3vw]">
      <div className="flex items-center">
        {data?.tag && (
          <Text
            html={data?.tag}
            className="mr-1 flex-shrink-0 rounded border border-anker-color px-1 text-anker-color"
            variant="paragraph"
          />
        )}
        <Text
          html={data?.description}
          className="h-fit font-light"
          variant="paragraph"
        />
      </div>
      <Link
        className="font-light text-anker-color"
        href={`/${data?.link?.to}`}
        onClick={() =>
          pageGTMEvent({
            event: 'uaEvent',
            eventCategory: 'Cart Pop Up',
            eventAction: 'special_offer',
            eventLabel: 'learn_more',
          })
        }
      >
        {data?.link?.text}
      </Link>
    </div>
  )
}

const MobileRecommends = ({
  personalizeProducts,
  recommendsProducts,
  cartRecommends,
  shoppingInfo,
  getBundleSize,
  setRecommendsAddedSkuAdded,
  data,
  shopCommon,
}) => {
  const trace = useRef(false)

  const getInViewItems = () => {
    let items = []
    document
      .querySelectorAll('#PersonalizeRecommendsProducts .slick-active')
      ?.forEach((item) => {
        const index = parseInt(item.getAttribute('data-index') || '1')
        const product = personalizeProducts?.[index]
        items.push({
          item_id: product?.variant?.sku, //读取被推荐产品的SKU
          item_name: product?.name,
          item_variant: '',
          price: product?.variant?.price, //读取被推荐产品展示的折扣价
          index: index, //读取被推荐的位置，如1,2,3
        })
      })
    return items
  }

  const skus = data?.lineItems?.reduce(
    (prev, cur) => prev + cur?.variant?.sku + ',',
    ''
  )

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '18px',
    infinite: false,
    afterChange: () => {
      viewItems({ items: getInViewItems(), skus })
    },
  }
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    if (isMobile(window.navigator).phone) {
      setMobile(true)
    }
  }, [])

  const { ref, inView } = useInView({
    threshold: 0,
  })

  // 使用useEffect来处理在移动端视图内的项目
  useEffect(() => {
    if (inView && mobile && !trace.current) {
      const items = getInViewItems()
      if (items.length) {
        viewItems({ items, skus })
        trace.current = true
      }
    }
    // 移除trace.current依赖，避免不必要的重渲染
  }, [inView, mobile])

  return (
    <div
      ref={ref}
      className="only-in-mobile"
      id="PersonalizeRecommendsProducts"
    >
      <style jsx global>
        {`
          .${s.cartItems} .slick-slide {
            margin-left: 2px;
            margin-right: 2px;
          }
          .${s.cartItems} .slick-track {
            display: flex;
            align-items: stretch;
          }
          .${s.cartItems} .slick-slide {
            position: relative;
            height: auto;
          }
          .${s.cartItems} .slick-slide > div,
          .${s.cartItems} .slick-slide > div > div {
            height: 100%;
          }
          .${s.cartItems} .slick-slide > div > div {
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
          }
        `}
      </style>
      {/* 算法推荐商品 */}
      {personalizeProducts?.length > 0 && (
        <div className={s.recommendsContainer}>
          <Text
            className={s.recommendsTitle}
            html={shoppingInfo?.recommendsTitle}
            variant="paragraph"
          />
          <Slider {...settings}>
            {personalizeProducts.map((item, index) => {
              return (
                <CartRecommendItem
                  modal="recommends"
                  form="CartSidebarView"
                  key={`${item.id}${index}`}
                  item={item}
                  discountPrice={item?.__discountPrice || ''}
                  position={index + 1}
                  mainProduct={item?.mainProduct}
                  currencyCode={data.currency.code}
                  shopCommon={shopCommon}
                  allSku={data?.lineItems?.map((item) => item?.variant?.sku)}
                  getBundleSize={getBundleSize}
                  onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                />
              )
            })}
          </Slider>
        </div>
      )}
      {/* 运营配置的推荐商品 */}
      {cartRecommends?.show && (
        <div id="RecommendsProducts">
          {recommendsProducts?.length > 0 && (
            <div className={s.recommendsContainer}>
              <Text
                className={s.recommendsTitle}
                html={shoppingInfo?.recommendsTitle}
                variant="paragraph"
              />
              <Slider {...settings}>
                {recommendsProducts.map((item, index) => {
                  return (
                    <CartRecommendItem
                      modal="recommends"
                      form="CartSidebarView"
                      key={`recommendsProducts${item.id}${index}`}
                      item={item}
                      discountPrice={item.__discountPrice || ''}
                      position={index + 1}
                      mainProduct={item.mainProduct}
                      currencyCode={data.currency.code}
                      shopCommon={shopCommon}
                      allSku={data?.lineItems?.map(
                        (item) => item?.variant?.sku
                      )}
                      getBundleSize={getBundleSize}
                      onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                    />
                  )
                })}
              </Slider>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const DesktopRecommends = ({
  personalizeProducts,
  recommendsProducts,
  cartRecommends,
  shoppingInfo,
  getBundleSize,
  setRecommendsAddedSkuAdded,
  data,
  shopCommon,
  show,
}) => {
  const trace = useRef(false)
  const hasRecommends =
    personalizeProducts?.length ||
    (cartRecommends?.show && recommendsProducts?.length)

  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    if (isMobile(window.navigator).phone) {
      setMobile(true)
    }
  }, [])

  // PC 展开都能看到，全部一起触发 view 事件
  // 使用useEffect来处理当组件显示，非移动设备，有个性化产品推荐且未追踪时发送追踪信息
  useEffect(() => {
    if (
      show &&
      !mobile &&
      personalizeProducts &&
      personalizeProducts.length > 0 &&
      !trace.current
    ) {
      const items = personalizeProducts.map((product, index) => ({
        item_id: product.variant && product.variant.sku, // 读取被推荐产品的SKU
        item_name: product.name,
        item_variant: '',
        price: product.variant && product.variant.price, // 读取被推荐产品展示的折扣价
        index: index, // 读取被推荐的位置，如1,2,3
      }))

      const skus = data.lineItems
        ? data.lineItems.reduce(
            (prev, cur) => `${prev}${cur.variant && cur.variant.sku},`,
            ''
          )
        : ''

      viewItems({ items, skus })
      trace.current = true
    }
  }, [show, mobile, personalizeProducts])

  return (
    <div
      id="PersonalizeRecommendsProducts"
      className={cn('only-in-pc', s.right, hasRecommends ? 'w-[375px]' : 'w-0')}
    >
      {/* 算法推荐商品 */}
      {personalizeProducts?.length > 0 && (
        <div className="px-5 pb-8 md:pb-4">
          <Text
            className={s.recommendsTitle}
            html={shoppingInfo?.recommendsTitle}
            variant="paragraph"
          />
          <ul>
            {personalizeProducts?.map((item, index) => {
              return (
                <CartRecommendItem
                  modal="recommends"
                  form="CartSidebarView"
                  key={`${item.id}${index}`}
                  item={item}
                  index={index}
                  discountPrice={item?.__discountPrice || ''}
                  position={index + 1}
                  mainProduct={item?.mainProduct}
                  currencyCode={data.currency.code}
                  shopCommon={shopCommon}
                  allSku={data?.lineItems?.map((item) => item?.variant?.sku)}
                  getBundleSize={getBundleSize}
                  onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                />
              )
            })}
          </ul>
        </div>
      )}

      {/* 运营配置的推荐商品 */}
      {cartRecommends?.show && (
        <div id="RecommendsProducts">
          {recommendsProducts?.length > 0 && (
            <div className="px-5 pb-8 md:pb-4">
              <Text
                className={s.recommendsTitle}
                html={shoppingInfo?.recommendsTitle}
                variant="paragraph"
              />
              <ul className="">
                {recommendsProducts.map((item, index) => {
                  return (
                    <CartRecommendItem
                      modal="recommends"
                      form="CartSidebarView"
                      key={`recommendsProducts-${item.id}${index}`}
                      item={item}
                      discountPrice={item.__discountPrice || ''}
                      position={index + 1}
                      mainProduct={item.mainProduct}
                      currencyCode={data.currency.code}
                      shopCommon={shopCommon}
                      allSku={data?.lineItems?.map(
                        (item) => item?.variant?.sku
                      )}
                      getBundleSize={getBundleSize}
                      onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                      fetchPersonalizeProducts={fetchPersonalizeProducts}
                    />
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
