import { Picture, Text } from '@components/ui'
import styles from './index.module.css'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { validate } from 'email-validator'
import { useCallback, useMemo, useState } from 'react'
import fetcher from '@lib/fetcher'
import { useRouter } from 'next/router'
import { getAdCookie } from '@lib/utils/cookies'
import {
  I18N_STORE_DOMAIN,
  MULPASS_URL,
  SHORT_LOCALE_MAP,
} from '@shopify/const'
import registrationsTrack from '../../RegistrationsTrack'
// start_ai_generated
import Cookies from 'js-cookie'
// end_ai_generated
import { getAppName } from '../../const'
import { useUI } from '@components/ui/context'
import { getUniqueListBy, tsHtml2LocaleLink } from '@lib/utils/tools'
import { features } from 'commerce.config'
import { cartAttributesUpdateFn } from 'lib/shopify/api/cart/use-update-attributes'
import { useCart } from 'lib/shopify/api/cart'
import { useGlobal } from '@components/common/GlobalContext'

const defaultSubscribeCountry = ['us', 'ca']

const RegistrationsMain = ({
  data,
  registrationsFillEmail,
  gs,
  onQuickLogin,
  onRegistrationsSuccess,
  onRegistrationsError,
  onSendVerifyEmailSuccess,
  onRegistrationsVerify,
  onPasswordLogin,
}) => {
  const {
    registrationsDealsType,
    registrationsOptions = {},
    registrationsSignSuccessCallback,
    setActiveToken,
  } = useUI()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [canAutoSignup, setCanAutoSignup] = useState(true)
  const [serviceError, setServiceError] = useState(false)
  const [formData, setFormData] = useState(true)
  const [sendLoading, setSendLoading] = useState(false)
  const s = { ...styles, ...gs }
  const { locale } = useRouter()
  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    defaultValues: { registrations: { email: registrationsFillEmail } },
  })
  const { memberRegisterCard } = useGlobal()

  const { data: cart } = useCart()
  const getCustomAttribute = useCallback(
    (attributes) => {
      return getUniqueListBy(
        [...(cart?.customAttributes || []), ...(attributes || [])],
        'key'
      ).filter((item) => item.value)
    },
    [cart?.customAttributes]
  )

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const handleSendVerifyEmail = useCallback(async () => {
    if (sendLoading) return
    setSendLoading(true)
    const res = await fetcher({
      action: 'user',
      needRecaptcha: true,
      locale,
      url: '/api/multipass/account/users/send_validation_email',
      method: 'POST',
      body: {
        email: formData?.registrations,
        app,
      },
    }).catch((e) => {
      console.log(e, 'e')
    })
    if (res.status > 100 && res.status < 300) {
      onSendVerifyEmailSuccess && onSendVerifyEmailSuccess()
    }
    setSendLoading(false)
  }, [
    sendLoading,
    locale,
    formData?.registrations,
    app,
    onSendVerifyEmailSuccess,
  ])

  const handleFocus = useCallback(() => {
    // start_ai_generated
    clearErrors('registrations[email]')
    setServiceError(false)
    // end_ai_generated
  }, [clearErrors])

  const getDomain = useCallback(() => {
    const hostUrl = window.location.host.split('.')
    return location.href.includes('localhost')
      ? 'localhost'
      : `.${hostUrl[1]}.${hostUrl[2]}`
  }, [])

  const setCookie = useCallback(
    ({ auth_token }) => {
      const domain = getDomain()
      Cookies.set(`${app}-token`, auth_token, {
        domain,
        expires: 30,
      })
      Cookies.set(`${app}-info`, auth_token, {
        domain,
        expires: 30,
      })
      Cookies.set(`alpcEU`, features[locale]?.isEuLocale ? '1' : '0', {
        domain,
        expires: 30,
      })
    },
    [app, getDomain, locale]
  )

  const isDefaultSubscribeCountry = useMemo(
    () => defaultSubscribeCountry.includes(locale),
    [locale]
  )

  const country_code = useMemo(() => {
    const shortLocale = SHORT_LOCALE_MAP[locale || ''] || locale
    return (shortLocale || data?.globalCountry?.code).toUpperCase()
  }, [locale, data?.globalCountry?.code])

  const onSubmit = useCallback(
    async (formData) => {
      setSubmitLoading(true)
      setFormData(formData)
      const { register_source } = getAdCookie()
      const { email, subscribe } = formData?.registrations
      const fullUrl = window.location.href
      const url = new URL(fullUrl)
      // 提取基础 URL
      const baseUrl = `${url.origin}${url.pathname}`
      const res = await fetcher({
        action: 'login',
        needRecaptcha: true,
        locale,
        url: '/api/multipass/passport-api/auto-signup',
        method: 'POST',
        body: {
          email,
          app,
          country_code,
          register_source,
          data: {
            redirect_url: `${baseUrl}?ref=edm_member_activation`,
          },
        },
        headers: {
          'User-Agent': navigator?.userAgent,
        },
      }).catch((e) => {
        console.log(e, 'e')
        setSubmitLoading(false)
      })
      const domain = getDomain()

      if (res.status > 100 && res.status < 300) {
        const { auth_token, canAutoSignup, multipassinfo, active_token } =
          res?.data?.data
        const ref = 'thankyoupage_plusmember'
        const lastUrl = location.href.includes('?')
          ? `${location.href}&ref=${ref}`
          : `${location.href}?ref=${ref}`
        registrationsTrack.registrationsTrack({
          action: 'success',
          subscribe: subscribe ? 1 : 0,
          email,
          deals_type: data?.dealsType,
        })
        if (registrationsOptions?.cart?.id) {
          cartAttributesUpdateFn(
            locale,
            getCustomAttribute([
              {
                key: '_login_user',
                value: '1',
              },
              {
                key: '_last_url',
                value: lastUrl,
              },
              {
                key: '_token',
                value: auth_token,
              },
            ]),
            registrationsOptions?.cart?.id
          )
        }
        const b_auth_token = btoa(auth_token)
        setCookie({ auth_token, multipassinfo })
        setCanAutoSignup(canAutoSignup)
        if (canAutoSignup) {
          setActiveToken(active_token)
          localStorage?.setItem('b_auth_token', b_auth_token)
          Cookies.set(`multipassinfo`, multipassinfo, {
            domain,
            expires: 30,
          })
          const passportLoginUrl = `${MULPASS_URL}/login?app=${app}&t=${b_auth_token}&redirect=${encodeURIComponent(
            location.href
          )}`
          Cookies.set('passport-login-url', passportLoginUrl, {
            domain,
            expires: 30,
          })
          if (registrationsSignSuccessCallback) {
            registrationsSignSuccessCallback()
          } else {
            // 注册成功
            let url = '/api/multipass/rainbowbridge/activities'
            const result = await fetcher({
              action: 'activity',
              needRecaptcha: true,
              locale,
              url,
              method: 'POST',
              headers: {
                'X-Application-Name': `${app}-shopify`,
                'x-application-token': res?.data?.data?.auth_token,
              },
              body: {
                email,
                register_source,
                shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
                single_brand_subscribe: true,
                genre: registrationsDealsType || data?.dealsType,
                promise_subscribe: subscribe,
              },
            }).finally(() => setSubmitLoading(false))
            if (!result.errors) {
              // 订阅成功
              onRegistrationsSuccess &&
                onRegistrationsSuccess(result, email, subscribe)
            }
          }
        } else {
          onRegistrationsVerify && onRegistrationsVerify(email)
          setSubmitLoading(false)
        }
      } else {
        setSubmitLoading(false)
        setServiceError(true)
        // 注册失败
        onRegistrationsError && onRegistrationsError(res, email)
        if (res.status === 422) {
          setServiceError(false)
        }
      }
    },
    [
      app,
      country_code,
      data?.dealsType,
      getCustomAttribute,
      getDomain,
      locale,
      onRegistrationsError,
      onRegistrationsSuccess,
      onRegistrationsVerify,
      registrationsDealsType,
      registrationsOptions?.cart?.id,
      registrationsSignSuccessCallback,
      setActiveToken,
      setCookie,
    ]
  )

  return (
    <registrations-main
      class={cn(s.registrations_main, { [s.disabled]: submitLoading })}
    >
      <div className={s.registrations_model_head}>
        <Picture
          source={`${
            registrationsOptions?.memberRegistration
              ? memberRegisterCard?.bannerBgPc
              : data?.img_banner_pc
          }, ${
            registrationsOptions?.memberRegistration
              ? memberRegisterCard?.bannerBgMob
              : data?.img_banner_mob
          } 1024`}
          className={cn(
            'aspect-h-[256] aspect-w-[358] w-full min-l:aspect-h-[328] min-l:aspect-w-[540]',
            {
              'bg-[#052030]': registrationsOptions?.memberRegistration,
            }
          )}
        />
        <Text
          ag="Auto-00000611"
          html={
            registrationsOptions?.memberRegistration
              ? memberRegisterCard?.title
              : data?.title
          }
          className={cn(s.registrations_title, s.rsm_title)}
        />
      </div>
      <div className={s.registrations_model_body}>
        <form
          method="post"
          className={s.rsm_form}
          acceptCharset="UTF-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            className={cn(s.rsm_input, s.registrations_input, {
              [s.input_error]: errors?.registrations?.email,
            })}
            tag="Auto-00000612"
            name="registrations[email]"
            onFocus={handleFocus}
            maxLength="200"
            aria-label={data?.placeholder}
            placeholder={data?.placeholder}
            {...register('registrations[email]', {
              required: data?.info_fill_email,
              validate: (value) => validate(value) || data?.info_err_email,
            })}
          />
          <p className={s.form_error}>
            {errors?.registrations?.email?.message}
          </p>
          <p className={cn(s.rsm_policy_box, s.checkout_box)}>
            <input
              className={s.rsm_checkout}
              tag="Auto-00000615"
              name="registrations[policy]"
              type="checkbox"
              defaultChecked={isDefaultSubscribeCountry}
              aria-label="policy-checkbox"
              {...register('registrations[policy]', {
                required: data?.info_select_policy,
              })}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: tsHtml2LocaleLink({
                  html: data?.policy_text,
                  locale,
                }),
              }}
              className={s.rsm_checkout_text}
            ></span>
          </p>
          <p className={s.form_error}>
            {errors?.registrations?.policy?.message}
          </p>
          <p className={cn(s.rsm_subscribe_box, s.checkout_box)}>
            <input
              tag="Auto-00000616"
              className={s.rsm_checkout}
              name="registrations[subscribe]"
              type="checkbox"
              defaultChecked={isDefaultSubscribeCountry}
              aria-label="subscribe-checkbox"
              {...register('registrations[subscribe]')}
            />
            <span className={s.rsm_checkout_text}>{data.subscribe_text}</span>
          </p>
          <p className={s.form_error}>
            {errors?.registrations?.subscribe?.info_select_policy}
          </p>
          {!canAutoSignup && (
            <p
              className={cn(
                s.form_error,
                'rounded-[2px] bg-[rgba(248,77,79,0.08)] px-[12px] py-[10px]'
              )}
            >
              <span>{data?.verifyError}</span>&nbsp;
              <a
                onClick={handleSendVerifyEmail}
                className=" cursor-pointer text-[#005D8E] underline"
              >
                {data?.sendEmailText}
              </a>
            </p>
          )}
          {serviceError && (
            <p
              className={cn(
                s.form_error,
                'rounded-[2px] bg-[rgba(248,77,79,0.08)] px-[12px] py-[10px]'
              )}
            >
              <span>{data?.serviceError}</span>
            </p>
          )}
          {submitLoading ? (
            <button
              className={cn(
                s.rsm_submit,
                s.registrations_btn,
                s.flex_center,
                s.loading_btn
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          ) : (
            <button
              type="submit"
              tag="Auto-00000617"
              className={cn(s.rsm_submit, s.registrations_btn, s.flex_center)}
            >
              {data?.register_text}
            </button>
          )}
        </form>
        <p className={s.rsm_login}>
          {data.login_text}
          <a
            tag="Auto-00000618"
            onClick={() => {
              if (registrationsOptions?.memberRegistration && onPasswordLogin) {
                onPasswordLogin(getValues('registrations[email]'))
                return
              }
              onQuickLogin && onQuickLogin(getValues('registrations[email]'))
            }}
          >
            {data?.login_now_text}
          </a>
        </p>
        {registrationsOptions?.memberRegistration && data?.member_tip && (
          <p
            className="mt-[10px] text-center text-[14px] font-medium leading-[1.2] text-[#606060] opacity-75"
            dangerouslySetInnerHTML={{ __html: data?.member_tip }}
          ></p>
        )}
      </div>
    </registrations-main>
  )
}

export default RegistrationsMain
