import cn from 'classnames'
import { useEffect, useState } from 'react'
import { Text } from '@components/ui'
import { XMarkIcon } from '@heroicons/react/24/solid'

import s from './index.module.css'

const InstalmentsModal = ({ className, metafields, children }) => {
  const { thirdparty, shopCommon } = metafields || {}
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (modalOpen && window.affirm) {
      affirm.ui.ready(function () {
        affirm.ui.refresh()
      })
    }
  }, [modalOpen])

  return (
    thirdparty?.instalments && (
      <>
        <div className={cn('px-1 pb-0 pt-2', className)}>
          <div
            className="flex w-full items-center justify-between gap-1 rounded"
            onClick={() => setModalOpen(true)}
          >
            <Text
              className="mt-1 flex items-center gap-2 text-left text-xs font-medium text-[#999]"
              html={thirdparty?.instalments?.modal_btn}
            />
            <button className="flex-none cursor-pointer text-xs font-medium text-[#005d8e] underline">
              {shopCommon?.learn_more}
            </button>
          </div>
        </div>
        {modalOpen && (
          <div className={cn('mask_modal', s.modal)}>
            <div className="modal_box">
              <button
                className="absolute right-3 top-3 cursor-pointer"
                onClick={() => setModalOpen(false)}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
              <div className="px-8 pb-6 pt-8">
                <Text
                  className="mb-5 mt-2 text-center text-xl font-semibold md:text-base"
                  html={thirdparty?.instalments?.modal_title}
                />
                <div className="grid border border-[#D9D9D9]">{children}</div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  )
}

export default InstalmentsModal
