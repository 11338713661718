import { languageCode } from '@commerce/product/use-price'
import { useRouter } from 'next/router'

/**
 * Renders the Paypal component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.price - The price of the product.
 * @param {string} [props.className=''] - The additional CSS class name for the component.
 * @returns {JSX.Element} The rendered Paypal component.
 */
const Paypal = ({ price, className = '' }) => {
  const { locale } = useRouter()
  return (
    <div
      className={className}
      data-pp-message
      data-pp-pagetype="product-details"
      data-pp-amount={price}
      data-pp-buyercountry={languageCode(locale)}
    ></div>
  )
}

export default Paypal
