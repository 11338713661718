import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import { CommerceError } from '@commerce/utils/errors'
import { useCommerce } from '@commerce/index'
import useAddItem, { UseAddItem } from '@commerce/cart/use-add-item'
import type { AddItemHook } from '../types/cart'
import useCart from './use-cart'
import { useUI } from '@components/ui/context'

import {
  checkoutLineItemAddMutation,
  getCheckoutId,
  checkoutToCart,
  checkoutCreate,
} from '../utils'
import { Mutation, MutationCheckoutLineItemsAddArgs } from '../schema'

export default useAddItem as UseAddItem<typeof handler>

export const handler: MutationHook<AddItemHook> = {
  fetchOptions: {
    query: checkoutLineItemAddMutation,
  },
  async fetcher({ input: item, options, fetch }) {
    let lineItems: any = []
    const addLine = (line: any) => {
      if (
        line.quantity &&
        (!Number.isInteger(line.quantity) || line.quantity! < 1)
      ) {
        throw new CommerceError({
          message: 'The item quantity has to be a valid integer greater than 0',
        })
      }

      lineItems.push({
        variantId: line.variantId,
        quantity: line.quantity ?? 1,
        customAttributes: line?.customAttributes,
      })
    }
    if (Array.isArray(item)) {
      item.map((v, i) => {
        addLine(v)
      })
    } else {
      addLine(item)
    }

    const { locale } = options

    let checkoutId = getCheckoutId(locale)

    if (!checkoutId) {
      return checkoutToCart(
        await checkoutCreate(fetch, lineItems, locale),
        locale
      )
    } else {
      const result = await fetch<Mutation, MutationCheckoutLineItemsAddArgs>({
        ...options,
        variables: {
          checkoutId,
          lineItems,
        },
      })

      return checkoutToCart(result.checkoutLineItemsAdd, locale)
    }
  },
  useHook: (({ fetch }: any) =>
    () => {
      const { mutate, data } = useCart()
      const { setTipModal } = useUI()
      const { shop } = useCommerce()
      const orderLimits = shop?.orderLimits || {}

      return useCallback(
        async function addItem(input: any) {
          // 新增是否超库存判断
          let result = true
          let inputs: any[] = Array.isArray(input) ? input : [input]
          const errors: Array<string> = []
          //shopify会对同一个商品不同活动时做拆分，这里需要汇总同一个sku的库存数
          const lines: Record<string, any> = {}
          data?.lineItems?.forEach((item) => {
            lines[item.variantId] = lines[item.variantId] || {
              quantity: 0,
              variant: {
                currentlyNotInStock: item?.variant?.currentlyNotInStock,
                quantityAvailable: item?.variant?.quantityAvailable,
                sku: item?.variant?.sku,
              },
              options: item.options,
              name: item?.name,
            }
            lines[item.variantId].quantity =
              lines[item.variantId].quantity + item.quantity
          })
          inputs?.forEach((input) => {
            const item = lines[input.variantId]
            if (item) {
              const variant = item?.variant
              //库存,如果允许超卖(允许超卖的商品也有9999限制,9999数可配置)
              const available = variant?.currentlyNotInStock
                ? orderLimits?.quantityMax || 9999
                : variant.quantityAvailable
              //根据配置判断最大加购
              const orderLimit =
                orderLimits?.limits?.[variant?.sku] ||
                orderLimits?.limits?.['*']
              const limitMax = orderLimit?.max
              const max =
                limitMax !== undefined && limitMax <= available
                  ? limitMax
                  : available
              const sumQuantity = input.quantity + item.quantity

              //如果加购数量大于库存，则返回失败
              if (sumQuantity > max) {
                result = false
                let tips =
                  orderLimits?.tips?.errorTip ||
                  `<div class='txtCon'>{name},<b>Options</b>:{options}, Must have at most <b>{max}</b> of this item.</div>`

                const options = item?.options?.map((option: any) => {
                  return `${option?.name}:${option?.value}`
                })
                tips = tips
                  ?.replace('{name}', item?.name)
                  ?.replace('{options}', options?.join(',') || item?.name)
                  ?.replace('{max}', max)
                errors.push(tips)
              }
            }
          })
          // anker day 会员折扣统一处理
          let finialInput: any[] = Array.isArray(input) ? input : [input]
          if (result) {
            const resultData = await fetch({
              input: finialInput,
            })
            await mutate(resultData, false)
            return {
              lines,
              result,
              resultData,
              oriCart: data,
              input,
              orderLimits,
            }
          } else {
            const errorMessage = `${
              orderLimits?.tips?.errorTitle ||
              'Cannot place order,conditions not met'
            }${errors?.join(',') || 'addToCart err'}`

            setTipModal(errorMessage)
            throw new CommerceError({
              message: errorMessage,
            })
          }
        },
        [mutate, data]
      )
    }) as any,
}
