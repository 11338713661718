import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { useEffect, useState, useMemo } from 'react'
import dayjs from 'dayjs'
import { Text, Link, Picture } from '@components/ui'
import { template } from '@lib/utils'
import { useRouter } from 'next/router'
import { useGlobal } from '../GlobalContext'
import { getAppName } from '@components/common/Registrations/const'
import useDiscount from 'lib/utils/useDiscount'
import useNewUserCoupon from 'lib/utils/useNewUserCoupon'
import registerCode from '@lib/apis/register-code'
import { useProfile } from '@components/common/ProfileContext'
import useSWR from 'swr'
import { useMount } from 'lib/utils'
import { isMobile } from '@lib/utils/tools'

const Item = ({ item, color, href }) => (
  <>
    {item.href ? (
      <div className="flex items-center justify-center gap-3 leading-[1.05] min-md:gap-5">
        <Link
          className="full-width line-clamp-2 text-left text-[12px] font-medium hover:underline min-md:text-center min-md:text-[14px]"
          style={{ color }}
          href={href}
          dangerouslySetInnerHTML={{
            __html: item.text,
          }}
          target={item.openNew ? '_blank' : '_self'}
        ></Link>
        {item?.countdownTime && <CountdownTime data={item} />}
      </div>
    ) : (
      <p>{item.text}</p>
    )}
  </>
)

/**
 * 特殊处理的新用户引导模块，只在手机端显示，内部有根据用户类型展示不同的文案的逻辑
 * @param {*} param0
 * @returns
 */
const NewCustomerItem = ({ item, color, href }) => {
  // 解构获取 profile 和 isFinish
  const { profile, isFinish } = useProfile()

  // 解构获取 locale
  const { locale } = useRouter()
  // 从 item 中解构获取 couponPriority
  const { couponPriority } = item
  // 从 item.text 中解构获取 guestMessage, userMessage, userNoCouponMessage，如果 item.text 不存在则默认为 {}
  const { guestMessage, userMessage, userNoCouponMessage } = item.text || {}

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const isReadyToFetchCode = isFinish && profile && app && locale

  const { data: codeRes, isLoading: isFetchCouponLoading } = useSWR(
    isReadyToFetchCode
      ? 'api/multipass/rainbowbridge/activities/eufy_register_code'
      : null,
    () => registerCode({ locale, email: profile.email, app }),
    {
      revalidateOnFocus: false,
    }
  )

  const availableCoupon = useNewUserCoupon({
    coupons: codeRes?.data?.coupons,
    couponPriority,
  })

  // 使用优惠券获取折扣文本
  const offText = useDiscount({
    discountType: availableCoupon?.value_type,
    value: availableCoupon?.value,
  })

  const message = useMemo(() => {
    if (!profile) {
      return guestMessage
    }
    if (!availableCoupon) {
      return template(userNoCouponMessage, {
        count: dayjs().diff(dayjs(profile?.registed_at * 1000), 'day'),
      })
    }
    return template(userMessage, {
      off: offText,
    })
  }, [
    availableCoupon,
    guestMessage,
    offText,
    profile,
    userMessage,
    userNoCouponMessage,
  ])

  if (!isFinish || isFetchCouponLoading) return null

  return (
    <>
      {item.href ? (
        <div className="flex items-center justify-center gap-3 leading-[1.05] min-md:gap-5">
          {item.icon && (
            <Picture
              source={item.icon}
              className="w-8 flex-shrink-0 rounded-full"
            />
          )}
          <Link
            className="full-width line-clamp-2 text-left text-[12px] font-medium hover:underline min-md:text-center min-md:text-[14px]"
            style={{ color }}
            href={href}
            dangerouslySetInnerHTML={{
              __html: message,
            }}
            target={item.openNew ? '_blank' : '_self'}
          ></Link>
          {item.cta && (
            <Link
              href={href}
              target={item.openNew ? '_blank' : '_self'}
              className="text-nowrap rounded-[40px] bg-[#04283A] px-[11px] py-[8px] text-[14px] font-medium text-white"
            >
              {item.cta}
            </Link>
          )}
          {item?.countdownTime && <CountdownTime data={item} />}
        </div>
      ) : (
        <p>{item.text}</p>
      )}
    </>
  )
}

const EufyAnnouncement = ({ metafields }) => {
  const { locale } = useRouter()
  const { geoCode } = useGlobal()
  const { announcement } = metafields
  if (!announcement || !Object.keys(announcement).length || announcement.hide)
    return null

  const BaseAnn = ({ announcement }) => {
    const { bgColor, color, text, href, openNew } = announcement
    return (
      <div
        className="bg-brand-color py-[10px] font-semibold"
        style={{
          textAlign: 'center',
          backgroundColor: bgColor,
        }}
      >
        <Link
          className="full-width block hover:underline"
          style={{ color }}
          href={href}
          dangerouslySetInnerHTML={{ __html: text }}
          target={openNew ? '_blank' : '_self'}
        ></Link>
      </div>
    )
  }

  const SwiperAnn = ({ announcement }) => {
    const { lists, bgColor, color, ref = 'topbanner', delay } = announcement
    const [isMob, setIsMob] = useState(false)

    useMount(() => {
      setIsMob(isMobile())
    })

    const filterLists = useMemo(
      () =>
        lists.filter((item) => {
          return (
            (!item.show || item.show.includes(locale)) &&
            (!item.locales || item.locales.includes(geoCode)) &&
            (isMob || (!isMob && item.strategy !== 'newCustomer'))
          )
        }),
      [lists, isMob]
    )

    // start_ai_generated
    const settings = {
      direction: 'vertical',
      loop: true,
      modules: [Autoplay],
      ...(filterLists.length > 1 && {
        autoplay: {
          delay: parseInt(delay || 5000),
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
      }),
      allowTouchMove: filterLists.length > 1 ? true : false,
      style: {
        height: '46px',
        backgroundColor: bgColor,
      },
    }
    // end_ai_generated

    return (
      <div id="announcementBar">
        <Swiper {...settings}>
          {filterLists.map((item, index) => {
            let href
            const refIndex = ref + '_' + (index + 1)
            if (item.href?.indexOf('?') > -1) {
              href = item.href + '&ref=' + refIndex
            } else {
              href = item.href + '?ref=' + refIndex
            }
            return (
              // start_ai_generated
              <SwiperSlide
                key={index}
                className="flex items-center justify-center text-center"
              >
                <div className="layer">
                  <div className="content leading-[1.05]">
                    {item.strategy === 'newCustomer' ? (
                      <NewCustomerItem item={item} color={color} href={href} />
                    ) : (
                      <Item item={item} color={color} href={href} />
                    )}
                  </div>
                </div>
              </SwiperSlide>
              // end_ai_generated
            )
          })}
        </Swiper>
      </div>
    )
  }

  const { lists } = announcement

  if (lists) return <SwiperAnn announcement={announcement} />

  return <BaseAnn announcement={announcement} />
}

const CountdownTime = ({ data }) => {
  const [countdownTime, setCountdownTime] = useState({})
  useEffect(() => {
    if (data?.countdownTime) {
      const calculateTime = () => {
        const now = dayjs()
        const target = dayjs(data?.countdownTime)
        const difference = target.diff(now)
        if (difference > 0) {
          const days = target.diff(now, 'day')
          const hours = target.diff(now, 'hour') % 24
          const minutes = target.diff(now, 'minute') % 60
          const seconds = target.diff(now, 'second') % 60
          return {
            days: days.toString().padStart(2, '0').split(''),
            hours: hours.toString().padStart(2, '0').split(''),
            minutes: minutes.toString().padStart(2, '0').split(''),
            seconds: seconds.toString().padStart(2, '0').split(''),
          }
        }
      }
      setInterval(() => {
        setCountdownTime(calculateTime() || {})
      }, 1000)
    }
  }, [data?.countdownTime])
  return (
    !!Reflect.ownKeys(countdownTime).length && (
      <div className="relative flex items-center justify-center gap-1 min-md:gap-2">
        {data?.daysText && (
          <TimeItem
            timeItemBgColor={data?.timeItemBgColor}
            timeLabelColor={data?.timeLabelColor}
            label={data?.daysText}
            time={countdownTime?.days?.join('')}
          />
        )}
        <Text html=":" className="text-[24px] text-[#00496F]" />
        <TimeItem
          timeItemBgColor={data?.timeItemBgColor}
          timeLabelColor={data?.timeLabelColor}
          label={data?.hrsText}
          time={countdownTime?.hours?.join('')}
        />
        <Text html=":" className="text-[24px] text-[#00496F]" />
        <TimeItem
          timeItemBgColor={data?.timeItemBgColor}
          timeLabelColor={data?.timeLabelColor}
          label={data?.minsText}
          time={countdownTime?.minutes?.join('')}
        />
        <Text html=":" className="text-[24px] text-[#00496F]" />
        <TimeItem
          timeItemBgColor={data?.timeItemBgColor}
          timeLabelColor={data?.timeLabelColor}
          label={data?.secText}
          time={countdownTime?.seconds?.join('')}
        />
      </div>
    )
  )
}

const TimeItem = ({ time, timeItemBgColor }) => {
  return (
    <div
      className="flex h-[28px] w-[28px] flex-col items-center justify-center rounded-[4px] bg-[#00496f] text-[12px] text-white min-md:h-[30px] min-md:w-[30px]"
      style={{ backgroundColor: timeItemBgColor }}
    >
      <div className="font-semibold min-md:text-[16px]">{time}</div>
      {/* <div
        className="font-medium opacity-[.75]"
        style={{ color: timeLabelColor }}
      >
        {label}
      </div> */}
    </div>
  )
}

export default EufyAnnouncement
