import Cookies from 'js-cookie'
import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'

export default async function registerCode({ locale, email, app }) {
  const url = '/api/multipass/rainbowbridge/activities/eufy_register_code'
  const appToken = Cookies.get(`${app}-info`)
  const shopifyDomain = I18N_STORE_DOMAIN[locale]

  const res = await fetcher({
    url,
    locale,
    method: 'POST',
    headers: {
      'x-application-token': appToken,
    },
    body: {
      email,
      shopify_domain: shopifyDomain,
    },
  })

  return res?.data
}
