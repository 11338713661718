import dynamic from 'next/dynamic'

const All = dynamic(() => import('./ALL'))

const ACPower = dynamic(() => import('./ac-power/ACPower'))
const PowerStrips = dynamic(() => import('./ac-power/PowerStrips'))
const USBOutlets = dynamic(() => import('./ac-power/USBOutlets'))
const OutletExtenders = dynamic(() => import('./ac-power/OutletExtenders'))

const Chargers = dynamic(() => import('./chargers/Chargers'))
const ForIPhoneAndMore = dynamic(() => import('./chargers/ForIPhoneAndMore'))
const ForLaptops = dynamic(() => import('./chargers/ForLaptops'))
const ForTablets = dynamic(() => import('./chargers/ForTablets'))
const ForMultiDevice = dynamic(() => import('./chargers/ForMultiDevice'))

const PowerStations = dynamic(() => import('./power-stations/PowerStations'))
const Portable = dynamic(() => import('./power-stations/Portable'))
const SolarPanels = dynamic(() => import('./power-stations/SolarPanels'))
const SolarGenerators = dynamic(
  () => import('./power-stations/SolarGenerators')
)

const PowerBanks = dynamic(() => import('./power-banks/PowerBanks'))
const Under3 = dynamic(() => import('./power-banks/Under3'))
const Range3to4 = dynamic(() => import('./power-banks/Range3to4'))
const Up4 = dynamic(() => import('./power-banks/Up4'))
const PowerBanksWireless = dynamic(() => import('./power-banks/Wireless'))

const Cables = dynamic(() => import('./cables/Cables'))
const Foriphone = dynamic(() => import('./cables/Foriphone'))
const USCc = dynamic(() => import('./cables/USCc'))
const Cableother = dynamic(() => import('./cables/Others'))

const Wireless = dynamic(() => import('./wireless/Wireless'))
const Magnetic = dynamic(() => import('./wireless/Magnetic'))
const Stands = dynamic(() => import('./wireless/Stands'))
const Pads = dynamic(() => import('./wireless/Pads'))
const Stations = dynamic(() => import('./wireless/Stations'))
const CarChargers = dynamic(() => import('./wireless/CarChargers'))

const HubsAndDocks = dynamic(() => import('./hubs-and-docks/HubsAndDocks'))
const Hubs = dynamic(() => import('./hubs-and-docks/Hubs'))
const Adapters = dynamic(() => import('./hubs-and-docks/Adapters'))
const DockingStations = dynamic(
  () => import('./hubs-and-docks/DockingStations')
)

const ContactPhone = dynamic(() => import('./contact/Phone'))
const ContactEmail = dynamic(() => import('./contact/Email'))
const ContactChat = dynamic(() => import('./contact/Chat'))
const ContactWhatApp = dynamic(() => import('./contact/WhatApp'))

const AdvantageGuarantee = dynamic(() => import('./advantage/Guarantee'))
const AdvantageShipping = dynamic(() => import('./advantage/Shipping'))
const AdvantageSupport = dynamic(() => import('./advantage/Support'))
const AdvantageWarranty = dynamic(() => import('./advantage/Warranty'))

const Facebook = dynamic(() => import('./eufy-apps/Facebook'))
const Twitter = dynamic(() => import('./eufy-apps/Twitter'))
const Instagram = dynamic(() => import('./eufy-apps/Instagram'))
const Linkin = dynamic(() => import('./eufy-apps/Linkin'))

const Filter = dynamic(() => import('./v3/Filter'))
const Sort = dynamic(() => import('./v3/Sort'))
const CartV3 = dynamic(() => import('./v3/Cart'))
const CloseV3 = dynamic(() => import('./v3/Close'))
const ArrowV3 = dynamic(() => import('./v3/Arrow'))

const icons = (props: any) =>
  [
    {
      key: 'all',
      icon: <All {...props}></All>,
    },
    // hubs-and-docks
    {
      key: 'adapter-hubs', // de
      icon: <HubsAndDocks {...props}></HubsAndDocks>,
    },
    {
      key: 'hubs-and-docks',
      icon: <HubsAndDocks {...props}></HubsAndDocks>,
    },
    {
      key: 'hubs',
      icon: <Hubs {...props}></Hubs>,
    },
    {
      key: 'adapters',
      icon: <Adapters {...props}></Adapters>,
    },
    {
      key: 'docking-stations',
      icon: <DockingStations {...props}></DockingStations>,
    },

    // wireless
    {
      key: 'kabelloses-zubehor', // de
      icon: <Wireless {...props}></Wireless>,
    },
    {
      key: 'wireless',
      icon: <Wireless {...props}></Wireless>,
    },
    {
      key: 'magnetic',
      icon: <Magnetic {...props}></Magnetic>,
    },
    {
      key: 'stands',
      icon: <Stands {...props}></Stands>,
    },
    {
      key: 'pads',
      icon: <Pads {...props}></Pads>,
    },
    {
      key: 'stations',
      icon: <Stations {...props}></Stations>,
    },
    {
      key: 'wireless-car-chargers',
      icon: <CarChargers {...props}></CarChargers>,
    },

    // cables
    {
      key: 'kabel', // de
      icon: <Cables {...props}></Cables>,
    },
    {
      key: 'cables',
      icon: <Cables {...props}></Cables>,
    },
    {
      key: 'for-iphone-lightning',
      icon: <Foriphone {...props}></Foriphone>,
    },
    {
      key: 'usb-c',
      icon: <USCc {...props}></USCc>,
    },
    {
      key: 'cableother',
      icon: <Cableother {...props}></Cableother>,
    },

    // power-banks
    {
      key: 'powerbanks', // de
      icon: <PowerBanks {...props}></PowerBanks>,
    },
    {
      key: 'power-banks',
      icon: <PowerBanks {...props}></PowerBanks>,
    },
    {
      key: 'under-3-phone-charges',
      icon: <Under3 {...props}></Under3>,
    },
    {
      key: '3-4-phone-charges',
      icon: <Range3to4 {...props}></Range3to4>,
    },
    {
      key: '4-phone-charges',
      icon: <Up4 {...props}></Up4>,
    },
    {
      key: 'powerbanks-wireless',
      icon: <PowerBanksWireless {...props}></PowerBanksWireless>,
    },

    // power-stations
    {
      key: 'ladestationen', // de
      icon: <PowerStations {...props}></PowerStations>,
    },
    {
      key: 'power-stations',
      icon: <PowerStations {...props}></PowerStations>,
    },
    {
      key: 'portable-power-stations',
      icon: <Portable {...props}></Portable>,
    },
    {
      key: 'solar-panels',
      icon: <SolarPanels {...props}></SolarPanels>,
    },
    {
      key: 'solar-generators',
      icon: <SolarGenerators {...props}></SolarGenerators>,
    },
    {
      key: 'solarstrom-set', // de
      icon: <SolarGenerators {...props}></SolarGenerators>,
    },

    // chargers
    {
      key: 'wandladegerate', // de
      icon: <Chargers {...props}></Chargers>,
    },
    {
      key: 'chargers',
      icon: <Chargers {...props}></Chargers>,
    },
    {
      key: 'for-iphone-and-more',
      icon: <ForIPhoneAndMore {...props}></ForIPhoneAndMore>,
    },
    {
      key: 'for-tablets',
      icon: <ForTablets {...props}></ForTablets>,
    },
    {
      key: 'for-laptops',
      icon: <ForLaptops {...props}></ForLaptops>,
    },
    {
      key: 'car-chargers',
      icon: <CarChargers {...props}></CarChargers>,
    },
    {
      key: 'for-multi-device',
      icon: <ForMultiDevice {...props}></ForMultiDevice>,
    },

    // ac-power
    {
      key: 'ac-power',
      icon: <ACPower {...props}></ACPower>,
    },
    {
      key: 'power-strips',
      icon: <PowerStrips {...props}></PowerStrips>,
    },
    {
      key: 'usb-outlets',
      icon: <USBOutlets {...props}></USBOutlets>,
    },
    {
      key: 'outlet-extenders',
      icon: <OutletExtenders {...props}></OutletExtenders>,
    },

    // Contact-contact
    {
      key: 'contact-phone',
      icon: <ContactPhone {...props}></ContactPhone>,
    },
    {
      key: 'contact-email',
      icon: <ContactEmail {...props}></ContactEmail>,
    },
    {
      key: 'contact-chat',
      icon: <ContactChat {...props}></ContactChat>,
    },
    {
      key: 'contact-whatsapp',
      icon: <ContactWhatApp {...props}></ContactWhatApp>,
    },

    // Advantage-advantage
    {
      key: 'advantage-guarantee',
      icon: <AdvantageGuarantee {...props}></AdvantageGuarantee>,
    },
    {
      key: 'advantage-shipping',
      icon: <AdvantageShipping {...props}></AdvantageShipping>,
    },
    {
      key: 'advantage-support',
      icon: <AdvantageSupport {...props}></AdvantageSupport>,
    },
    {
      key: 'advantage-warranty',
      icon: <AdvantageWarranty {...props}></AdvantageWarranty>,
    },

    // Eufy-App
    {
      key: 'eufy-facebook',
      icon: <Facebook {...props}></Facebook>,
    },
    {
      key: 'eufy-twitter',
      icon: <Twitter {...props}></Twitter>,
    },
    {
      key: 'eufy-instagram',
      icon: <Instagram {...props}></Instagram>,
    },
    {
      key: 'eufy-linkin',
      icon: <Linkin {...props}></Linkin>,
    },

    // collection v3
    {
      key: 'filter',
      icon: <Filter {...props}></Filter>,
    },
    {
      key: 'sort',
      icon: <Sort {...props}></Sort>,
    },
    {
      key: 'cartv3',
      icon: <CartV3 {...props}></CartV3>,
    },
    {
      key: 'closev3',
      icon: <CloseV3 {...props}></CloseV3>,
    },
    {
      key: 'arrowv3',
      icon: <ArrowV3 {...props}></ArrowV3>,
    },
  ] as const

export default icons
