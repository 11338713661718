import { i18n, features } from 'commerce.config'

import { getDataFromFeature, getEuLocales } from '@lib/utils/tools'

export const BRAND = process.env.NEXT_PUBLIC_BRAND || 'eufy'

export const COMMERCE_FEATURES = features

export const SHOPIFY_COOKIE_EXPIRE = 30

export const LOCALES = i18n.locales

export const USE_DEFAULT_LOGIN = process.env.NEXT_PUBLIC_USE_DEFAULT_LOGIN

export const SHORT_LOCALE_MAP: Record<string, string> = getDataFromFeature({
  features,
  key: 'shortLocale',
})

export const LOCALE_ASCRIPTION_MAP: Record<string, string> = getDataFromFeature(
  { features, key: 'sourceDataLocale' }
)

export const LISTING_SHOW_CHECKOUT_MAP: Record<string, boolean> =
  getDataFromFeature({ features, key: 'showCheckoutBar' })

export const MULPASS_URL =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? `https://passport.${BRAND}.com`
    : `https://beta.passport.${BRAND}.com`

export const API_TOKEN = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN

export const STORE_DOMAIN = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN

export const API_URL = `https://${STORE_DOMAIN}/api/2024-01/graphql.json`

export const ENV = process.env.NEXT_PUBLIC_ENV

export const I18N_API_TOKEN =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? getDataFromFeature({ features, key: 'storefrontToken' })
    : getDataFromFeature({ features, key: 'betaStorefrontToken' })

export const I18N_STORE_DOMAIN = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    const ASLOCALE_MAP = LOCALE_ASCRIPTION_MAP[locale] || locale
    result[locale] = `${
      process.env.NEXT_PUBLIC_ENV === 'production' ? '' : 'beta-'
    }${BRAND}-${
      SHORT_LOCALE_MAP[ASLOCALE_MAP || ''] || ASLOCALE_MAP
    }.myshopify.com`
  })
  return result
})()

export const NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID =
  process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID

export const FAKE_PRICE = 9999999.99

export const SHOPIFY_CART_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCart_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CHECKOUT_ID_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCheckoutId_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CHECKOUT_URL_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCheckoutUrl_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const SHOPIFY_CUSTOMER_TOKEN_COOKIE = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `shopifyCustomerToken_${I18N_STORE_DOMAIN[locale]}`
  })
  return result
})()

export const COMMUNITY_REFERRAL_HANDLES = [
  '/community-referral-program',
  '/exclusive-community-offer',
]

const REFERRAL_GID =
  process.env.NEXT_PUBLIC_ENV === 'production' ? 80716628154 : 93218570409

export const REFERRAL_PAGES_INFO = {
  id: `gid://shopify/Page/${REFERRAL_GID}`,
  path: '/referral-new',
  name: 'Referral',
  handle: 'referral-new',
}

export const ORIGIN_URL = {
  anker: 'https://www.anker.com',
  eufy:
    process.env.NEXT_PUBLIC_ENV === 'production'
      ? 'https://www.eufy.com'
      : 'https://beta.eufy.com',
  ankermake: 'https://www.ankermake.com',
}[BRAND]

export const EUFY_COUNTRY_SUFFIX: {
  [key: string]: string
} = getDataFromFeature({ features, key: 'title' })

export const APP_NAME = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    const NAME = `${BRAND}-${SHORT_LOCALE_MAP[locale || ''] || locale}`
    result[locale] =
      process.env.NEXT_PUBLIC_ENV === 'production' ? NAME : `beta-${NAME}`
  })
  return result
})()

export const COUNTRY_MAP = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    const asLocale = LOCALE_ASCRIPTION_MAP[locale || ''] || locale
    result[locale] = SHORT_LOCALE_MAP[asLocale || ''] || locale
  })
  return result
})()

export const EU_SHOP_LOCALES = getEuLocales({ features })

export const YOTPO_SHOP_LOCALES = ['us', 'ca']

export const I18N_ACCOUNT_APPLICATION_NAME = (function () {
  const { locales = [] } = i18n
  const result: { [key: string]: string } = {}
  locales.forEach((locale) => {
    result[locale] = `${
      process.env.NEXT_PUBLIC_ENV === 'production' ? '' : 'beta-'
    }${BRAND}-${SHORT_LOCALE_MAP[locale || ''] || locale}-shopify`
  })
  return result
})()

export const I18N_THEME_DOMAIN = I18N_STORE_DOMAIN
  ? (function () {
      const { locales = [] } = i18n
      const result: { [key: string]: string } = {}
      locales.forEach((locale) => {
        result[locale] =
          process.env.NEXT_PUBLIC_ENV === 'production'
            ? `${SHORT_LOCALE_MAP[locale] || locale}.${BRAND}.com`
            : I18N_STORE_DOMAIN[locale]
      })
      return result
    })()
  : ''

export const I18N_API_URL =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? I18N_THEME_DOMAIN &&
      (function () {
        const result: { [key: string]: string } = {}
        Object.keys(I18N_THEME_DOMAIN).forEach((locale) => {
          result[
            locale
          ] = `https://${I18N_THEME_DOMAIN[locale]}/api/2024-01/graphql.json`
        })
        return result
      })()
    : I18N_STORE_DOMAIN &&
      (function () {
        const result: { [key: string]: string } = {}
        Object.keys(I18N_STORE_DOMAIN).forEach((locale) => {
          result[
            locale
          ] = `https://${I18N_STORE_DOMAIN[locale]}/api/2024-01/graphql.json`
        })
        return result
      })()

// 会员身份类型
export enum MEMBER_TYPE {
  NON = '0', // 未登录
  FREE = '1', // 普通会员
  PLUS = '2', // 付费会员
}

// 会员价折上折的作用主体
export enum MEMBER_DISCOUNT_SUBJECT {
  PRICE = 'discounted_price',
  AMOUNT = 'discount_amount',
}

// 会员价折扣类型
export enum MEMBER_DISCOUNT_TYPE {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixed_amount',
}
