import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { normalizeCart } from 'lib/utils/normalize'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { cartAttributesUpdateMutation } from 'lib/shopify/mutations/cart'
import { Cart as CartType } from 'lib/shopify/types'
import { SHOPIFY_CHECKOUT_ID_COOKIE } from 'lib/config/const'

// todo!
import { ValidationError } from '@commerce/utils/errors'
import { getUniqueListBy } from '@lib/utils/tools'
import { useCart } from './use-cart'

export const getAttributesFromLineItems = (
  lineItems: CartType.InputLineItem[]
) => {
  const attributes: CartType.InputArribute[] = []
  const appSubAttributes: CartType.subInputValue = {}
  lineItems.map((item: CartType.InputLineItem) => {
    if (item?.attributes?.length) {
      item?.attributes?.map((item: CartType.InputArribute) => {
        if (item?.key?.includes('_appsub_')) {
          appSubAttributes[item.key.replace('_appsub_', '') ?? ''] =
            item?.value ?? ''
        } else if (item?.key?.includes('_utm_params')) {
          attributes.push({
            key: item?.key?.replace('_', ''),
            value: item?.value ?? '',
          })
        }
      })
    }
  })

  if (Object.keys(appSubAttributes).length) {
    attributes.push({
      key: 'subscription_package_no',
      value: JSON.stringify(appSubAttributes),
    })
  }

  return attributes
}

export async function cartAttributesUpdateFn(
  locale: string = 'us',
  attributes: any,
  id?: string
): Promise<CartType.ExportCart> {
  // 活动折扣 cookie 全站生效，后端拿不到，所以暂时注释
  // const personaCookie = getPersonaCookie()
  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]
  const cartId = id || Cookies.get(checkoutIdCookie)

  if (!cartId) {
    throw new ValidationError({
      message: 'Invalid input used for this operation: Miss cartId',
    })
  }
  const { res, status } =
    await fetchGraphqlApi<CartType.CartAttributesUpdateOperation>({
      locale,
      query: cartAttributesUpdateMutation,
      variables: {
        cartId,
        attributes,
      },
      cache: 'no-store',
    })

  const cart = res?.cartAttributesUpdate?.cart

  return cart && normalizeCart(cart)
}

export function useCartAttributesUpdate() {
  const { locale } = useRouter()

  const { mutate, data } = useCart()
  const cartAttributesUpdate = useCallback(
    async (input: {
      customAttributes: any
      id?: string
      updateRestAttrs?: boolean
    }) => {
      const inputAttributes = input?.customAttributes || []
      const id = input?.id || data?.id
      const attributes = getUniqueListBy(
        [
          ...(input?.updateRestAttrs ? data?.attributes || [] : []),
          ...inputAttributes,
        ],
        'key'
      ).filter((item) => item.value)
      if (!id) {
        throw new ValidationError({
          message: 'Invalid input used for this operation: Miss Cart Data',
        })
      }

      const result = await cartAttributesUpdateFn(locale, attributes, id)
      await mutate(result, false)
      return result
    },
    [data, locale, mutate, getUniqueListBy]
  )

  return cartAttributesUpdate
}
