import { FC, useRef, useEffect, useCallback } from 'react'
import s from './Dialog.module.css'
import { Cross } from '@components/icons/next'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { cn } from '@components/helper'

interface ModalProps {
  className?: string
  rootClassName?: string
  closeClassName?: string
  iconClassName?: string
  closeStrokeColor?: string
  children?: any
  onClose: () => void
  onEnter?: () => void | null
}

const Modal: FC<ModalProps> = ({
  children,
  onClose,
  className,
  rootClassName,
  closeClassName,
  iconClassName,
  closeStrokeColor = 'currentColor',
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    const modal = ref.current

    if (modal) {
      disableBodyScroll(modal, { reserveScrollBarGap: true })
      window.addEventListener('keydown', handleKey)
    }
    return () => {
      clearAllBodyScrollLocks()
      window.removeEventListener('keydown', handleKey)
    }
  }, [handleKey])

  return (
    <div className={cn(s.root, rootClassName)}>
      <div className={cn(s.modal, className)} role="dialog" ref={ref}>
        <button
          onClick={() => onClose()}
          aria-label="Close panel"
          className={cn(s.close, closeClassName)}
        >
          <Cross
            stroke={closeStrokeColor}
            className={cn('h-[22px] w-[22px]', iconClassName)}
          />
        </button>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default Modal
