import '@assets/main.css'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import NiceModal from '@ebay/nice-modal-react'
// import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Head, Layout } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { addCookie } from '@lib/utils/cookies'
import '../components/common/CookieConsent/styles.css'
import localFont from 'next/font/local'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ProfileProvider } from '@components/common/ProfileContext'
import { GlobalProvider } from '@components/common/GlobalContext'
import { useForceHtmlLangAttribute } from 'lib/utils/index'
import { I18N_THEME_DOMAIN } from '@shopify/const'
import { ErrorBoundary } from '@sentry/nextjs'
import { Text } from '@components/ui'

const Gilroy = localFont({
  variable: '--font-Gilroy',
  src: [
    {
      path: '../fonts/Gilroy-Regular.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../fonts/Gilroy-Medium.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../fonts/Gilroy-SemiBold.otf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../fonts/Gilroy-Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../fonts/Gilroy-ExtraBold.otf',
      weight: '800',
      style: 'normal',
    },
  ],
})

const theme = createTheme({
  typography: {
    fontFamily: Gilroy.style.fontFamily,
  },
})

const ThirdParty = dynamic(() => import('@components/common/ThirdParty'))

const FallbackComponent = () => {
  return (
    <div className="fit mx-auto flex max-w-2xl flex-col items-center justify-center px-10 py-20">
      <Text variant="heading">Oops, there is an error!</Text>
    </div>
  )
}

export default function MyApp({ Component, pageProps }) {
  const { locale } = useRouter()
  const PROPS = { ...pageProps, locale }
  const shopifyUrl = `https://${I18N_THEME_DOMAIN[locale]}`

  useForceHtmlLangAttribute(locale)

  useEffect(() => {
    addCookie({ locale, shopifyUrl })
    document.body.classList?.remove('loading')
  }, [locale, pageProps])

  return (
    <ErrorBoundary
      fallback={FallbackComponent}
      beforeCapture={(scope, error) => {
        scope.setTags({
          errorType: 'CrashError',
          errorScope: 'Client',
        })
        if (error) {
          error.name = `CrashError: ${location.origin + location.pathname}`
        }
      }}
    >
      <Head pageProps={PROPS} />
      <ManagedUIContext>
        <style jsx global>{`
          html {
            font-family: ${Gilroy.style.fontFamily}, -apple-system, system-ui,
              BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', sans-serif;
          }
        `}</style>
        <ThemeProvider theme={theme}>
          <ProfileProvider>
            <GlobalProvider>
              <Layout pageProps={PROPS}>
                <NiceModal.Provider>
                  <Component {...PROPS} />
                  <ErrorBoundary
                    beforeCapture={(scope, error) => {
                      scope.setTags({
                        errorType: 'ThirdPartyCrashError',
                        errorScope: 'Client',
                      })
                      if (error) {
                        error.name = `ThirdPartyCrashError: ${
                          location.origin + location.pathname
                        }`
                      }
                    }}
                  >
                    <ThirdParty pageProps={PROPS} />
                  </ErrorBoundary>
                </NiceModal.Provider>
              </Layout>
            </GlobalProvider>
          </ProfileProvider>
        </ThemeProvider>
      </ManagedUIContext>
    </ErrorBoundary>
  )
}
