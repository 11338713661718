import cn from 'classnames'
import React, {
  forwardRef,
  ButtonHTMLAttributes,
  JSXElementConstructor,
  useRef,
} from 'react'
import mergeRefs from 'react-merge-refs'
import styles from './Button.module.css'
import { LoadingDots } from '@components/ui'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  className?: string
  variant?:
    | 'slim'
    | 'ghost'
    | 'naked'
    | 'plain'
    | 'primary'
    | 'secondary'
    | 'secondaryBlack'
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  Component?: string | JSXElementConstructor<any>
  width?: string | number
  loading?: boolean
  disabled?: boolean
  border?: boolean
  s?: any
  target?: '_blank' | '_self' | '_parent' | '_top'
}

const Button: React.FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = '',
    children,
    active,
    width,
    loading = false,
    disabled = false,
    style = {},
    Component = 'button',
    border = false,
    s: gs = {},
    ...rest
  } = props
  const ref = useRef<typeof Component>(null)
  const s = { ...styles, ...gs }
  const rootClassName = cn(
    {
      [s.root]: !variant,
      [s.ghost]: variant === 'ghost',
      [s.slim]: variant === 'slim',
      [s.naked]: variant === 'naked',
      // [s.loading]: loading,
      [s.disabled]: disabled,
      [s.primary]: variant === 'primary',
      [s.secondary]: variant === 'secondary',
      [s.secondaryBlack]: variant === 'secondaryBlack',
      [s.boxBorder]: border,
      ['']: variant === 'plain',
    },
    className
  )

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={rootClassName}
      disabled={disabled || loading}
      tabIndex={'onClick' in rest ? '0' : '-1'}
      role={'onClick' in rest ? 'button' : ''}
      onKeyPress={
        'onClick' in rest
          ? (e: any) => {
              if (e.key == 'Enter') {
                rest.onClick && rest.onClick(e)
              }
            }
          : () => {}
      }
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {loading ? <LoadingDots /> : children}
    </Component>
  )
})

Button.displayName = 'Button'

export default Button
