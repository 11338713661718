import { useEffect, useState, useMemo } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import debounce from 'lodash.debounce'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useUI } from '@components/ui/context'
import { useCommerce } from '@commerce/index'
import { Picture, Text, Link } from '@components/ui'
import { BRAND } from '@shopify/const'
import usePrice from '@shopify/product/use-price'
import { useUpdateItem, useRemoveItem } from 'lib/shopify/api/cart'
import { ProductQuantity, ProductPrice } from '@components/product'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { atobID } from '@lib/utils/tools'
import s from './CartItem.module.css'
import BannerLink from '@components/common/BannerLink'
import { getGaId } from '@lib/utils/tools'

const placeholderImg = '/product-img-placeholder.svg'

const CartItem = ({
  item,
  index,
  bundleItem,
  currencyCode,
  shopCommon = {},
  mainProduct = '',
  discountPrice,
  modal = 'default',
  position,
  allSku,
  getBundleSize,
  quantityBreakDescription,
  removeItemCbk,
  ...rest
}) => {
  const clientId = getGaId()

  // eslint-disable-next-line no-unused-vars
  const { locale } = useRouter()
  const { closeSidebarIfPresent } = useUI()
  const [removing, setRemoving] = useState(false)
  //根据sku限购需求，最大最小值从shop里配置的sku里获取
  const { shop } = useCommerce()
  const orderLimits = shop?.orderLimits
  const orderLimit = orderLimits?.limits?.[item?.variant?.sku]
  const limitMin = orderLimit?.min !== undefined ? orderLimit?.min : 1
  item.quantity = item.quantity >= limitMin ? item.quantity : limitMin
  const [quantity, setQuantity] = useState(item?.quantity)
  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem({
    item,
  })
  const [errorTip, setErrorTip] = useState('')
  const errorHandle = (error, curQuantity) => {
    setErrorTip(error)
    setQuantity('')
    setTimeout(() => {
      setQuantity(curQuantity)
    }, 10)
    const errorTimer = setTimeout(() => {
      setErrorTip('')
      clearTimeout(errorTimer)
    }, 8000)
  }
  const { price, basePrice, free } = usePrice({
    amount:
      discountPrice && discountPrice > 0
        ? Number(discountPrice)
        : +item.totalAmount,
    baseAmount: +item.subtotalAmount,
    currencyCode,
  })

  const { metafields = {} } = item?.variant

  const handleChange = async (value) => {
    if (!bundleItem) setQuantity(Number(value))
    if (item?.quantity === value || errorTip) return false
    try {
      await updateItem({
        quantity: Number(value),
        customAttributes: item?.customAttributes || [],
      })
      setErrorTip('')
    } catch (err) {
      console.warn('change updateCart err', err)
      errorHandle(err?.message || 'updateCart err', item.quantity)
    }
  }

  const increaseQuantity = async (n = 1) => {
    const curQuantity = item.quantity
    if (bundleItem) {
      const val = Number(item.quantity) + n
      setQuantity(val)
      await updateItem({
        quantity: val,
        customAttributes: item?.customAttributes || [],
      })
        .then(() => {
          setErrorTip('')
        })
        .catch((err) => {
          console.warn('bundle updateCart err', err, item.quantity)
          errorHandle(err?.message || 'updateCart err', curQuantity)
        })
    } else {
      const val = Number(quantity) + n
      setQuantity(val)
      await updateItem({
        quantity: val,
        customAttributes: item?.customAttributes || [],
      })
        .then(() => {
          setErrorTip('')
        })
        .catch((err) => {
          console.warn('updateCart err', err, item.quantity)
          errorHandle(err?.message || 'updateCart err', curQuantity)
        })
    }
    if (rest?.form === 'CartSidebarView') {
      pageGTMEvent({
        event: 'eeEvent',
        eventCategory: 'EnhancedEcommerce',
        eventAction: n > 0 ? 'Added Product' : 'Removed Product',
        nonInteraction: false,
        ecommerce: {
          currencyCode: currencyCode,
          ...(n > 0
            ? {
                add: {
                  products: [
                    {
                      id: item.variant.sku,
                      name: item.name,
                      price: price,
                      quantity: item.quantity,
                      variant: item.variant.name,
                      brand: BRAND,
                      category: '',
                    },
                  ],
                },
              }
            : {
                remove: {
                  products: [
                    {
                      id: item.variant.sku,
                      name: item.name,
                      price: price,
                      quantity: item.quantity,
                      variant: item.variant.name,
                      brand: BRAND,
                      category: '',
                    },
                  ],
                },
              }),
        },
      })
      pageGTMEvent({
        // GA4
        event: n > 0 ? 'add_to_cart' : 'remove_from_cart',
        page_group: document.location.href,
        position:
          n > 0 ? 'Shopping Cart Sidebar' : 'Shopping Cart Sidebar_Minus',
        ecommerce: {
          currency: currencyCode,
          value: price,
          items: [
            {
              item_id: atobID(item.variant?.sku),
              item_name: item.name,
              item_brand: BRAND,
              item_variant: item.variant.name,
              price: price,
              quantity: item.quantity,
            },
          ],
        },
      })
      n > 0 &&
        pageGTMEvent({
          event: 'Add to Cart',
          page_group: document.location.href,
          position: 'Shopping Cart Sidebar',
          ecommerce: {
            currency: currencyCode,
            value: price,
            items: [
              {
                item_id: atobID(item.variant?.sku),
                item_name: item.name,
                item_brand: BRAND,
                item_variant: item.variant.name,
                item_category: item.productType,
                price: price,
                quantity: item.quantity,
              },
            ],
          },
        })
    }
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item)
      // 调用你的分析函数以记录移除操作
      const numericIds = item?.product?.id?.split('/').pop()
      const numericId = item?.variant?.id?.split('/').pop()
      window?._fwn.analytics &&
        window?._fwn.analytics.removeFromCart({
          ext_customer_identifier: clientId,
          currency: currencyCode,
          country: locale,
          subtotal: item?.subtotalAmount,
          product: [
            {
              ext_parent_product_id: numericIds,
              parent_product_name: item?.name,
              ext_product_id: numericId,
              product_name: item?.product?.title,
              image: item?.product?.featuredImage?.url,
              price: item?.variant?.price,
              currency: locale,
              sku: item?.variant?.sku,
              quantity: item?.quantity,
            },
          ],
        })

      removeItemCbk()
      if (rest?.form === 'CartSidebarView') {
        pageGTMEvent({
          event: 'eeEvent',
          eventCategory: 'EnhancedEcommerce',
          eventAction: 'Removed Product',
          nonInteraction: false,
          ecommerce: {
            currencyCode: currencyCode,
            remove: {
              products: [
                {
                  id: item.variant.sku,
                  name: item.name,
                  price: price,
                  quantity: item.quantity,
                  variant: item?.variant?.name,
                  position: rest?.position,
                  brand: BRAND,
                  category: '',
                },
              ],
            },
          },
        })
      }
    } catch (error) {
      setRemoving(false)
    }
  }

  const options = useMemo(() => {
    return item.options
  }, [item.options, item?.variant?.options, modal])

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.quantity])

  const handleNameClick = () => {
    closeSidebarIfPresent()
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle_title',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'bundle',
      event_parameters: {
        page_group: 'Cart Pop Up_' + allSku?.join(','),
        position: 'Cart Pop Up_Bundle_title',
        SKU: item?.variant?.sku,
        SKU_name: item?.name,
        SUM: price,
        SKU_variant: item?.variant?.name,
        SKU_index: position,
      },
    })
  }

  const handleImgClick = () => {
    closeSidebarIfPresent()

    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle_image',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'bundle',
      event_parameters: {
        page_group: 'Cart Pop Up_' + allSku?.join(','),
        position: 'Cart Pop Up_Bundle_image',
        SKU: item?.variant?.sku,
        SKU_name: item?.name,
        SUM: price,
        SKU_variant: item?.variant?.name,
        SKU_index: position,
      },
    })
  }

  const link = `/products${item.path}?variant=${atobID(
    item?.variant?.id || product?.variants[0]?.id
  )}`
  return (
    <li
      className={cn(s.root, {
        'pointer-events-none opacity-50': removing,
      })}
      {...rest}
    >
      <div className="flex h-full items-stretch space-x-2">
        {/* 产品图 */}
        <div className="relative self-center">
          <Picture
            className={cn(s.productImage)}
            source={item.variant.image?.url || placeholderImg}
            alt={item.variant.image?.altText || 'Product Image'}
          />
          <BannerLink to={link} onClick={handleImgClick} />
        </div>
        {/* 产品信息 */}
        <div className="flex h-auto flex-grow flex-col justify-evenly text-base">
          {/* 产品名 */}
          <Link
            href={link}
            tag="Auto-00000421"
            onClick={handleNameClick}
            className={s.productName}
            dangerouslySetInnerHTML={{
              __html: item.name || item.product_title,
            }}
          />
          {/* 产品选项 */}
          {options && options.length > 0 && (
            <div className="pb-1">
              {options?.map(
                (option, i) =>
                  !option?.value?.includes('Default Title') && (
                    <div
                      key={`${item.id}-${option.name}`}
                      className="text-sm text-[#999]"
                    >
                      {option.name}:
                      <span
                        className="ml-1 overflow-hidden"
                        tag="Auto-00000422"
                      >
                        {option.value}
                      </span>
                      {i === options.length - 1 ? (
                        ''
                      ) : (
                        <span className="mr-3" />
                      )}
                    </div>
                  )
              )}
            </div>
          )}
          {/* 数量 controller */}
          {modal === 'default' && (
            <ProductQuantity
              index={index}
              s={{ quantityBox: s.quantityBox }}
              className={s.quantity}
              value={quantity}
              pId={item.variant.sku}
              increase={(num) => increaseQuantity(num || 1)}
              decrease={(num) => increaseQuantity(num || -1)}
              handleChange={debounce(handleChange, 500)}
              availableForSale={item.variant.availableForSale}
              currentlyNotInStock={item.variant.currentlyNotInStock}
              max={item.variant?.quantityAvailable || 0}
              shopCommon={shopCommon}
            />
          )}
          {metafields.cartInfo && (
            <div className={s.cartInfo}>
              <Text html={metafields.cartInfo}></Text>
            </div>
          )}
        </div>
        <button
          className="h-fit rounded-full bg-gray-100 p-1"
          onClick={handleRemove}
          tag="Auto-00000425"
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>
      {/* 默认购物车价格在图片和产品信息下方 */}
      <div
        className={cn(
          'flex items-center justify-end pt-2 text-lg md:text-base'
        )}
      >
        <ProductPrice
          className={s.productPrice}
          index={index}
          styles={s}
          free={free}
          price={price}
          basePrice={basePrice}
          availableForSale={item.variant?.availableForSale}
          settings={shopCommon}
        />
      </div>
      {/* 满减折扣描述 */}
      {item.product?.tags?.includes('bundlepage_special_discount') &&
        quantityBreakDescription}
      {orderLimits?.show && errorTip && (
        <Text html={errorTip} className={s.errorTip}></Text>
      )}
    </li>
  )
}

export default CartItem
