// 引入React相关的hooks
import React, { useCallback, useEffect, useRef, useState } from 'react'
// 引入组件样式
import styles from './index.module.css'
// 引入Text组件
import { Text } from '@components/ui'
// 引入classnames库，用于条件性地合并class
import cn from 'classnames'
// 引入fetcher函数，用于发起网络请求
import fetcher from '@lib/fetcher'
// 引入APP_NAME常量
import { APP_NAME } from '@shopify/const'
// 引入Message组件
import { Message } from '../RegistrationsActivate'
// 引入useUI hook
import { useUI } from '@components/ui/context'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { useProfile } from '@components/common/ProfileContext'

interface RegistrationsAuthCodeData {
  authCodeTitle?: string
  authCodeDesc?: string
  enterActiveCodeText?: string
  authCodeContent?: string
  authCodeReceiveText?: string
  sendAgainText?: string
  buttonText?: string
  note?: string
  codeEmptyError?: string
  incorrectCodeError?: string
  retrieveText?: string
  authSuccessMessage?: string
  sendEmailSuccessMessage?: string
  sendEmailErrorMessage?: string
  beenActivatedMessage?: string
  invalidCodeMessage?: string
  expiredCodeMessage?: string
}

// 定义RegistrationsAuthCodeProps接口
interface RegistrationsAuthCodeProps {
  data: RegistrationsAuthCodeData
  gs: any
  locale: string
  registerEmail: string
  onAuthCodeSuccess?: () => void
}

// 定义RegistrationsAuthCode组件
const RegistrationsAuthCode: React.FC<RegistrationsAuthCodeProps> = ({
  data,
  gs,
  locale,
  registerEmail,
  onAuthCodeSuccess,
}) => {
  // 合并styles和gs对象
  const s = { ...styles, ...gs }
  // 定义状态变量
  const [submitLoading, setSubmitLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const [messageText, setMessageText] = useState('')
  const [resendStatus, setResendStatus] = useState('')
  const [countDownTime, setCountDownTime] = useState(60)
  // 使用useUI hook
  const {
    activeToken,
    setActiveToken,
    setRegistrationsOptions,
    registrationsOptions,
  } = useUI()
  // 创建一个长度为6的空数组
  const defaultCodeArray = new Array(6).fill('')
  // 定义错误信息状态
  const [error, setError] = useState('')

  // 定义验证码数组状态
  const [codeArray, setCodeArray] = useState(defaultCodeArray)

  // 定义输入框的值状态
  const [inputValue, setInputValue] = useState('')

  // 定义输入框的ref
  const inputRef = useRef<HTMLInputElement>(null)

  // 过滤出非空的验证码数组
  const codeArrayReal = codeArray.filter((v) => v)

  const { profile } = useProfile()

  // 处理验证码提交的回调函数
  const handleAuthCodeSubmit = useCallback(async () => {
    // 验证码为空时设置错误信息
    if (!codeArrayReal?.length) {
      setError(data?.codeEmptyError || '')
      return
    }
    // 验证码长度不为6时返回
    if (codeArrayReal?.length !== 6) {
      return
    }
    // 设置提交加载状态
    setSubmitLoading(true)

    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'lp_button',
      member_active_status: profile?.activated ? 'active' : 'not active',
      event_parameters: {
        page_group: 'reg_sub_pop',
      },
      position: 'success',
      button_name: data.buttonText,
    })

    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'user_center',
      event_parameters: {
        page_group: 'login_pop',
      },
      position: 'activate_card',
      button_name: data.buttonText,
    })

    // 发起网络请求
    const res = await fetcher({
      action: 'account',
      needRecaptcha: true,
      locale,
      url: '/api/multipass/account/users/email_validation',
      method: 'POST',
      body: {
        activation_code: activeToken,
        verify_code: codeArrayReal.join(''),
      },
      // 请求完成后取消加载状态
    }).finally(() => setSubmitLoading(false))
    // 根据响应状态处理结果
    if (res.status > 100 && res.status < 300) {
      setResendStatus('success')
      setMessageText(data?.authSuccessMessage || '')
      setRegistrationsOptions({
        ...registrationsOptions,
        sendAuthCodeSuccess: true,
        button: data.buttonText,
      })
      onAuthCodeSuccess && onAuthCodeSuccess()
    } else {
      // 根据错误码设置错误信息
      if (res?.data?.error_code === '104') {
        onAuthCodeSuccess && onAuthCodeSuccess()
      } else if (res?.data?.error_code === '142') {
        setError(data?.incorrectCodeError || 'Invalid verification code.')
      } else if (res?.data?.error_code === '154') {
        setError(data?.expiredCodeMessage || '')
      } else {
        setError(data?.sendEmailErrorMessage || '')
      }
    }
    // 设置定时器清除状态信息
    let timer = setTimeout(() => {
      setResendStatus('')
      setMessageText('')
      clearTimeout(timer)
    }, 5000)
  }, [
    codeArrayReal,
    profile?.activated,
    data.buttonText,
    data?.codeEmptyError,
    data?.authSuccessMessage,
    data?.incorrectCodeError,
    data?.expiredCodeMessage,
    data?.sendEmailErrorMessage,
    locale,
    activeToken,
    setRegistrationsOptions,
    registrationsOptions,
    onAuthCodeSuccess,
  ])

  // 处理验证码输入变化的函数
  const handleInputCodeChange = (e: any) => {
    // 获取输入值并过滤非数字字符，截取前6位
    let value = e.target.value
    let tempValue = value.replace(/[^0-9]/g, '').substr(0, 6)
    // 将输入值转换为数组
    let tempArray = [...tempValue]
    // 更新验证码数组
    let tempCodeArray = codeArray.map((v, i) => (v = tempArray[i] || ''))
    setInputValue(tempValue)
    setCodeArray(tempCodeArray)
  }

  // 处理重新发送验证码的回调函数
  const handleSendAgain = useCallback(async () => {
    if (!registerEmail) return
    // 设置重新发送加载状态
    setResendLoading(true)
    const fullUrl = window.location.href
    const url = new URL(fullUrl)
    // 提取基础 URL
    const baseUrl = `${url.origin}${url.pathname}`
    // 发起网络请求
    const res = await fetcher({
      action: 'user',
      needRecaptcha: true,
      locale,
      url: '/api/multipass/account/users/send_validation_email',
      method: 'POST',
      body: {
        email: registerEmail,
        app: APP_NAME[locale],
        ...(registrationsOptions?.memberRegistration && {
          data: {
            redirect_url: baseUrl + '?ref=member_actived',
          },
        }),
      },
      // 请求完成后取消加载状态
    }).finally(() => setResendLoading(false))
    // 根据响应状态处理结果
    if (res.status > 100 && res.status < 300) {
      const { active_token } = res?.data?.data || {}
      setActiveToken(active_token)
      setResendStatus('success')
      setMessageText(data?.sendEmailSuccessMessage || '')
      // 设置定时器更新倒计时
      let timer = setInterval(() => {
        setCountDownTime((prev) => {
          if (prev <= 0) {
            clearInterval(timer)
            return 60
          }
          return prev - 1
        })
      }, 1000)
    } else {
      setResendStatus('error')
      setMessageText(data?.sendEmailErrorMessage || '')
    }
    // 设置定时器清除状态信息
    let timer = setTimeout(() => {
      setResendStatus('')
      setMessageText('')
      clearTimeout(timer)
    }, 5000)
  }, [
    locale,
    registerEmail,
    registrationsOptions?.memberRegistration,
    setActiveToken,
    data?.sendEmailSuccessMessage,
    data?.sendEmailErrorMessage,
  ])

  // 处理数字项点击事件
  const handleNumberItemClick = () => {
    inputRef?.current && inputRef?.current?.focus()
  }

  useEffect(() => {
    if (registrationsOptions?.sendAuthCodeManually) {
      handleSendAgain()
    }
  }, [handleSendAgain, registrationsOptions?.sendAuthCodeManually])

  // 渲染组件
  return (
    // 主容器
    <div
      className={cn(s.registrations_auth_code, {
        [s.disabled]: submitLoading,
      })}
    >
      {/* // 内容包裹容器 */}
      <div className={cn(s.registrations_wrap, '!pb-12')}>
        {/* // 标题区域 */}
        <div className={s.registrations_auth_code_title}>
          {/* // 标题文本 */}
          <Text className={s.rac_title} html={data?.authCodeTitle} />
          {/* // 描述文本 */}
          <Text
            className={s.rac_desc}
            html={data?.authCodeDesc?.replace('%email%', registerEmail)}
          />
        </div>
        {/* // 验证码输入区域 */}
        <div className={s.registrations_auth_code_body}>
          {/* // 输入提示文本 */}
          <Text
            className={s.rac_enter_code_text}
            html={data?.enterActiveCodeText}
          />
          {/* // 验证码数字显示区域 */}
          <div className={s.numberBox}>
            {/* // 映射验证码数组为数字项 */}
            {codeArray.map((v, i) => {
              const showBlinking = codeArray.indexOf('') === i
              return (
                // 数字项容器
                <div
                  className={cn(s.itemContent, { active: !!v })}
                  key={i}
                  onClick={handleNumberItemClick}
                >
                  {/* // 显示数字 */}
                  {v}
                  {showBlinking && <span className={cn(s.blinking)}>l</span>}
                </div>
              )
            })}
          </div>
          {/* // 输入框包裹容器 */}
          <div className={s.inputBoxWrap}>
            {/* // 验证码输入框 */}
            <input
              type="text"
              value={inputValue}
              onBlur={() => setError('')}
              onChange={handleInputCodeChange}
              pattern="[0-9]*"
              autoComplete="one-time-code"
              inputMode="numeric"
              maxLength={6}
              ref={inputRef}
            />
          </div>
          {/* // 错误信息显示 */}
          {error && <Text className={s.error} html={error} />}
          {/* // 验证码说明内容 */}
          {data?.authCodeContent && (
            <ul
              className={s.rac_content}
              dangerouslySetInnerHTML={{ __html: data?.authCodeContent }}
            />
          )}
        </div>
        {/* // 底部区域 */}
        <div className={s.registrations_auth_code_footer}>
          {/* // 接收验证码提示文本 */}
          <Text
            variant="inline"
            className={s.authCodeReceiveText}
            html={data?.authCodeReceiveText}
          />{' '}
          {/* // 倒计时文本或重新发送链接 */}
          {countDownTime && countDownTime !== 60 ? (
            <Text
              variant="inline"
              html={data?.retrieveText?.replace(
                '%countdownTime%',
                countDownTime.toString()
              )}
              className={cn(s.sendAgainText, '!text-eufy-color')}
            />
          ) : (
            <Text
              variant="inline"
              onClick={handleSendAgain}
              className={cn(s.sendAgainText, {
                ['pointer-events-none']: resendLoading,
              })}
              html={data?.sendAgainText}
            />
          )}
          {/* // 提交按钮，根据加载状态显示不同样式 */}
          {submitLoading ? (
            <button
              className={cn(
                s.rac_submit,
                s.registrations_btn,
                s.flex_center,
                s.loading_btn
              )}
            >
              {/* // 加载中的SVG图标 */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          ) : (
            // 正常状态的提交按钮
            <button
              type="submit"
              className={cn(s.rac_submit, s.registrations_btn, s.flex_center)}
              onClick={handleAuthCodeSubmit}
            >
              {data.buttonText}
            </button>
          )}
          {/* // 注释文本 */}
          <Text className={s.note} html={data?.note} />
        </div>
        {/* // 消息组件，用于显示状态信息 */}
        <Message status={resendStatus} message={messageText} />
      </div>
    </div>
  )
}

// 导出组件
export default RegistrationsAuthCode
