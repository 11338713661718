import { FC, useEffect, useState, useCallback } from 'react'
import { Logo, Button, Input } from '@components/ui'
import useLogin from '@shopify/auth/use-login'
import { useUI } from '@components/ui/context'
import { validate } from 'email-validator'
import { getQuery, funcUrlDel } from '@lib/utils/cookies'

interface Props {}

const LoginView: FC<Props> = () => {
  // Form State
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [dirty, setDirty] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const { setModalView, closeModal } = useUI()

  const login = useLogin()

  const handleLogin = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()

    if (!dirty && !disabled) {
      setDirty(true)
      handleValidation()
    }

    try {
      setLoading(true)
      setMessage('')
      await login({
        email,
        password,
      })
      const query = getQuery()
      if (query.hasOwnProperty('reset_email')) {
        const reset_url = funcUrlDel('reset_email')
        window.history.pushState({}, '', reset_url)
      }
      setLoading(false)
      closeModal()
    } catch (err) {
      setLoading(false)
      setDisabled(false)
    }
  }

  const handleValidation = useCallback(() => {
    // Test for Alphanumeric password
    const validPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(password)

    // Unable to send form unless fields are valid.
    if (dirty) {
      setDisabled(!validate(email) || password.length < 7 || !validPassword)
    }
  }, [email, password, dirty])

  useEffect(() => {
    handleValidation()
  }, [handleValidation])

  return (
    <form
      onSubmit={handleLogin}
      className="flex w-80 flex-col justify-between p-3"
    >
      <div className="flex justify-center pb-12 ">
        <Logo width="100%" height="auto" />
      </div>
      <div className="flex flex-col space-y-3">
        {message && (
          <div className="border border-red p-3 text-red">
            {message}. Did you {` `}
            <a
              className="inline cursor-pointer font-bold text-accent-9 hover:underline"
              onClick={() => setModalView('FORGOT_VIEW')}
            >
              forgot your password?
            </a>
          </div>
        )}
        <Input type="email" placeholder="Email" onChange={setEmail} />
        <Input type="password" placeholder="Password" onChange={setPassword} />
        <a
          className="cursor-pointer text-accent-5 underline"
          onClick={() => setModalView('FORGOT_VIEW')}
        >
          Forgot your password?
        </a>

        <Button
          variant="slim"
          type="submit"
          loading={loading}
          disabled={disabled}
          className="flex items-center justify-center bg-gray-900 p-3.5 text-white"
        >
          Log In
        </Button>
        <div className="pt-1 text-center text-sm">
          <span className="text-accent-7">Don&apos;t have an account?</span>
          {` `}
          <a
            className="cursor-pointer font-bold text-accent-9 hover:underline"
            onClick={() => setModalView('SIGNUP_VIEW')}
          >
            Sign Up
          </a>
        </div>
      </div>
    </form>
  )
}

export default LoginView
