import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button, Dialog, useUI } from '@components/ui'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { pageGTMEvent } from '@lib/utils/thirdparty'

interface Props {
  options?: string
  title: string
  content: string
  cancelBtn?: string
  confirmBtn?: string
  cancelUrl: string
  confirmUrl: string
  memberType: number
  monthly?: string
  annual?: string
}

const MemberTipBox = NiceModal.create(
  ({
    options,
    title,
    content,
    cancelBtn = 'cancel',
    confirmBtn = 'confirm',
    cancelUrl,
    confirmUrl,
    memberType,
    monthly,
    annual,
  }: Props) => {
    const modal = useModal()
    const { query, route } = useRouter()
    const { registrationsOptions } = useUI()

    const page_group = useMemo(() => {
      switch (route) {
        case '/':
          return 'Home Page'
        case '/[...pages]':
          return `Activity Page_${query.pages?.[0]}`
        case '/products/[slug]':
          return `Product Detail Page_${query.slug}`
        default:
          return ''
      }
    }, [query.pages, query.slug, route])

    const formatContent = useMemo(() => {
      let currentMember, purchaseMember
      if (options === 'memberCannotSuperposed') {
        currentMember = memberType === 1 ? monthly : annual
        purchaseMember = memberType === 1 ? monthly : annual
      } else {
        currentMember = memberType === 1 ? annual : monthly
        purchaseMember = memberType === 1 ? monthly : annual
      }
      return content
        .replace('${currentMember}', currentMember || '')
        .replace('${purchaseMember}', purchaseMember || '')
    }, [annual, content, memberType, monthly, options])

    const confirm = () => {
      if (confirmUrl) {
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'begin_checkout',
          event_parameters: {
            page_group,
          },
          position: registrationsOptions?.checkout
            ? 'Cart Checkout'
            : 'Card Pop Up',
          button_name: registrationsOptions?.button,
          currency: registrationsOptions?.cart?.currency?.code,
          value: registrationsOptions?.cart?.totalPrice,
          items: registrationsOptions?.cart?.lineItems?.map((item: any) => ({
            item_id: item.variant?.sku, //产品SKU
            item_name: item.product?.title,
            item_brand: process.env.NEXT_PUBLIC_BRAND || 'eufy',
            item_variant: item.variant.name,
            price: item.totalAmount,
            quantity: item.quantity | 1,
          })),
        })
        location.href = confirmUrl
      }
      modal.remove()
    }

    const cancel = () => {
      if (cancelUrl) {
        location.href = cancelUrl
      }
      modal.remove()
    }

    const close = () => {
      if (cancelUrl) {
        location.href = cancelUrl
      }
      modal.remove()
    }
    return (
      <Dialog className="w-[92vw] max-w-[540px]" onClose={() => close()}>
        <div className="text-center">
          <h3
            className="text-2xl font-bold !leading-[1.2] text-[#2d2d2d]"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h3>
          <p
            className="mt-3 text-base font-medium !leading-[1.2] text-[#333]"
            dangerouslySetInnerHTML={{ __html: formatContent }}
          ></p>
          {options === 'memberCannotSuperposed' ? (
            <div className="mt-6">
              <Button
                className=" min-w-[151px] !bg-[#333]"
                onClick={() => confirm()}
              >
                {confirmBtn}
              </Button>
            </div>
          ) : (
            <div className="mt-6 flex gap-6">
              <Button
                variant="secondary"
                className="w-1/2"
                onClick={() => cancel()}
              >
                {cancelBtn}
              </Button>
              <Button className="w-1/2 !bg-[#333]" onClick={() => confirm()}>
                {confirmBtn}
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    )
  }
)
export default MemberTipBox
