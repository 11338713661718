import React, { AnchorHTMLAttributes, FC, useMemo } from 'react'
import { useRouter } from 'next/router'
import { handleLocaleLink } from '@lib/utils/tools'

interface LinkProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  href?: string
  params?: {
    label: string
    val: string
  }
}

const Link: FC<LinkProps> = ({
  href,
  children,
  params = { label: '', val: '' },
  ...rest
}) => {
  const { locale } = useRouter()

  // 使用 useMemo 避免在每次组件渲染时都重新计算 localizedHref
  const localizedHref = useMemo(() => {
    return href ? handleLocaleLink({ href, locale, ...params }) : ''
  }, [href, locale, params])

  // 当使用 dangerouslySetInnerHTML 时，不渲染 children，避免不必要的元素创建
  const content = rest.dangerouslySetInnerHTML ? null : children

  return href ? (
    <a href={localizedHref} {...rest}>
      {content}
    </a>
  ) : (
    <a {...rest}>{content}</a>
  )
}

export default Link
