import { useEffect } from 'react'
import { useModal } from '@ebay/nice-modal-react'
import { useRouter } from 'next/router'

/**
 * 用户召回礼品卡弹窗
 * @param param0
 */
export default function useShowGiftCardModal({ giftCardPop, giftCard }: any) {
  const giftCardModal = useModal('gift-card-modal') // 使用useModal钩子获取礼品卡模态框
  const { query } = useRouter()

  useEffect(() => {
    const paramsRules = giftCard?.params // 从礼品卡属性中解构出params属性
    const modalShouldShow =
      Array.isArray(paramsRules) &&
      paramsRules.some((rule) => {
        const { label, val } = rule
        return query[label] === val
      })
    if (modalShouldShow) {
      const paramsRule = paramsRules.find(
        ({ label, val }) => query[label] === val
      )
      const initEmail =
        query[paramsRules.find(({ val }) => val.includes('*'))?.label]
      // 展示礼品卡模态框，并传入相关参数
      giftCardModal.show({
        // 使用展开操作符将giftCardPop对象的属性包含进来
        ...giftCardPop,
        // 根据paramsRule的存在来决定是否添加genre属性
        genre: paramsRule?.genre,
        // 根据paramsRule的存在来决定是否添加customerTag属性
        customerTag: paramsRule?.customerTag,
        // 初始化时传入的电子邮件地址
        initEmail,
      })
    }
  }, [giftCard?.params, giftCardPop, giftCardModal, query, giftCard])
}
