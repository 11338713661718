import { useState } from 'react'
import cn from 'classnames'
import Icon from '@components/icons'
import Modal from 'react-modal'
import { Text } from '@components/ui'
import { Close } from '@components/icons/next'
import styles from './ProductIconBar.module.css'

const ProductIconBar = ({
  className,
  iconBars,
  direction,
  s: gs = {},
  interaction = true,
}) => {
  const [txtModal, setTxtModal] = useState('')
  const s = { ...styles, ...gs }
  return (
    <>
      <div className={cn(className, s.iconBars)}>
        {iconBars?.length > 0 &&
          iconBars.map((bar, index) =>
            interaction ? (
              <button
                className={cn(s.iconBar, {
                  [s.rightIconButton]: direction === 'right',
                })}
                key={index}
                onClick={() => {
                  setTxtModal(bar.txtModal)
                }}
              >
                <Icon iconKey={bar.icon} />
                <Text html={bar.text} className={s.barText} />
              </button>
            ) : (
              <div
                className={cn(s.iconBar, {
                  [s.rightIconButton]: direction === 'right',
                })}
                key={index}
              >
                <Icon iconKey={bar.icon} className={s.icon} />
                <Text html={bar.text} className={s.barText} />
              </div>
            )
          )}
      </div>
      <Modal
        isOpen={!!txtModal}
        onRequestClose={() => {
          setTxtModal('')
        }}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="coupon-faq"
        ariaHideApp={false}
      >
        <Close
          className={s.closeBtn}
          onClick={() => {
            setTxtModal('')
          }}
        />
        <Text html={txtModal} />
      </Modal>
    </>
  )
}

export default ProductIconBar
