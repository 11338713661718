import React, { FC, useCallback, useMemo } from 'react'
import { ThemeProvider } from 'next-themes'
import { pageGTMEvent } from '@lib/utils/thirdparty'

export interface State {
  combinationOpt: Array<string>
  combinationImg: Array<string>
  displaySidebar: boolean
  displayDropdown: boolean
  displayModal: boolean
  sidebarView: string
  productSwiperIndex: number
  modalView: string
  userAvatar: string
  showBundlePopup: boolean
  recaptchaReady: boolean
  showRegistrationsPopup: boolean
  registrationsDealsType: string
  registrationsModelStatus: string
  registrationsOptions: {
    sendAuthCodeManually?: boolean
    sendAuthCodeSuccess?: boolean
    loginRedirectUrl?: string
    cart?: any
    checkout?: boolean
    memberRegistration?: boolean
    monthlyPlus?: boolean
    annualPlus?: boolean
    button?: string
  }
  registrationsSignSuccessCallback: () => void
  activeToken: string
  customerServiceType: string
  gtmReady: boolean
  menuTheme: boolean | string
  tipModal: string
  previewData: any
  /**
   * modal 需要的配置数据
   */
  modalProps: any
  /**
   * 自定义modal 样式
   */
  modalClassName: string
  selectedOptions: any
  leftBarShow: boolean
  sellingPlanId: string
  cartSidebarMutate: () => void
}

const initialState = {
  previewData: {},
  combinationOpt: [],
  selectedOptions: {},
  combinationImg: [],
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  productSwiperIndex: 0,
  userAvatar: '',
  recaptchaReady: false,
  gtmReady: false,
  tipModal: '',
  showBundlePopup: false,
  menuTheme: false,
  isQuiz: false,
  isQuizDialog: false,
  isSignupDialog: false,
  showRegistrationsPopup: false,
  registrationsDealsType: '',
  registrationsModelStatus: '',
  registrationsOptions: null,
  registrationsSignSuccessCallback: null,
  activeToken: '',
  modalProps: {},
  modalClassName: '',
  leftBarShow: false,
  sellingPlanId: '',
  customerServiceType: '',
  cartSidebarMutate: null,
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_MODAL'
      view?: MODAL_VIEWS
      modalProps?: any
      modalClassName?: string
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
      modalProps: any
    }
  | {
      type: 'SET_SWIPER_INDEX'
      view: number
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
    }
  | {
      type: 'SET_COMBINATION'
      value: Array<string>
    }
  | {
      type: 'SET_COMBINATION_IMG'
      value: Array<string>
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }
  | {
      type: 'SET_RECAPTCHA'
      value: boolean
    }
  | {
      type: 'SET_GTM'
      value: boolean
    }
  | {
      type: 'SET_TIP_MODAL'
      value: string
    }
  | {
      type: 'SET_BUNDLE_POPUP'
      value: boolean
    }
  | {
      type: 'SET_MENU_THEME'
      value: boolean
    }
  | {
      type: 'SET_IS_QUIZ'
      value: boolean
    }
  | {
      type: 'SET_IS_QUIZ_DIALOG'
      value: boolean
    }
  | {
      type: 'SET_IS_SIGNUP_DIALOG'
      value: boolean
    }
  | {
      type: 'SET_PREVIEW_DATA'
      value: any
    }
  | {
      type: 'SET_REGISTRATIONS_POPUP'
      value: any
    }
  | {
      type: 'SET_REGISTRATIONS_DEALS_TYPE'
      value: any
    }
  | {
      type: 'SET_REGISTRATIONS_MODEL_STATUS'
      value: any
    }
  | {
      type: 'SET_REGISTRATIONS_OPTIONS'
      value: any
    }
  | {
      type: 'SET_REGISTRATIONS_SIGN_SUCCESS_CALLBACK'
      value: any
    }
  | {
      type: 'SET_OPTIONS_DATA'
      value: any
    }
  | {
      type: 'SET_LEFT_BAR_SHOW'
      value: any
    }
  | {
      type: 'SET_SELLING_PLAN_ID'
      value: string
    }
  | {
      type: 'SET_CUSTOMER_SERVICE'
      view: CUSTOMER_SERVICE_TYPE
    }
  | {
      type: 'SET_ACTIVE_TOKEN'
      value: any
    }
  | {
      type: 'SET_CART_SIDEBAR_MUTATE'
      value: any
    }

type MODAL_VIEWS =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGOT_VIEW'
  | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'
  | 'BLOG_BANNER_VIEW'

type CUSTOMER_SERVICE_TYPE =
  | 'ComponentLiveChat'
  | 'ComponentABM'
  | 'ComponentFirework'

type SIDEBAR_VIEWS = 'CART_VIEW' | 'CHECKOUT_VIEW' | 'PAYMENT_METHOD_VIEW'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        modalView: action.view,
        ...(action.modalProps && { modalProps: action.modalProps }),
        modalClassName: action.modalClassName,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
        ...(action.modalProps && { modalProps: action.modalProps }),
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.view,
      }
    }
    case 'SET_SWIPER_INDEX': {
      return {
        ...state,
        productSwiperIndex: action.view,
      }
    }
    case 'SET_COMBINATION': {
      return {
        ...state,
        combinationOpt: action.value,
      }
    }
    case 'SET_COMBINATION_IMG': {
      return {
        ...state,
        combinationImg: action.value,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'SET_RECAPTCHA': {
      return {
        ...state,
        recaptchaReady: action.value,
      }
    }
    case 'SET_GTM': {
      return {
        ...state,
        gtmReady: action.value,
      }
    }
    case 'SET_TIP_MODAL': {
      return {
        ...state,
        tipModal: action.value,
      }
    }
    case 'SET_BUNDLE_POPUP': {
      return {
        ...state,
        showBundlePopup: action.value,
      }
    }
    case 'SET_MENU_THEME': {
      return {
        ...state,
        menuTheme: action.value,
      }
    }
    case 'SET_IS_QUIZ': {
      return {
        ...state,
        isQuiz: action.value,
        isQuizDialog: action.value,
      }
    }
    case 'SET_IS_QUIZ_DIALOG': {
      return {
        ...state,
        isQuizDialog: action.value,
      }
    }
    case 'SET_IS_SIGNUP_DIALOG': {
      return {
        ...state,
        isSignupDialog: action.value,
      }
    }
    case 'SET_REGISTRATIONS_POPUP': {
      return {
        ...state,
        showRegistrationsPopup: action.value,
      }
    }
    case 'SET_REGISTRATIONS_DEALS_TYPE': {
      return {
        ...state,
        registrationsDealsType: action.value,
      }
    }
    case 'SET_REGISTRATIONS_MODEL_STATUS': {
      return {
        ...state,
        registrationsModelStatus: action.value,
      }
    }
    case 'SET_REGISTRATIONS_OPTIONS': {
      return {
        ...state,
        registrationsOptions: action.value,
      }
    }
    case 'SET_REGISTRATIONS_SIGN_SUCCESS_CALLBACK': {
      return {
        ...state,
        registrationsSignSuccessCallback: action.value,
      }
    }
    case 'SET_PREVIEW_DATA': {
      return {
        ...state,
        previewData: action.value,
      }
    }
    case 'SET_OPTIONS_DATA': {
      return {
        ...state,
        selectedOptions: action.value,
      }
    }
    case 'SET_LEFT_BAR_SHOW': {
      return {
        ...state,
        leftBarShow: action.value,
      }
    }
    case 'SET_SELLING_PLAN_ID': {
      return {
        ...state,
        sellingPlanId: action.value,
      }
    }
    case 'SET_CUSTOMER_SERVICE': {
      return {
        ...state,
        customerServiceType: action.view,
      }
    }
    case 'SET_ACTIVE_TOKEN': {
      return {
        ...state,
        activeToken: action.value,
      }
    }
    case 'SET_CART_SIDEBAR_MUTATE': {
      return {
        ...state,
        cartSidebarMutate: action.value,
      }
    }
  }
}

export const UIProvider: FC<any> = (props: any) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openSidebar = useCallback(() => {
    pageGTMEvent({
      event: 'cart_recommend',
    })
    return dispatch({ type: 'OPEN_SIDEBAR' })
  }, [dispatch])
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  )
  const toggleSidebar = useCallback(() => {
    if (!state.displaySidebar) {
      pageGTMEvent({
        event: 'cart_recommend',
      })
    }
    return state.displaySidebar
      ? dispatch({ type: 'CLOSE_SIDEBAR' })
      : dispatch({ type: 'OPEN_SIDEBAR' })
  }, [dispatch, state.displaySidebar])
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )

  const openDropdown = useCallback(
    () => dispatch({ type: 'OPEN_DROPDOWN' }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: 'CLOSE_DROPDOWN' }),
    [dispatch]
  )
  /**
   * 打开模态框
   * @param view 模态框id
   * @param modalProps 模态框配置数据
   * @param modalClassName 自定义模态框样式
   */
  const openModal = useCallback(
    (view?: MODAL_VIEWS, modalProps?: any, modalClassName?: string) =>
      dispatch({ type: 'OPEN_MODAL', view, modalProps, modalClassName }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
    [dispatch]
  )
  const setRecaptcha = useCallback(
    (value: boolean) => dispatch({ type: 'SET_RECAPTCHA', value }),
    [dispatch]
  )
  const setGTM = useCallback(
    (value: boolean) => dispatch({ type: 'SET_GTM', value }),
    [dispatch]
  )
  const setTipModal = useCallback(
    (value: string) => dispatch({ type: 'SET_TIP_MODAL', value }),
    [dispatch]
  )
  const setCombination = useCallback(
    (value: Array<string>) => dispatch({ type: 'SET_COMBINATION', value }),
    [dispatch]
  )
  const setCombinationImg = useCallback(
    (value: Array<string>) => dispatch({ type: 'SET_COMBINATION_IMG', value }),
    [dispatch]
  )
  const setBundlePopup = useCallback(
    (value: boolean) => dispatch({ type: 'SET_BUNDLE_POPUP', value }),
    [dispatch]
  )

  const setModalView = useCallback(
    (view: MODAL_VIEWS, modalProps: any = {}) =>
      dispatch({ type: 'SET_MODAL_VIEW', view, modalProps }),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => dispatch({ type: 'SET_SIDEBAR_VIEW', view }),
    [dispatch]
  )

  const setSwiperIndex = useCallback(
    (view: number) => dispatch({ type: 'SET_SWIPER_INDEX', view }),
    [dispatch]
  )

  const setMenuTheme = useCallback(
    (value: boolean) => dispatch({ type: 'SET_MENU_THEME', value }),
    [dispatch]
  )

  const setIsQuiz = useCallback(
    (value: boolean) => dispatch({ type: 'SET_IS_QUIZ', value }),
    [dispatch]
  )

  const setIsQuizDialog = useCallback(
    (value: boolean) => dispatch({ type: 'SET_IS_QUIZ_DIALOG', value }),
    [dispatch]
  )

  const setIsSignupDialog = useCallback(
    (value: boolean) => dispatch({ type: 'SET_IS_SIGNUP_DIALOG', value }),
    [dispatch]
  )

  const setPreviewData = useCallback(
    (value: boolean) => dispatch({ type: 'SET_PREVIEW_DATA', value }),
    [dispatch]
  )

  const setRegistrationsPopup = useCallback(
    (value: boolean) => dispatch({ type: 'SET_REGISTRATIONS_POPUP', value }),
    [dispatch]
  )

  const setRegistrationsDealsType = useCallback(
    (value: boolean) =>
      dispatch({ type: 'SET_REGISTRATIONS_DEALS_TYPE', value }),
    [dispatch]
  )

  const setRegistrationsModelStatus = useCallback(
    (value: boolean) =>
      dispatch({ type: 'SET_REGISTRATIONS_MODEL_STATUS', value }),
    [dispatch]
  )

  const setRegistrationsOptions = useCallback(
    (value: boolean) => dispatch({ type: 'SET_REGISTRATIONS_OPTIONS', value }),
    [dispatch]
  )

  const setRegistrationsSignSuccessCallback = useCallback(
    (value: boolean) =>
      dispatch({ type: 'SET_REGISTRATIONS_SIGN_SUCCESS_CALLBACK', value }),
    [dispatch]
  )

  const setSelectedOptions = useCallback(
    (value: boolean) => dispatch({ type: 'SET_OPTIONS_DATA', value }),
    [dispatch]
  )

  const setMobLeftBarShow = useCallback(
    (value: boolean) => dispatch({ type: 'SET_LEFT_BAR_SHOW', value }),
    [dispatch]
  )

  const setSellingPlanId = useCallback(
    (value: string) => dispatch({ type: 'SET_SELLING_PLAN_ID', value }),
    [dispatch]
  )

  const setCustomerServiceType = useCallback(
    (view: CUSTOMER_SERVICE_TYPE) =>
      dispatch({ type: 'SET_CUSTOMER_SERVICE', view }),
    [dispatch]
  )

  const setActiveToken = useCallback(
    (value: string) => dispatch({ type: 'SET_ACTIVE_TOKEN', value }),
    [dispatch]
  )

  const setCartSidebarMutate = useCallback(
    (value: boolean) => dispatch({ type: 'SET_CART_SIDEBAR_MUTATE', value }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
      setSwiperIndex,
      setUserAvatar,
      setRecaptcha,
      setGTM,
      setTipModal,
      setCombination,
      setCombinationImg,
      setBundlePopup,
      setMenuTheme,
      setIsQuiz,
      setIsQuizDialog,
      setIsSignupDialog,
      setPreviewData,
      setRegistrationsPopup,
      setRegistrationsDealsType,
      setRegistrationsModelStatus,
      setRegistrationsOptions,
      setRegistrationsSignSuccessCallback,
      setSelectedOptions,
      setMobLeftBarShow,
      setSellingPlanId,
      setCustomerServiceType,
      setActiveToken,
      setCartSidebarMutate,
    }),
    [
      state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
      setSwiperIndex,
      setUserAvatar,
      setRecaptcha,
      setGTM,
      setTipModal,
      setCombination,
      setCombinationImg,
      setBundlePopup,
      setMenuTheme,
      setIsQuiz,
      setIsQuizDialog,
      setIsSignupDialog,
      setPreviewData,
      setRegistrationsPopup,
      setRegistrationsDealsType,
      setRegistrationsModelStatus,
      setRegistrationsOptions,
      setRegistrationsSignSuccessCallback,
      setSelectedOptions,
      setMobLeftBarShow,
      setSellingPlanId,
      setCustomerServiceType,
      setActiveToken,
      setCartSidebarMutate,
    ]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext: FC = ({ children }: any) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
