// 引入UI组件中的Text组件
import { Text } from '@components/ui'
// 引入图标组件
import Icon from '@components/icons'
// 引入React的useEffect和useState钩子
import { useEffect, useState } from 'react'

// 定义RegistrationsAuthCodeSuccessProps接口，用于类型检查
interface RegistrationsAuthCodeSuccessProps {
  data: RegistrationsAuthCodeSuccess
  gs: any
  onLoadingAuthState?: () => void
}

interface RegistrationsAuthCodeSuccess {
  title?: string
  subtitle?: string
  countDownTime?: number
}

// 定义RegistrationsAuthCodeSuccess组件
const RegistrationsAuthCodeSuccess: React.FC<
  RegistrationsAuthCodeSuccessProps
> = ({ data, gs, onLoadingAuthState }) => {
  // 使用useState管理倒计时时间
  const [countDownTime, setCountDownTime] = useState<number>(
    data?.countDownTime || 5
  )
  // 复制gs对象
  const s = { ...gs }

  // 使用useEffect处理倒计时逻辑
  useEffect(() => {
    let timer = setInterval(() => {
      setCountDownTime((prev) => {
        if (prev <= 0) {
          clearTimeout(timer)
          return data?.countDownTime || 5
        }
        return prev - 1
      })
    }, 1000)
  }, [data?.countDownTime])

  useEffect(() => {
    if (countDownTime <= 0) {
      onLoadingAuthState && onLoadingAuthState()
    }
  }, [countDownTime, onLoadingAuthState])

  // 渲染组件
  return (
    <div className={s.registrations_auth_code_success}>
      <div className="relative mx-6 rounded-2xl bg-white p-4 min-l:mx-0 min-l:min-w-[400px]">
        <div className="pt-8 text-center">
          <div className="mx-auto h-[64px] w-[64px]">
            {/* // 使用Icon组件显示图标 */}
            <Icon iconKey={'rightSuccess'} width={64} height={64}></Icon>
          </div>
          {/* // 显示标题文本 */}
          <Text
            className="mt-4 text-[20px] font-semibold leading-[1.2] text-[#333]"
            html={data?.title}
          />
          {/* // 显示副标题文本，并换其中的%time%为倒计时时间 */}
          <Text
            className="mt-2 text-[14px] font-medium leading-[1.4] text-[#999] [&_span]:text-[#FAAD15]"
            html={data?.subtitle?.replace('%time%', countDownTime?.toString())}
          />
        </div>
      </div>
    </div>
  )
}

// 导出RegistrationsAuthCodeSuccess组件
export default RegistrationsAuthCodeSuccess
