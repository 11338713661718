import dayjs from 'dayjs'
import { useMemo } from 'react'

/**
 * 根据优先级返回第一个可用的新人券
 * @param param0
 * @returns
 */
export default function useNewUserCoupon({
  coupons = [],
  couponPriority = [],
}: {
  coupons: any[]
  couponPriority: string[]
}) {
  // 优化了条件判断逻辑，减少了代码复杂度

  const availableCoupon = useMemo(() => {
    let res: any = null
    couponPriority.forEach((priority) =>
      coupons.forEach((coupon) => {
        if (
          Number(coupon.value) === Number(priority) &&
          coupon.usage_count === 0 &&
          (!coupon.expired_at || dayjs().isBefore(dayjs(coupon.expired_at)))
        ) {
          if (res === null) res = coupon
        }
      })
    )
    return res
  }, [couponPriority, coupons])

  return availableCoupon
}
