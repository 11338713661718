import metafieldsItems from './metafields-items-query'

const getProductQuery = /* GraphQL */ `
  query getProductBySlug($slug: String!) {
    productByHandle(handle: $slug) {
      id
      handle
      availableForSale
      title
      productType
      vendor
      description
      descriptionHtml
      onlineStoreUrl
      publishedAt
      totalInventory
      tags
      seo {
        title
        description
      }
      options {
        id
        name
        values
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      requiresSellingPlan
      sellingPlanGroups(first: 250) {
        edges {
          node {
            name
            sellingPlans(first: 250) {
              edges {
                node {
                  id
                  name
                  priceAdjustments {
                    adjustmentValue {
                      ... on SellingPlanFixedAmountPriceAdjustment {
                        __typename
                        adjustmentAmount {
                          amount
                          currencyCode
                        }
                      }
                      ... on SellingPlanFixedPriceAdjustment {
                        __typename
                        price {
                          amount
                          currencyCode
                        }
                      }
                      ... on SellingPlanPercentagePriceAdjustment {
                        __typename
                        adjustmentPercentage
                      }
                    }
                  }
                  options {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
      variants(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            title
            sku
            barcode
            availableForSale
            requiresShipping
            quantityAvailable
            currentlyNotInStock
            selectedOptions {
              name
              value
            }
            image {
              altText
              url
              width
              height
            }
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            weight
            sellingPlanAllocations(first: 10) {
              edges {
                node {
                  sellingPlan {
                    id
                    name
                    options {
                      name
                      value
                    }
                  }
                  priceAdjustments {
                    compareAtPrice {
                      amount
                      currencyCode
                    }
                    perDeliveryPrice {
                      amount
                      currencyCode
                    }
                    price {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
            MF_presell: metafield(namespace: "global", key: "presell") {
							${metafieldsItems}
            }
            MF_cartInfo: metafield(namespace: "cart", key: "info") {
							${metafieldsItems}
            }
            MF_image: metafield(namespace: "banner", key: "image") {
							${metafieldsItems}
            }
            MF_images: metafield(namespace: "variant", key: "images") {
							${metafieldsItems}
            }
            MF_infos: metafield(namespace: "variant", key: "infos") {
							${metafieldsItems}
            }
            MF_logistics: metafield(namespace: "variant", key: "logistics") {
							${metafieldsItems}
            }
            MF_variantSurprisePack: metafield(namespace: "variant", key: "surprisePack") {
              ${metafieldsItems}
            }
            MF_variantAccessories: metafield(namespace: "variant", key: "accessories") {
              ${metafieldsItems}
            }
            MF_discounts: metafield(namespace: "discounts", key: "discounts") {
							${metafieldsItems}
            }
            MF_gallery: metafield(namespace: "variant", key: "gallery") {
              ${metafieldsItems}
            }
            MF_variantTags: metafield(namespace: "variant", key: "tag") {
              ${metafieldsItems}
            }
            MF_memberBenefits: metafield(namespace: "activity", key: "memberBenefits") {
              ${metafieldsItems}
            }
            MF_exchangePurchase: metafield(namespace: "scriptDiscount", key: "exchangePurchase") {
              ${metafieldsItems}
            }
            MF_freeGift: metafield(namespace: "free_gift", key: "freeGift") {
              ${metafieldsItems}
            }
            MF_presellDesc: metafield(namespace: "global", key: "presellDesc") {
							${metafieldsItems}
            }
            MF_quantityLevelDiscount: metafield(namespace: "qty_level", key: "quantity_level_discount") {
							${metafieldsItems}
            }
            MF_memberPrice: metafield(namespace: "member", key: "price") {
							${metafieldsItems}
            }
            MF_bundleSuiteSales: metafield(namespace: "bundle", key: "bundle_suite_sales") {
            				${metafieldsItems}
			      }
            MF_coupons: metafield(namespace: "__discountCodeApp", key: "data") {
							${metafieldsItems}
            }
            MF_memberPriceV2: metafield(namespace: "global", key: "eufy_member_price") {
							${metafieldsItems}
            }
            MF_hideShipping: metafield(namespace: "global", key: "HideShipping") {
              ${metafieldsItems}
            }
          }
        }
      }
      images(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            originalSrc
            altText
            width
            height
          }
        }
      }
      MF_pageType: metafield(namespace: "pageType", key: "category") {
        ${metafieldsItems}
      }
      MF_seoSetting: metafield(namespace: "seo", key: "setting") {
        ${metafieldsItems}
      }
      MF_preRender: metafield(namespace: "build", key: "preRender") {
        ${metafieldsItems}
      }
      MF_relatedProducts: metafield(namespace: "relatedSource", key: "products") {
        ${metafieldsItems}
      }
      MF_template: metafield(namespace: "template", key: "name") {
        ${metafieldsItems}
      }
      MF_navSetting: metafield(namespace: "nav", key: "setting") {
        ${metafieldsItems}
      }
      MF_topBar: metafield(namespace: "product", key: "topbar") {
        ${metafieldsItems}
      }
      MF_gallery: metafield(namespace: "product", key: "gallery") {
        ${metafieldsItems}
      }
      MF_productType: metafield(namespace: "product", key: "type") {
        ${metafieldsItems}
      }
      MF_shopifyDescription: metafield(
        namespace: "product"
        key: "shopifyDescription"
      ) {
        ${metafieldsItems}
      }
      MF_productInfos: metafield(namespace: "product", key: "infos") {
        ${metafieldsItems}
      }
      MF_combination: metafield(namespace: "Bundle", key: "combination") {
        ${metafieldsItems}
      }
      MF_recommendations: metafield(namespace: "Bundle", key: "recommendations") {
        ${metafieldsItems}
      }
      MF_productPackage: metafield(namespace: "product", key: "package") {
        ${metafieldsItems}
      }
      MF_productAccessories: metafield(namespace: "product", key: "accessories") {
        ${metafieldsItems}
      }
      MF_productSurprisePack: metafield(namespace: "product", key: "surprisePack") {
				${metafieldsItems}
      }
      MF_productGift: metafield(namespace: "product", key: "gift") {
				${metafieldsItems}
      }
      MF_productGiftPopUps: metafield(namespace: "product", key: "giftPopUps") {
        ${metafieldsItems}
      }
      MF_bundleProduct: metafield(namespace: "relatedSource", key: "bundleProduct") {
        ${metafieldsItems}
      }
      MF_orderLimits: metafield(namespace: "settings", key: "orderLimits") {
        ${metafieldsItems}
      }
      MF_component_order: metafield(namespace: "component", key: "_order") {
        ${metafieldsItems}
      }
      MF_aplus_order: metafield(namespace: "aplus", key: "_order") {
        ${metafieldsItems}
      }
      MF_judgemeBadge: metafield(namespace: "judgeme", key: "badge") {
        ${metafieldsItems}
      }
      MF_judgemeWidget: metafield(namespace: "judgeme", key: "widget") {
        ${metafieldsItems}
      }
      MF_content: metafield(namespace: "pages", key: "content") {
        ${metafieldsItems}
      }
      MF_discounts: metafield(namespace: "discounts", key: "discounts") {
        ${metafieldsItems}
      }
      MF_productNav: metafield(namespace: "product", key: "nav") {
        ${metafieldsItems}
      }
      MF_optionsData: metafield(namespace: "product", key: "options") {
        ${metafieldsItems}
      }
      MF_popCompareData: metafield(namespace: "product", key: "popCompareData") {
        ${metafieldsItems}
      }
      MF_yotpoReviewsCount: metafield(namespace: "yotpo", key: "reviews_count") {
        ${metafieldsItems}
      }
      MF_yotpoReviewsAverage: metafield(namespace: "yotpo", key: "reviews_average") {
        ${metafieldsItems}
      }
      MF_pageCommon: metafield(namespace: "page", key: "common") {
        ${metafieldsItems}
      }
      MF_liveHelper: metafield(namespace: "global", key: "live-helper") {
        ${metafieldsItems}
      }
      MF_giftCard: metafield(namespace: "global", key: "giftCard") {
        ${metafieldsItems}
      }
      MF_hideSearch: metafield(namespace: "global", key: "hideSearch") {
        ${metafieldsItems}
      }
      MF_showNewPop: metafield(namespace: "global", key: "showNewPop") {
        ${metafieldsItems}
      }
      MF_productData: metafield(namespace: "product", key: "data") {
        ${metafieldsItems}
      }
      MF_productVideo: metafield(namespace: "global", key: "ProductVideo") {
        ${metafieldsItems}
      }
      MF_user_reviews_video: metafield(namespace: "global", key: "user_reviews_video") {
        ${metafieldsItems}
      }
      MF_listing_compare: metafield(namespace: "global", key: "listing_compare") {
        ${metafieldsItems}
      }
      MF_specs: metafield(namespace: "global", key: "specs") {
        ${metafieldsItems}
      }
      MF_faqs: metafield(namespace: "global", key: "faqs") {
        ${metafieldsItems}
      }
      MF_howtoVideo: metafield(namespace: "global", key: "howtoVideo") {
        ${metafieldsItems}
      }
      MF_quick_intro: metafield(namespace: "global", key: "quick_intro") {
        ${metafieldsItems}
      }
      MF_standard_schema: metafield(namespace: "global", key: "standard_schema") {
        ${metafieldsItems}
      }
      MF_listing_paragraph: metafield(namespace: "global", key: "listing_paragraph") {
        ${metafieldsItems}
      }
      MF_custom_variants: metafield(namespace: "global", key: "custom_variants") {
        ${metafieldsItems}
      }
      MF_slickContainer: metafield(namespace: "global", key: "slickContainer") {
        ${metafieldsItems}
      }
      MF_highlights: metafield(namespace: "global", key: "highlights") {
        ${metafieldsItems}
      } 
      MF_downloads: metafield(namespace: "global", key: "downloads") {
        ${metafieldsItems}
      }  
      MF_inbox: metafield(namespace: "global", key: "inbox") {
        ${metafieldsItems}
      }
      MF_liveStream: metafield(namespace: "global", key: "live_stream") {
		    ${metafieldsItems}
      }
      MF_listingReferralCode: metafield(namespace: "global", key: "listingReferralCode") {
      	${metafieldsItems}
      }
      MF_subscription_package_no: metafield(namespace: "global", key: "subscription_package_no") {
		    ${metafieldsItems}
      }
      MF_source_channel: metafield(namespace: "global", key: "source_channel") {
				${metafieldsItems}
      }
      MF_fw_ava_id: metafield(namespace: "global", key: "fw_ava_id") {
				${metafieldsItems}
      }
      MF_tags: metafield(namespace: "listing", key: "tags") {
				${metafieldsItems}
      }
      MF_listingReferralCode: metafield(namespace: "global", key: "listingReferralCode") {
				${metafieldsItems}
      }
      MF_hideFooterRegister: metafield(namespace: "global", key: "hideFooterRegister") {
        ${metafieldsItems}
      }
      MF_guarantee: metafield(namespace: "global", key: "guarantee") {
        ${metafieldsItems}
      }
      MF_productSubscribe: metafield(namespace: "global", key: "subscribe") {
        ${metafieldsItems}
      }
      MF_productExtInfo: metafield(namespace: "product_ext_info", key: "bulling_point") {
        ${metafieldsItems}
      }
      MF_accessories: metafield(namespace: "accessories", key: "related_products") {
        ${metafieldsItems}
      }
    }
  }
`

export default getProductQuery
