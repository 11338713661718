const features = {
  us: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: false,
    showCheckoutBar: true,
    isEuLocale: false,
    shortLocale: 'us',
    sourceDataLocale: 'us',
    title: 'eufy US',
    storefrontToken: '514cde4d8a13415e84d9bcb7a612e974',
    betaStorefrontToken: '47b1aa2c0797043f9baba39388029d70',
    countryName: 'United States',
    continent: 'North America',
    lang: 'English',
  },
  uk: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'uk',
    sourceDataLocale: 'uk',
    title: 'eufy UK',
    storefrontToken: '5a356838db3106d26f0ee681508051c1',
    betaStorefrontToken: 'd69a4a663a06126228d25a9c7d0f8fe8',
    countryName: 'United Kingdom',
    continent: 'Europe',
    lang: 'English',
  },
  ca: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: false,
    shortLocale: 'ca',
    sourceDataLocale: 'ca',
    title: 'eufy Canada',
    storefrontToken: 'a6647c2de7c6b18ab8f5eaa26542e6e6',
    betaStorefrontToken: '4113baf4a9a11877d747e5b604bf5583',
    countryName: 'Canada',
    continent: 'North America',
    lang: 'English',
  },
  'eu-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'eu',
    sourceDataLocale: 'eu-en',
    title: 'eufy Europe',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Europe',
    continent: 'Europe',
    lang: 'English',
  },
  'eu-de': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'de',
    sourceDataLocale: 'eu-de',
    title: 'eufy Deutschland',
    storefrontToken: '022e7f49d4b7ceb3eec3b1a60b9a5539',
    betaStorefrontToken: 'af2892e214f9896c072716d7303f0325',
    countryName: 'Deutschland',
    continent: 'Europe',
    lang: 'Deutsch',
  },
  'eu-fr': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'fr',
    sourceDataLocale: 'eu-fr',
    title: 'eufy France',
    storefrontToken: '9f8692257f5c35b10296685b6ed05c78',
    betaStorefrontToken: '90ea124bb53aad67f1d63e0223ad3ac3',
    countryName: 'France',
    continent: 'Europe',
    lang: 'Français',
  },
  'eu-it': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'it',
    sourceDataLocale: 'eu-it',
    title: 'eufy Italia',
    storefrontToken: '162d71ab87720c4754345cfd291b6389',
    betaStorefrontToken: 'bc47bcbded4df1c5c3d6019f66d5f05e',
    countryName: 'Italia',
    continent: 'Europe',
    lang: 'Italiano',
  },
  'eu-es': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'es',
    sourceDataLocale: 'eu-es',
    title: 'eufy España',
    storefrontToken: '1b6977db04cf67fb31adfaf9d8896a6f',
    betaStorefrontToken: 'f3a499840ef40f5d2cbbeb7c5ea4f347',
    countryName: 'España',
    continent: 'Europe',
    lang: 'Español',
  },
  nl: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'nl',
    sourceDataLocale: 'nl',
    title: 'eufy NL',
    storefrontToken: 'dedba11bf51a05ede36507f1a7908735',
    betaStorefrontToken: '435d0964ce7ada82766de649c501da22',
    countryName: 'Netherlands',
    continent: 'Europe',
    lang: 'Dutch',
  },
  'cz-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'cz',
    sourceDataLocale: 'eu-en',
    title: 'eufy CZ',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Czech Republic',
    continent: 'Europe',
    lang: 'English',
  },
  'dk-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'dk',
    sourceDataLocale: 'eu-en',
    title: 'eufy DK',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Denmark',
    continent: 'Europe',
    lang: 'English',
  },
  'gr-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'gr',
    sourceDataLocale: 'eu-en',
    title: 'eufy GR',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Greece',
    continent: 'Europe',
    lang: 'English',
  },
  'mk-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'mk',
    sourceDataLocale: 'eu-en',
    title: 'eufy MK',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Macedonia',
    continent: 'Europe',
    lang: 'English',
  },
  'hu-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'hu',
    sourceDataLocale: 'eu-en',
    title: 'eufy HU',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Hungary',
    continent: 'Europe',
    lang: 'English',
  },
  'se-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'se',
    sourceDataLocale: 'eu-en',
    title: 'eufy SE',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Sweden',
    continent: 'Europe',
    lang: 'English',
  },
  'hr-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'hr',
    sourceDataLocale: 'eu-en',
    title: 'eufy HR',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Croatia',
    continent: 'Europe',
    lang: 'English',
  },
  'bg-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'bg',
    sourceDataLocale: 'eu-en',
    title: 'eufy BG',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Bulgaria',
    continent: 'Europe',
    lang: 'English',
  },
  'ee-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'ee',
    sourceDataLocale: 'eu-en',
    title: 'eufy EE',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Estonia',
    continent: 'Europe',
    lang: 'English',
  },
  'fi-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'fi',
    sourceDataLocale: 'eu-en',
    title: 'eufy FI',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Finland',
    continent: 'Europe',
    lang: 'English',
  },
  'lt-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'lt',
    sourceDataLocale: 'eu-en',
    title: 'eufy LT',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Lithuania',
    continent: 'Europe',
    lang: 'English',
  },
  'pl-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'pl',
    sourceDataLocale: 'eu-en',
    title: 'eufy PL',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Poland',
    continent: 'Europe',
    lang: 'English',
  },
  'ro-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'ro',
    sourceDataLocale: 'eu-en',
    title: 'eufy RO',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Romania',
    continent: 'Europe',
    lang: 'English',
  },
  'si-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'si',
    sourceDataLocale: 'eu-en',
    title: 'eufy SI',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Slovenia',
    continent: 'Europe',
    lang: 'English',
  },
  'sk-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'sk',
    sourceDataLocale: 'eu-en',
    title: 'eufy SK',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Slovakia',
    continent: 'Europe',
    lang: 'English',
  },
  'no-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'no',
    sourceDataLocale: 'eu-en',
    title: 'eufy NO',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Norway',
    continent: 'Europe',
    lang: 'English',
  },
  'ad-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'ad',
    sourceDataLocale: 'eu-en',
    title: 'eufy AD',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Andorra',
    continent: 'Europe',
    lang: 'English',
  },
  'al-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'al',
    sourceDataLocale: 'eu-en',
    title: 'eufy AL',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Albania',
    continent: 'Europe',
    lang: 'English',
  },
  'ba-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'ba',
    sourceDataLocale: 'eu-en',
    title: 'eufy BA',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Bosnia and Herzegovina',
    continent: 'Europe',
    lang: 'English',
  },
  'me-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'me',
    sourceDataLocale: 'eu-en',
    title: 'eufy ME',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Montenegro',
    continent: 'Europe',
    lang: 'English',
  },
  'rs-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'rs',
    sourceDataLocale: 'eu-en',
    title: 'eufy RS',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Serbia',
    continent: 'Europe',
    lang: 'English',
  },
  'gl-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'gl',
    sourceDataLocale: 'eu-en',
    title: 'eufy GL',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Greenland',
    continent: 'Europe',
    lang: 'English',
  },
  'is-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'is',
    sourceDataLocale: 'eu-en',
    title: 'eufy IS',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Iceland',
    continent: 'Europe',
    lang: 'English',
  },
  'ua-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'ua',
    sourceDataLocale: 'eu-en',
    title: 'eufy UA',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Ukraine',
    continent: 'Europe',
    lang: 'English',
  },
  'by-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'by',
    sourceDataLocale: 'eu-en',
    title: 'eufy BY',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Belarus',
    continent: 'Europe',
    lang: 'English',
  },
  'md-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'md',
    sourceDataLocale: 'eu-en',
    title: 'eufy MD',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Moldova',
    continent: 'Europe',
    lang: 'English',
  },
  'lv-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'lv',
    sourceDataLocale: 'eu-en',
    title: 'eufy LV',
    storefrontToken: '9b242dd024471887e6b9e26a5b6b801e',
    betaStorefrontToken: 'e6ba13c9bb1d8bafe4de78cc24a5fd2d',
    countryName: 'Latvia',
    continent: 'Europe',
    lang: 'English',
  },
  'at-de': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'at',
    sourceDataLocale: 'eu-de',
    title: 'eufy AT',
    storefrontToken: '022e7f49d4b7ceb3eec3b1a60b9a5539',
    betaStorefrontToken: 'af2892e214f9896c072716d7303f0325',
    countryName: 'Austria',
    continent: 'Europe',
    lang: 'Deutsch',
  },
  'lu-de': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'lu',
    sourceDataLocale: 'eu-de',
    title: 'eufy LU',
    storefrontToken: '022e7f49d4b7ceb3eec3b1a60b9a5539',
    betaStorefrontToken: 'af2892e214f9896c072716d7303f0325',
    countryName: 'Luxembourg',
    continent: 'Europe',
    lang: 'Deutsch',
  },
  'li-de': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'li',
    sourceDataLocale: 'eu-de',
    title: 'eufy LI',
    storefrontToken: '022e7f49d4b7ceb3eec3b1a60b9a5539',
    betaStorefrontToken: 'af2892e214f9896c072716d7303f0325',
    countryName: 'Liechtenstein',
    continent: 'Europe',
    lang: 'Deutsch',
  },
  'va-it': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'va',
    sourceDataLocale: 'eu-it',
    title: 'eufy VA',
    storefrontToken: '162d71ab87720c4754345cfd291b6389',
    betaStorefrontToken: 'bc47bcbded4df1c5c3d6019f66d5f05e',
    countryName: 'Vatican',
    continent: 'Europe',
    lang: 'Italiano',
  },
  'sm-it': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'sm',
    sourceDataLocale: 'eu-it',
    title: 'eufy SM',
    storefrontToken: '162d71ab87720c4754345cfd291b6389',
    betaStorefrontToken: 'bc47bcbded4df1c5c3d6019f66d5f05e',
    countryName: 'San Marino',
    continent: 'Europe',
    lang: 'Italiano',
  },
  'be-nl': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
    showCheckoutBar: true,
    isEuLocale: true,
    shortLocale: 'be',
    sourceDataLocale: 'nl',
    title: 'eufy BE',
    storefrontToken: 'dedba11bf51a05ede36507f1a7908735',
    betaStorefrontToken: '435d0964ce7ada82766de649c501da22',
    countryName: 'Belgium',
    continent: 'Europe',
    lang: 'Nederlands',
  },
  au: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: false,
    showCheckoutBar: true,
    isEuLocale: false,
    shortLocale: 'au',
    sourceDataLocale: 'au',
    title: 'eufy Australia',
    storefrontToken: '80fa9a3950d357b72080f8dafe9ea7b7',
    betaStorefrontToken: '41070b9107c6d0862b2fbf6a45a94c3b',
    countryName: 'Australia',
    continent: 'Asia Pacific',
    lang: 'English',
  },
  'sg-en': {
    cart: false,
    search: true,
    wishlist: false,
    customerAuth: false,
    customCheckout: false,
    showKlarna: false,
    showCheckoutBar: false,
    isEuLocale: false,
    shortLocale: 'sg',
    sourceDataLocale: 'sg-en',
    title: 'eufy Singapore',
    storefrontToken: '09f277cdc296f5e3c01f2474cdcb1663',
    betaStorefrontToken: '9c44690faaf932e57b10768ebf84b03f',
    countryName: 'Singapore',
    continent: 'Asia Pacific',
    lang: 'English',
  },
  nz: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: false,
    showCheckoutBar: true,
    isEuLocale: false,
    shortLocale: 'nz',
    sourceDataLocale: 'nz',
    title: 'eufy New Zealand',
    storefrontToken: '7b2d7cd8871a44fb6bd6f6c234b6def0',
    betaStorefrontToken: '78d10d27aa44c7627f0175b85cffe547',
    countryName: 'New Zealand',
    continent: 'Asia Pacific',
    lang: 'English',
  },
  /* plus: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: false,
    showCheckoutBar: true,
    isEuLocale: false,
    shortLocale: 'checkout',
    sourceDataLocale: 'checkout',
    title: 'eufy checkout',
    storefrontToken: '',
    betaStorefrontToken: '548255feadb4ec3933dc7d15f5fc7581',
    countryName: 'Checkout',
    lang: 'English',
  }, */
}

// 如果 features 不存在，则默认为 ['us']
const locales = features ? Object.keys(features) : ['us']

module.exports = {
  provider: 'shopify',
  features,
  i18n: {
    locales,
    defaultLocale: 'us',
    localeDetection: false,
  },
  images: {
    domains: [
      'cdn.shopify.com',
      'cdn.shopifycdn.net',
      'www.facebook.com',
      'cdn4.fireworktv.com',
    ],
    deviceSizes: [360, 768, 1080, 1200, 1920],
  },
}
