// 引入js-cookie库用于操作cookies
import Cookies from 'js-cookie'
// 引入React的钩子函数
import { useCallback, useEffect, useState } from 'react'

// 创建一个自定义钩子，用于处理cookies中的标签
export default function useTagInCookies({
  expiresDay = 30, // 默认cookie过期时间为30天
}: {
  expiresDay?: number // 过期时间可选参数
}) {
  // 声明一个状态变量来存储域名
  const [domain, setDomain] = useState('')

  // 使用useEffect钩子在组件挂载时设置域名
  useEffect(() => {
    // 获取当前网站的主机名并分割成数组
    const hostUrl = window.location.host.split('.')
    // 判断是否是本地环境，设置相应的域名
    const domain = window.location.host.includes('localhost')
      ? 'localhost'
      : `.${hostUrl[1]}.${hostUrl[2]}`
    // 更新状态变量domain
    setDomain(domain)
  }, [])

  // 使用useCallback钩子创建一个获取Cookie的函数，避免不必要的重渲染
  const getItemWithExpiry = useCallback(
    (key: string) => {
      // 从Cookies中获取对应key的数据
      const itemString = Cookies.get(key)
      // 如果没有获取到数据，返回null
      if (!itemString) {
        return null
      }
      // 解析获取到的数据
      const item = JSON.parse(itemString)
      const now = new Date()

      // 检查cookie是否过期
      if (now.getTime() > item.expiry) {
        console.log('Cookie expired')
        // 如果过期，删除cookie
        Cookies.remove(key, {
          domain,
          expires: expiresDay,
        })
        return null
      }
      // 如果没有过期，返回cookie数据
      return item
    },
    [domain, expiresDay] // 依赖项数组，只有当这些依赖项变化时，函数才会更新
  )

  // 使用useCallback钩子创建一个设置Cookie的函数
  const setItemWithExpiry = useCallback(
    ({
      key,
      value,
      timestamp,
    }: {
      key: string // cookie的键
      value: any // cookie的值
      timestamp: number // 过期时间戳
    }) => {
      const now = new Date()
      // 构造cookie对象，包含值、过期时间和时间戳
      const item = {
        value,
        expiry: now.getTime() + timestamp, // 计算过期时间
        timestamp,
      }
      // 设置cookie
      Cookies.set(key, item, {
        expires: expiresDay, // 设置过期天数
      })
    },
    [expiresDay] // 依赖项数组
  )
  // 返回设置和获取cookie的函数
  return { setItemWithExpiry, getItemWithExpiry }
}
