import React from 'react'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import cn from 'classnames'
import 'regenerator-runtime/runtime'
import s from './index.module.css'

/**
 * Affirm分期组件
 * @param {Object} props 组件属性
 * @param {number} props.price 价格，默认为0
 * @param {Object} props.variant 变体信息
 * @param {string} props.className 自定义类名，默认为空字符串
 * @returns React元素
 */
const Affirm = ({ price = 0, variant, className = '' }) => {
  // 计算显示金额
  const displayAmount = (price || variant?.price) * 100

  // 处理点击事件
  const handleClick = (e) => {
    const eventBase = {
      eventCategory: `Product Detail Page_${variant?.sku || 'cart'}`,
      eventAction: 'affirm',
      eventLabel: e?.target?.innerText, // 获取按钮文案
    }

    // 触发UA事件
    pageGTMEvent({
      event: 'uaEvent',
      ...eventBase,
    })

    // 触发GA4事件
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'lp_button',
      event_parameters: {
        ...eventBase,
        page_group: eventBase.eventCategory,
        button_name: eventBase.eventLabel,
        position: 'affirm',
      },
    })
  }

  return (
    <p
      className={cn(s.affirm, className, 'affirm-as-low-as')}
      data-page-type="product"
      data-amount={displayAmount}
      onClick={handleClick}
    ></p>
  )
}

export default Affirm
