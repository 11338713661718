import metafieldsItems from './metafields-items-query'

const getBlogQuery = /* GraphQL */ `
  query getBlogByHandle($slug: String!) {
    blogByHandle(handle: $slug) {
      handle
      seo {
        title
        description
      }
      onlineStoreUrl
      title
      articles(first: 250, sortKey:PUBLISHED_AT, reverse:true ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            handle
            id
            title
            handle
            seo {
              title
              description
            }
            blog {
              handle
              title
            }
            image {
              url
              altText
            }
            tags
            publishedAt
            content(truncateAt: ${200})
            MF_author: metafield(namespace: "info", key: "author") {
                    ${metafieldsItems}
            }
            MF_faq: metafield(
              namespace: "content"
              key: "faq"
            ) {
                ${metafieldsItems}
            }
          }
        }
      }
      MF_preRender: metafield(namespace: "build", key: "preRender") {
							${metafieldsItems}
      }
      MF_pageType: metafield(namespace: "pageType", key: "category") {
        ${metafieldsItems}
      }
      MF_template: metafield(namespace: "template", key: "name") {
							${metafieldsItems}
      }
      MF_nav: metafield(namespace: "blog", key: "nav") {
							${metafieldsItems}
      }
      MF_subscribe: metafield(namespace: "blog", key: "subscribe") {
							${metafieldsItems}
      }
      MF_infos: metafield(namespace: "blog", key: "infos") {
        ${metafieldsItems}
      }
      MF_postsSpotlight: metafield(namespace: "posts", key: "spotlight") {
        ${metafieldsItems}
      }
      MF_blogCollectionUpdatedAt: metafield(namespace: "global", key: "articles_update_at") {
        ${metafieldsItems}
      }
      MF_postsRecommend: metafield(namespace: "component", key: "posts") {
        ${metafieldsItems}
      }
      MF_videoList: metafield(namespace: "global", key: "videoList") {
        ${metafieldsItems}
      }
      MF_blogVideos: metafield(namespace: "component", key: "videos") {
        ${metafieldsItems}
      }
      MF_recommendsProducts: metafield(namespace: "component", key: "products") {
        ${metafieldsItems}
      }
      MF_showNewPop: metafield(namespace: "global", key: "showNewPop") {
        ${metafieldsItems}
      }
      MF_giftCard: metafield(namespace: "global", key: "giftCard") {
        ${metafieldsItems}
      }
      MF_liveHelper: metafield(namespace: "global", key: "live-helper") {
				${metafieldsItems}
      }
      MF_liveStream: metafield(namespace: "global", key: "live_stream") {
				${metafieldsItems}
      }
      MF_hideFooterRegister: metafield(namespace: "global", key: "hideFooterRegister") {
        ${metafieldsItems}
      }
      MF_titleTag: metafield(namespace: "global", key: "title_tag") {
        ${metafieldsItems}
      }
      MF_descriptionTag: metafield(namespace: "global", key: "description_tag") {
        ${metafieldsItems}
      }
    }
  }
`

export default getBlogQuery
