import {
  useCart,
  useAddItem,
  useRemoveItem,
  useUpdateItem,
} from 'lib/shopify/api/cart'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui'
import useProduct from '@shopify/product/use-product'
import { useEffect, useMemo, useCallback, useState } from 'react'
import { fuzzyEqual } from '@components/helper'
import Decimal from 'decimal.js'
import Cookies from 'js-cookie'

const useGradientGiftsCampaign = ({ campaign, _giveaway }) => {
  /* if (!campaign) {
    return {
      involvedLines: [],
      reorder: () => {},
      disableCodeRemove: false,
      nextSummerLevel: null,
      involvedSubTotal: 0,
    }
  } */
  const addItem = useAddItem()
  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem()
  const { locale } = useRouter()
  const { data } = useCart()
  // eslint-disable-next-line no-unused-vars
  const { cartAction, setCartAction } = useUI()
  const [points_subscribe, set_points_subscribe] = useState(false)

  useEffect(() => {
    if (locale === 'au') {
      const isPointsSubscribe = Cookies.get('points_subscribe')
      if (isPointsSubscribe) {
        set_points_subscribe(true)
      }
    }
  }, [data, locale])

  const [upgrade_multiple, upgrade_value] = useMemo(() => {
    let upgrade_multiple = 1
    let upgrade_value = 0
    if (points_subscribe) {
      upgrade_multiple = 1.2
      upgrade_value = 40
    }
    data?.lineItems?.forEach(({ customAttributes }) => {
      customAttributes?.forEach(({ key, value }) => {
        if (key === '_amount_upgrade_multiple') {
          upgrade_multiple = value
        }
        if (key === '_amount_upgrade_value') {
          upgrade_value = value
        }
      })
    })
    return [upgrade_multiple, upgrade_value, data?.lineItems]
  }, [data, points_subscribe])

  const breakpoints = useMemo(() => {
    return campaign?.breakpoints?.map((item) => {
      return {
        breakpoint: new Decimal(item?.breakpoint)
          .minus(new Decimal(upgrade_value))
          .dividedBy(new Decimal(upgrade_multiple))
          .toFixed(2, Decimal.ROUND_DOWN),
        giveawayProducts: item?.giveawayProducts,
      }
    })
  }, [campaign?.breakpoints, upgrade_multiple, upgrade_value])

  const handles = breakpoints
    ?.map(({ giveawayProducts }) =>
      giveawayProducts?.map((product) => product?.handle)?.join(',')
    )
    .join(',')

  const { data: result } = useProduct({
    handles,
  })

  const isGiveawayItem = useCallback(
    (item) =>
      item?.customAttributes?.find((attribute) => attribute.key === _giveaway),
    [_giveaway]
  )

  const involvedLines = useMemo(() => {
    return (
      data?.lineItems?.filter((line) => {
        const notGift = line?.totalAmount && Number(line.totalAmount) > 0

        // eslint-disable-next-line no-unused-vars
        const inTags = line.product?.tags?.some(
          (tag) =>
            campaign?.includeTags?.includes(tag.trim()) &&
            line?.variant?.availableForSale
        )
        return notGift && !isGiveawayItem(line)
      }) || []
    )
  }, [data?.lineItems, isGiveawayItem, campaign?.includeTags])

  const involvedSubTotal = useMemo(() => {
    return involvedLines?.reduce((prev, item) => {
      if (campaign?.useTotalAmount) {
        // 计算折后价
        return new Decimal(prev).plus(new Decimal(item?.totalAmount || 0))
      }
      return new Decimal(prev).plus(new Decimal(item?.subtotalAmount || 0))
    }, 0)
  }, [campaign?.useTotalAmount, involvedLines])

  // eslint-disable-next-line no-unused-vars
  const coupons = useMemo(() => {
    if (data?.discountCodes?.length) {
      const coupons = data?.discountCodes
        ?.filter((code) => code?.applicable)
        ?.map((item) => item?.code)
      return coupons
    }
  }, [data?.discountCodes])

  const [summerLevel, nextSummerLevel] = useMemo(() => {
    const sortedLevel = [...(breakpoints || [])]?.sort(
      (a, b) => b.breakpoint - a.breakpoint
    )

    // 当前购物车中所有满足 tag 的商品可以达到的满减阶梯
    const levelIndex = sortedLevel?.findIndex(
      (discount) =>
        involvedSubTotal >= Number(discount.breakpoint) &&
        involvedLines.length > 0
    )

    let nextSummerLevel = null
    if (levelIndex > 0) {
      nextSummerLevel = sortedLevel[levelIndex - 1]
    } else if (levelIndex === -1) {
      nextSummerLevel = sortedLevel[sortedLevel.length - 1]
    }

    return [sortedLevel[levelIndex], nextSummerLevel, involvedSubTotal]
  }, [breakpoints, involvedLines.length, involvedSubTotal])

  /********************** Summer Campaign 赠送赠品逻辑 ***********************/
  const cbk_Campaign = useCallback(async () => {
    // 新增不在购物车的赠品
    const productsNeedToAdd = summerLevel?.giveawayProducts?.filter(
      (product) =>
        !data?.lineItems?.find(
          (item) =>
            fuzzyEqual(product.sku, item.variant?.sku) && isGiveawayItem(item)
        )
    )
    // 删除是赠品但不在当前阶梯内的赠品
    const productsNeedToRemove = data?.lineItems?.filter(
      (item) =>
        isGiveawayItem(item) &&
        !summerLevel?.giveawayProducts?.find((product) =>
          fuzzyEqual(product.sku, item.variant?.sku)
        )
    )

    if (productsNeedToRemove?.length > 0) {
      const ids = productsNeedToRemove?.map((lineItem) => lineItem?.id)
      // console.log('need to remove lines:', ids)
      try {
        await removeItem({
          id: ids,
        })
      } catch (error) {}
    }

    if (result?.products && productsNeedToAdd?.length > 0) {
      const variants = productsNeedToAdd
        ?.map((product) =>
          result?.products
            ?.find((p) => fuzzyEqual(p.handle, product.handle))
            ?.variants?.find((variant) => fuzzyEqual(variant.sku, product.sku))
        )
        ?.filter((variant) => variant)
      await addItem(
        variants?.map((variant) => {
          return {
            variantId: String(variant?.id),
            quantity: 1,
            customAttributes: [
              { key: _giveaway, value: _giveaway },
              ...(points_subscribe
                ? [
                    {
                      key: '_amount_upgrade_multiple',
                      value: '1.2',
                    },
                    {
                      key: '_amount_upgrade_value',
                      value: '40',
                    },
                  ]
                : []),
            ],
          }
        })
      )
    }
  }, [
    summerLevel?.giveawayProducts,
    data?.lineItems,
    result?.products,
    isGiveawayItem,
    removeItem,
    addItem,
    _giveaway,
    points_subscribe,
  ])

  /********************** 新增 _code_money 属性 ********************** */
  const cbk_code = useCallback(async () => {
    const updateLine = []
    let discountsTotal = 0
    involvedLines?.forEach((line) => {
      const discountAllocations = line?.discountAllocations?.reduce(
        (prev, curr) => {
          return new Decimal(prev).plus(
            new Decimal(curr?.coupon ? curr?.amount : 0 || 0)
          )
        },
        0
      )
      if (discountAllocations > 0) {
        discountsTotal += discountAllocations?.toNumber()
      }
    })
    involvedLines?.forEach((line) => {
      const attributes_code_money = line?.customAttributes?.find(
        (item) => item?.key === '_code_money'
      )
      const attributes_giveaway = line?.customAttributes?.find(
        (item) => item?.key === _giveaway
      )
      const attributes_without_code_money = line?.customAttributes?.filter(
        (item) => item?.key !== '_code_money'
      )
      if (!attributes_giveaway && discountsTotal > 0) {
        updateLine.push({
          ...line,
          customAttributes: [
            ...(attributes_without_code_money || []),
            {
              key: '_code_money',
              value: discountsTotal + '',
            },
          ],
        })
      } else if (discountsTotal <= 0 && attributes_code_money) {
        updateLine.push({
          ...line,
          customAttributes: line?.customAttributes,
          // line?.customAttributes?.filter(
          //   (attribute) => attribute.key != '_code_money'
          // ) || [],
        })
      }
    })
    if (updateLine.length > 0) {
      await updateItem(updateLine)
    }
    return updateLine
  }, [_giveaway, involvedLines, updateItem])

  useEffect(() => {
    const cartCallback = async () => {
      // setCartAction(true)
      // if (campaign?.useTotalAmount) {
      await cbk_code()
      // }
      await cbk_Campaign()
      // setCartAction(false)
    }
    clearTimeout(window?.[`timer_${_giveaway}`])
    window[`timer_${_giveaway}`] = setTimeout(function () {
      cartCallback()
    }, 2000)
  }, [_giveaway, cbk_Campaign, cbk_code])

  //排序: 非 summer campaign 商品 =》 summer campaign 主商品 =》赠品
  const reorder = (a, b) => {
    const getPriorityOrder = (item) => {
      if (
        item.product?.tags?.find((tag) => campaign?.includeTags?.includes(tag))
      ) {
        return 1
      } else if (
        item.customAttributes?.find((attribute) => attribute.key === _giveaway)
      ) {
        return 0
      } else {
        return 2
      }
    }
    const priorityA = getPriorityOrder(a)
    const priorityB = getPriorityOrder(b)
    return priorityB - priorityA
  }

  return {
    involvedLines,
    reorder,
    disableCodeRemove: involvedLines?.length > 0,
    nextSummerLevel,
    involvedSubTotal,
  }
}

export default useGradientGiftsCampaign
